<div class="flex flex-col gap-0">

    <mat-divider class="w-full my-5"> </mat-divider>

    <div class="flex flex-col w-full lg:flex-row lg:justify-between lg:items-center">
        <div class="flex justify-between items-center w-full lg:justify-start basis-3/4">
            <h5 class="mb-0"> {{ props.label }} </h5>
            <span class="text-center px-3 py-1 rounded-full ring-1  ring-[#0000001a]  dark:ring-[#ffffff1a]">
                {{field.fieldGroup?.length }} éléments</span>
        </div>
        <button mat-flat-button color="accent" (click)="add()" type="button" class="basis-1/4"><mat-icon
                class="text-white">add</mat-icon> <span> {{ props['addText']}}</span></button>
    </div>

    <!--  Array -->
    <div *ngFor="let field of field.fieldGroup; let i = index" class="flex relative w-full">

        <formly-field class="col" [field]="field" class="w-100"></formly-field>

        <div class="btn-container flex flex-row gap-3 items-center">

            <!-- Sorting Btn -->
            <div class=" flex flex-row gap-2 items-center">
                <div class="btn-icon" (click)="moveFieldUp(i)">
                    <div class="delete-icon cursor-pointer rounded-full flex items-center justify-center size-10">
                        <svg id="Chevron Up" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 16L12 9L19 16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                    </div>
                </div>

                <div class="btn-icon" (click)="moveFieldDown(i)">
                    <div class="delete-icon cursor-pointer rounded-full flex items-center justify-center size-10">
                        <svg id="Chevron Down" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 9L12 16L5 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                    </div>
                </div>

            </div>
            <!-- / Sorting Btn -->

            <mat-divider [vertical]="true" class="h-8"></mat-divider>

            <div class="btn-icon w-fit px-3 mat-accent-background-pastel mat-accent-color mat-accent-border-color">
                <div class="delete-icon cursor-pointer rounded-full flex items-center justify-center h-10 ">
                    <span class="font-semibold">{{'Position de la réponse : '+ (i+1)}}</span>
                </div>
            </div>

            <!-- Delete Btn -->
            <div class="btn-icon">
                <div (click)="remove(getIndexToRemove(i))"
                    class="delete-icon cursor-pointer rounded-full flex items-center justify-center size-10">
                    <mat-icon svgIcon="delete-light"></mat-icon>
                </div>
            </div>
            <!-- / Delete Btn -->

        </div>

    </div>
    <!-- / Array -->

</div>