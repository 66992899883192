import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'formly-repeat-section',
  template: `
            <div class="flex flex-col gap-0">

              <mat-divider class="w-full my-5">  </mat-divider>

              <div class="flex flex-col w-full lg:flex-row lg:justify-between lg:items-center">
                <div class="flex justify-between items-center w-full lg:justify-start basis-3/4">
                  <h5 class="mb-0"> {{ props.label }} </h5>
                    <span class="text-center px-3 py-1 rounded-full ring-1  ring-[#0000001a]  dark:ring-[#ffffff1a]"> {{field.fieldGroup?.length }} éléments</span>
                </div>
                <button mat-flat-button color="accent" (click)="add()" type="button" class="basis-1/4" ><mat-icon class="text-white">add</mat-icon> <span> {{ props['addText']}}</span></button>
              </div>

              <!-- Reverse Array -->
              <div *ngFor="let field of field.fieldGroup?.slice()?.reverse(); let i = index" class="flex relative w-full">
                  <formly-field class="col" [field]="field" class="w-100"></formly-field>
                  <div class="btn-wrapper">
                    <div (click)="remove(getIndexToRemove(i))" class="delete-icon cursor-pointer rounded-full flex items-center justify-center size-10">
                    <mat-icon svgIcon="delete-light"></mat-icon>
                    </div>
                  </div>
              </div>

            </div>
  `,
  styles: [
    `
      .flex{
        display:flex;
        justify-content:flex-start;
        align-items:flex-start;
        gap:10px;
      }

      .relative{
        position:relative;
      }

      .justify-space-between{
         justify-content:space-between;
      }

      .align-center{
        align-items:center;
      }

      .btn-wrapper{
        width:40px;
        height:40px;
        display:flex;
        justify-content:center;
        align-items:center;
        position:absolute;
        top:20px;
        right:16px;
        border: 1px solid #EAECF0;
        border-radius:50%;
      }

      .w-75{
        width:75%;
      }

      .w-100{
        width:100%;
      }

    `
  ]
})

export class RepeatTypeComponent extends FieldArrayType {

  globalLength: any = 0;

  ngOnInit(): void {
    this.globalLength = this.field.fieldGroup?.length
    // Add Item by default
    if (this.field.fieldGroup?.length == 0) {
      this.add();
      this.globalLength = this.field.fieldGroup?.length
    }
  }

  getIndexToRemove(index: any) {
    this.globalLength = this.field.fieldGroup?.length
    return (this.globalLength - 1) - index
  }

}