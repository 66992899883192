import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-fyyyre-text',
  templateUrl: './fyyyre-text.component.html',
  styleUrl: './fyyyre-text.component.scss'
})
export class FyyyreTextComponent extends FieldType<FieldTypeConfig> {

}
