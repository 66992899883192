<!-- Filter Results Button -->
@if (config ) {
<button mat-button [color]="config.color ?? 'default'" [ngClass]="{
              'mat-mdc-button mat-mdc-raised-button':
                config?.styleType === 'raised',
              'mdc-button--outlined mat-mdc-outlined-button':
                config?.styleType === 'stroked' ||
                config?.styleType === undefined,
              'mat-mdc-unelevated-button': config?.styleType === 'flat',
              'mat-mdc-menu-item mdc-list-item':
                config?.styleType === 'menu',
            }" (click)="openFilterMenu()" [matMenuTriggerFor]="filterMenu">
  @if(config.icon){
  <mat-icon [svgIcon]="config.icon"></mat-icon>
  }
  <span>{{ config.label }} </span>
</button>

<!-- Filter Menu -->
<!-- 
      (mouseleave)="closeFilterMenu()"
 -->
<mat-menu #filterMenu="matMenu"
  class="filter-menu py-1 px-3 rounded-1xl bg-white dark:bg-inherit dark:ring-[#4b5563] text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
  <!--  Filters -->
  <div class="flex flex-col gap-4">
    <div class="flex flex-col gap-3" (click)="$event.stopPropagation()">
      <!-- Filter Header -->
      <div class="flex flex-row justify-between items-center">
        <span class="mb-0 text-md font-semibold text-gray-900 dark:text-white">Liste des filtres</span>
        <div class="flex flex-row justify-start items-center">
          <button mat-button color="primary">Clear</button>
        </div>
      </div>

      <!-- / Filter Header -->

      <!-- Filter Content -->

      <!-- Global Filter ( Search ) -->
      <div class="flex flex-col">
        <mat-form-field class="basis-100 global-autocomplete">
          <mat-icon matPrefix svgIcon="search-chat-light"></mat-icon>
          <input matInput placeholder="Recherche de mots-clés" #searchInput />
        </mat-form-field>
      </div>
      <!-- / Global Filter ( Search ) -->

      <!-- Filter List -->
      <mat-accordion>
        @for (filter of config.filters; track filter.name; let i = $index, let
        first = $first, last = $last ) {
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title class="">
              {{ filter.name }}
            </mat-panel-title>
            <mat-panel-description>
              <!-- Optionnal content --></mat-panel-description>
          </mat-expansion-panel-header>
          <!-- Filters Types ( Checkbox, Autocomplete ...) -->
          <ng-template matExpansionPanelContent>
            @switch (filter.fieldType) {
            <!-- Checkbox Type -->
            @case ('checkbox') {
            <mat-selection-list [multiple]="filter.multiple ?? false" #filterSelection (selectionChange)="
                onSelection($event, filterSelection.selectedOptions, filter)
              ">
              @for (data of
              !filter.contentLoad?getFilterDefaultData(filter):filter.fieldData.data;
              track data;) {
              <mat-list-option [value]="data" togglePosition="before" color="primary">{{ data.label }}</mat-list-option>
              }
            </mat-selection-list>
            }
            <!-- / Checkbox Type -->
            @case ('autocomplete') {
            <p>autocomplete</p>
            } @default { Default case. } }
          </ng-template>
          <!-- / Filters Types ( Checkbox, Autocomplete ...) -->
        </mat-expansion-panel>

        }
      </mat-accordion>
      <!-- /Filter List -->

      <!-- / Filter Content -->
    </div>
    <!-- Filter Actions -->
    <button mat-flat-button color="primary" class="basis-100 rounded-1xl" (click)="submitFilterUrl()">
      Appliquer les filtres
    </button>
    <!-- / Filter Actions -->
  </div>
  <!-- / Filters -->
</mat-menu>

}
<!-- / Filter Results Button -->

<!-- Filter List -->
<!-- 
- Global Filter
    - List Dropdown
        - Autocomplete / Badge ....
- Btn ( Show / export / reset)


 -->