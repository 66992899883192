<section class="flex flex-col max -h-full">
  @if (showInstanceBar) {
    <div class="flex flex-col">
      <mat-card class="mt-5 p-4 flex flex-row items-center gap-2.5">
        <mat-card-title class="font-semibold text-nowrap">
          Sessions du serious game :
        </mat-card-title>

        <div class="w-full">
          <mat-chip-grid #chipGrid aria-label="Session selection">
            @for (instance of gameInstances; track $index) {
              <mat-chip-row (removed)="removeInstance(instance)">
                {{instance.name}}
                <button matChipRemove [attr.aria-label]="'remove ' + instance.name">
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip-row>
            }
          </mat-chip-grid>
        </div>

        <button (click)="openDialog()" mat-flat-button color="accent">
          <div class="flex flex-row justify-start items-center gap-2">
            <mat-icon [svgIcon]="'custom-table/plus-light'"></mat-icon>
            <span>Ajouter une session</span>
          </div>
        </button>

      </mat-card>
    </div>
  }
  <div class="flex flex-row w-full mt-5 gap-5">
    <mat-card [ngClass]="{'w-3/4': showInstancesStats, 'w-full' : !showInstancesStats}"  class="flex h-full">
      <mat-card-header class="flex flex-col gap-4">
        <div class="flex flex-row">
          <div class="flex flex-col justify-center">
            <mat-card-title class="font-semibold">
              Activité globale du serious game</mat-card-title
            >
            <mat-card-subtitle> {{ headerLabel }}</mat-card-subtitle>
          </div>
          <button
            [matMenuTriggerFor]="filterMenu"
            mat-stroked-button
            class="ml-auto"
          >
            <mat-icon svgIcon="preferences-light"></mat-icon>
            Filtres
          </button>
          <mat-menu #filterMenu="matMenu" xPosition="before">
            <div class="flex flex-col gap-2.5 py-3 px-4">
              <h6 class="m-0">DATE PERSONNALISÉE</h6>
              <mat-form-field [formGroup]="dateRange">
                <mat-label>Sélectionner un intervalle</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                  <input
                    matStartDate
                    placeholder="Start date"
                    formControlName="start"
                  />
                  <input
                    matEndDate
                    placeholder="End date"
                    formControlName="end"
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker
                  #picker
                  (closed)="pickerClosed()"
                ></mat-date-range-picker>
              </mat-form-field>
            </div>

            <mat-divider></mat-divider>

            <div class="flex flex-col gap-2.5 py-3 px-4">
              <h6 class="m-0">DATE PRÉDÉFINI</h6>
              <button mat-stroked-button (click)="updateFiltersData('week')">
                Semaine
              </button>
              <button mat-stroked-button (click)="updateFiltersData('month')">
                Mois
              </button>
              <button mat-stroked-button (click)="updateFiltersData('year')">
                Année
              </button>
            </div>
          </mat-menu>
        </div>
        <mat-divider class="w-full"></mat-divider>
      </mat-card-header>
      <mat-card-content class="flex flex-col">
        <canvas
          baseChart
          [data]="lineChartData"
          [options]="lineChartOptions"
          [type]="'line'"
        ></canvas>
      </mat-card-content>
    </mat-card>

    @if (showInstancesStats) {
      <div class="flex flex-col h-full gap-5 w-1/4">
        <mat-card class="mat-elevation-z0">
          <mat-card-header>
            <mat-card-title-group class="flex flex-col gap-5">
              <mat-card-title class="font-semibold">Parties jouées</mat-card-title>
              <mat-card-subtitle> {{ headerLabel }}</mat-card-subtitle>
              <mat-divider class="w-full"></mat-divider>
            </mat-card-title-group>
          </mat-card-header>
          <mat-card-content class="flex flex-col">
            <canvas
              baseChart
              [data]="pieChartData"
              [type]="'pie'"
              [options]="pieChartOptions"
            >
            </canvas>
          </mat-card-content>
        </mat-card>

        <mat-card class="flex flex-col h-full">
          <mat-card-header>
            <mat-card-title class="font-semibold">
              Joueurs ayant le plus d'XP</mat-card-title
            >
            <mat-card-subtitle>{{ headerLabel }}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <div class="flex flex-col gap-2.5">
              @for (user of xpRank;let index=$index; track user) {

                <mat-card>
                  <mat-card-content>
                    <div
                      class="rounded-lg flex cursor-pointer flex-row items-center justify-between"
                    >
                      <div
                        class="flex flex-row items-center gap-5"
                        [ngClass]="{
                    'rank-first': index == 0,
                    'rank-second': index == 1,
                    'rank-third': index == 2
                  }"
                      >
                        @if(index<=2){ <mat-icon svgIcon="king-bulk"></mat-icon>
                        }
                        <h6 class="m-0 text-sm md:text-lg font-bold">
                          {{ user.first_name + " " + user.last_name }}
                        </h6>
                      </div>

                      <span class="m-0 text-lg">{{ user.xp }} XP</span>
                    </div>
                  </mat-card-content>
                </mat-card>
              } @empty {
                <li>Il n'y a pas de classement actuellement.</li>
              }
              <!--
              <mat-card>
                <mat-card-content>
                  <div class="rounded-lg flex cursor-pointer flex-row items-center justify-between">
                    <div class="rank-first flex flex-row items-center gap-5">
                      <mat-icon svgIcon="king-bulk"></mat-icon>
                      <h6 class="m-0 text-sm md:text-lg font-bold">John Doe</h6>
                    </div>

                    <span class="m-0 text-lg">1000 XP</span>
                  </div>

                </mat-card-content>
              </mat-card>

              <mat-card>
                <mat-card-content>
                  <div class="rounded-lg flex cursor-pointer flex-row items-center justify-between">
                    <div class="rank-second flex flex-row items-center gap-5">
                      <mat-icon svgIcon="king-bulk"></mat-icon>
                      <h6 class="m-0 text-sm md:text-lg font-bold">Jane Doe</h6>
                    </div>
                    <span class="m-0 text-lg">800 XP</span>
                  </div>

                </mat-card-content>
              </mat-card>

              <mat-card>
                <mat-card-content>
                  <div class=" rounded-lg flex cursor-pointer flex-row items-center justify-between">
                    <div class="rank-third flex flex-row items-center gap-5">
                      <mat-icon svgIcon="king-bulk"></mat-icon>
                      <h6 class="m-0 text-sm md:text-lg font-bold">Joe Doe</h6>
                    </div>
                    <span class="m-0 text-lg">700 XP</span>
                  </div>

                </mat-card-content>
              </mat-card>

              <mat-card>
                <mat-card-content>
                  <div class="rounded-lg flex cursor-pointer flex-row items-center justify-between">
                    <div class="flex flex-row items-center gap-5">
                      <h6 class="m-0 text-sm md:text-lg font-bold">James Doe</h6>
                    </div>
                    <span class="m-0 text-lg">650 XP</span>
                  </div>

                </mat-card-content>
              </mat-card> -->
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    }

  </div>
</section>

