<mat-form-field appearance="outline" class="relative w-full custom-input-file" [floatLabel]="'always'">
  <mat-label>{{to['label']??'Pièce jointe'}}</mat-label>


  <div class="flex flex-row items-center gap-0">
    @if (imgToShow) {
    @if(imgFile.length==0&&field.formControl?.value) {
    <div class="flex w-12 items-center rounded-lg overflow-hidden">
      <img [src]="field.formControl?.value" alt="Image" class="object-cover rounded-lg bg-no-repeat w-6 h-6">
    </div>
    } @else if (imgSrc) {
    <div class="flex w-12 items-center rounded-lg overflow-hidden">
      <img [src]="imgSrc" alt="Image" class="object-cover rounded-lg bg-no-repeat w-6 h-6">
    </div>

    }

    <input matInput [placeholder]="field.formControl?.value ??'Sélectionnez un fichier (.png, .jpg ... )'" type="text"
      readonly [(ngModel)]="valueToDisplayInInput" class="opacity-1 py-1">


    } @else {
    <div class="flex w-12 items-center rounded-lg overflow-hidden">
      <mat-icon matPrefix class="bg-cover rounded-lg bg-no-repeat w-6 ml-2">photo</mat-icon>
    </div>

    <input matInput [placeholder]="to['placeholder'] ??'Sélectionnez un fichier (.png, .jpg ... )'" type="text" readonly
      [(ngModel)]="valueToDisplayInInput" class="opacity-1 py-1">
    }


  </div>

  <div class="absolute left-0  bottom-0 top-0 h-full w-full  ">
    <input (change)="onFileSelected($event)" [accept]="to['accept']" type="file" [multiple]="to['multiple']??false"
      class="opacity-0 h-full w-full">
  </div>
  <mat-icon matSuffix (click)="clearInput()" matTooltip="Supprimer tous les fichiers"
    aria-label="Delete all files">close</mat-icon>
</mat-form-field>