import {Component, Input} from '@angular/core';

export interface InfoCardConfig {

  showInfoCardHeader : {
    enable: boolean,

    showInfoCardImage: {
      enable: boolean,
      path: string,
    },

    showInfoCardGameTitle: {
      enable: boolean,
      title: string,
    },


    showInfoCardGameDuration: {
      enable: boolean,
      label?: string,
      icon?: string,

    },

    showInfoCardGameDifficulty: {
      enable: boolean,
      label?: string,
      icon?: string,
    },

  },

  showInfoCardGameStory: {
    enable: boolean,
    title: string,
    story: string,
    button: InfoCardInterraction,
  },

  showInfoCardGameBadge: {
    enable: boolean,
    title: string,
    badges: any[],
    button: InfoCardInterraction,

  },

  showInfoCardGameRanking: {
    enable: boolean,
    title: string,
    ranking: any[],
    button: InfoCardInterraction,

  },

  showInfoCardGameProgression: {
    enable: boolean,
    value: number,
    maxValue: number,
    color: string,
    icon?: string,

  },


  backgroundColor: string,
  fontColor: string,
}

export interface InfoCardInterraction {
  enable: boolean;
  link?: string,
  label?: string,
  fontColor?: string,
  color?: string,

}


@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrl: './info-card.component.scss'
})

export class InfoCardComponent {

  @Input() infoCardConfig!: InfoCardConfig;

  constructor() { }

}
