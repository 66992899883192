import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';


@Component({
  selector: 'formly-field-divider',
  template: `
  <mat-divider class="mb-4"></mat-divider>
  `, styles: [
    `

    `
  ]
})
export class DividerFieldType extends FieldType<FieldTypeConfig> { }