<a
  mat-list-item
  [ngStyle]="{ 'margin-left': depth * 30 + 'px' }"
  (click)="onItemSelected(item);expanded=true;"
  [ngClass]="getClassCssItem(item)"
  [routerLink]="'/' + item.route"
  [fragment]="item.fragment"
  [routerLinkActive]="'active-list-item-v2'"
  [routerLinkActiveOptions]="myMatchOptions"
>
  @if(item.iconName){

  <div
    class="mat-icon-wrapper flex justify-center items-center"
    matListItemIcon
  >
    <mat-icon
   
      [svgIcon]="item.iconName"

    ></mat-icon>
  </div>
  }

  <div matListItemTitle class="flex justify-between">
    {{ item.displayName }}
    <span class="flex" *ngIf="item.children && item.children.length">
      <span class="flex"></span>
      <mat-icon [@indicatorRotate]="expanded ? 'expanded' : 'collapsed'">
        expand_more
      </mat-icon>
    </span>
  </div>

</a>
<div *ngIf="expanded" class="children-list-item">
  <app-menu-list-item
    *ngFor="let child of item.children"
    [item]="child"
    [depth]="depth + 1"
  >
  </app-menu-list-item>
</div>
