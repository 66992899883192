import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-admin-button',
  templateUrl: './admin-button.component.html',
  styleUrl: './admin-button.component.scss',
})
export class AdminButtonComponent {
  constructor(protected router: Router, private route: ActivatedRoute) {}

  goBackToAdminPanel() {
    this.route.params.subscribe((params) => {
      let targetRoute = ['/admin', 'serious-game', params['gameId']];
      if (params['chapterId']) {
        targetRoute.push('chapters');
        targetRoute.push(params['chapterId']);
        if (params['unitId']) {
          targetRoute.push('units');
          targetRoute.push(params['unitId']);
          if (params['levelId']) {
            targetRoute.push('levels');
            targetRoute.push(params['levelId']);
          }
        }
      }
      this.router.navigate(targetRoute);
    });
  }
}
