<div class="flex items-center justify-center w-full" appDnd>

    <div
        class="relative flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">


        @if(files.length==0){
        <div class="flex flex-col items-center justify-center pt-5 pb-6">
            <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
            </svg>
            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Cliquez pour
                    télécharger</span> ou glisser-déposer</p>
            <p class="text-xs text-gray-500 dark:text-gray-400">{{to['accept'] ? 'Fichiers autorisés : '+
                showAcceptedExtensions() : 'SVG,
                PNG,
                JPG or
                GIF'}}</p>
        </div>
        }@else{
        <div class="flex flex-wrap absolute z-50">
            <ng-container *ngFor="let file of files; let i = index">
                <div *ngIf="!file.state" class="image-preview" cdkDrag>

                    @switch ( getFileExtentionType(file)) {
                    @case ("excel") {
                    <div class="flex flex-col m-5 justify-center items-center gap-2">


                        <svg id="Document menu" width="72" height="72" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.2192 10.7305H13.5848C14.7615 10.7305 15.4934 11.5607 15.4934 12.7364V15.4295C15.4934 16.6052 14.7615 17.4354 13.5848 17.4354H10.2192C9.04246 17.4354 8.31055 16.6052 8.31055 15.4295V12.7364C8.31055 11.5607 9.04635 10.7305 10.2192 10.7305Z"
                                stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path d="M11.9019 17.4354V10.7305M8.31055 14.0828H15.4934" stroke="currentColor"
                                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                                d="M13.7857 3.00004C14.3434 3.00004 14.8777 3.22681 15.2641 3.62975L19.055 7.57935C19.422 7.96088 19.6264 8.46991 19.6264 8.99937V17.1633C19.641 19.2199 18.0234 20.9163 15.9697 21L8.04424 20.999C5.97114 20.9533 4.32823 19.2364 4.37398 17.1633V6.65667C4.42264 4.61764 6.09378 2.99128 8.13379 3.00004H13.7857Z"
                                stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path d="M14.2695 3.0625V5.95511C14.2686 7.36637 15.4112 8.51291 16.8234 8.51583H19.5623"
                                stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>

                        <p class="text-base">{{file.name}}</p>
                    </div>
                    }
                    @case ("pdf") {
                    <div class="flex flex-col m-5 justify-center items-center gap-2">

                        <svg id="Document pdf" width="72" height="72" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M19.8311 12.9516V9.16074C19.8311 8.61688 19.6206 8.09417 19.2449 7.70239L15.3513 3.64662C14.9545 3.23369 14.4056 3.00003 13.8325 3.00003H8.02936C5.9345 2.99197 4.21732 4.66081 4.16797 6.75567V12.9516"
                                stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path d="M14.3281 3.0625V6.03257C14.3271 7.48286 15.5004 8.65921 16.9507 8.66223H19.7637"
                                stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path
                                d="M4.41602 19.4718H5.81293C6.66699 19.4718 7.3599 18.7789 7.3599 17.9249C7.3599 17.0718 6.66699 16.3789 5.81293 16.3789H4.41602V20.9936"
                                stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path d="M19.83 16.375H17.3242V21.0008M19.4448 19.0738H17.3247" stroke="currentColor"
                                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M11.6542 21.0008C12.2676 21.0008 12.8558 20.7571 13.2899 20.323C13.7239 19.8899 13.9677 19.3018 13.9677 18.6884C13.9677 18.0741 13.7239 17.4859 13.2899 17.0528C12.8558 16.6187 12.2676 16.375 11.6542 16.375H10.498V21.0008H11.6542Z"
                                stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                        <p class="text-base">{{file.name}}</p>
                    </div>
                    }
                    @default {
                    <img [src]="file.url" alt="Image prévisualisée" crossorigin="anonymous" />
                    }
                    }

                    <button (click)="removeFile(i)"
                        class=" flex justify-center items-center w-8 h-8 rounded-full border-2 border-gray-300  bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-slate-300 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                        <mat-icon class="text-gray-300 dark:text-slate-300">delete</mat-icon>
                    </button>

                </div>
            </ng-container>
        </div>
        }
        <input id="dropzone-file" type="file" [hidden]="false" (change)="onFileSelected($event)"
            [accept]="getAcceptedExtensions()" [multiple]="to['multiple']" />

    </div>
</div>