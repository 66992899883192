import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss']
})
export class EditDialogComponent {
  dialogForm = new FormGroup({});
  dialogModel: any = {};
  options: FormlyFormOptions = {
    formState: {
      cancel: false,
    },
  };
  fieldsForm: FormlyFieldConfig[] = []

  public cssClasses: Array<string> = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EditDialogComponent>,) { }

  ngOnInit(): void {
    this.cssClasses = []
    if (this.data['form']) {
      this.dialogForm = this.data['form'];
    }

    if (this.data['field']) {
      let field = this.data['field']
      field.push({ cancel: false });
      this.fieldsForm = field;
    }

    if (this.data['model']) {
      this.dialogModel = this.data['model']
    }
  }



  ngAfterViewChecked(): void {

    //Called after every check of the component's view. Applies to components only.
    //Add 'implements AfterViewChecked' to the class.

    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    if (this.data.config && this.data.config.style) {
      this.getAditionalCssClasses(this.data.config.style);
    }
  }

  submitDialogForm() {
    if (this.options.formState.cancel) {
      this.dialogModel = undefined
    }
    this.dialogRef.close(this.dialogModel);
  }

  getAditionalCssClasses(configCSS: any) {
    if (configCSS.showBorder) {
      this.cssClasses.push('formly-field-border')
    }
  }

  getIconModal() {
    if (this.data.config && this.data.config.header.icon) {
      return this.data.config.header.icon;
    } else return 'ticket-bulk';
  }

}

