@if(router.url){
<a
  color="accent"
  (click)="goBackToAdminPanel()"
  class="text-white w-fit m-4 absolute right-0 top-0"
  mat-flat-button
>
  <mat-icon svgIcon="new-tab-light" class="mx-2"></mat-icon>
  <!-- <span class="mr-2">{{this.router!.url.includes('levels') ? 'Retourner à l\'étape' :
        this.router!.url.includes('chapters') ? 'Retourner aux chapitres' : 'Retourner au jeu'}}</span> -->
  <span class="mr-2">Edition de l'aventure</span>
</a>
}
