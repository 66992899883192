@if(card){
<mat-card
  class="mat-elevation-z0 p-0 contain-content serious-game-card cursor-pointer hover:border-slate-700 dark:hover:border-white"
  (click)="onClick()"
>
  <img mat-card-image [src]="card.card_background_url" class="w-full h-96" />
  <mat-card-header class="flex flex-col h-44 justify-center items-center">
    <mat-card-title class="text-center line-clamp-2 mb-3">{{
      card.name
    }}</mat-card-title>
    <mat-card-subtitle> {{ card.subtitle }} </mat-card-subtitle>
    @if ( card.skills ) {
    <div
      class="flex flex-row w-full overflow-x-auto hide-scrollbar justify-start gap-2.5"
    >
      @for (skill of card.skills; track $index) {
      <mat-chip-set class="flex justify-center mt-3">
        <mat-chip>{{ skill }}</mat-chip>
      </mat-chip-set>
      }
    </div>
    }
  </mat-card-header>
</mat-card>
}
