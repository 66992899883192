<div class="flex flex-col gap-2">



    <div class="flex justify-start items-center gap-2">
        @if(to['icon']){
        <mat-icon [svgIcon]="to['icon']" [color]="to['iconColor']"> </mat-icon>
        }
        @switch (to['typeText']) {

        @case ("h1") {
        <h1 class="mb-0"
            [ngClass]="{'mat-primary-color': to['textColor']=='primary','mat-accent-color': to['textColor']=='accent','mat-warn-color': to['textColor']=='warn'}">
            {{ to['label']}}</h1>
        }

        @case ("h2") {
        <h2 class="mb-0"
            [ngClass]="{'mat-primary-color': to['textColor']=='primary','mat-accent-color': to['textColor']=='accent','mat-warn-color': to['textColor']=='warn'}">
            {{ to['label']}}</h2>
        }

        @case ("h3") {
        <h3 class="mb-0"
            [ngClass]="{'mat-primary-color': to['textColor']=='primary','mat-accent-color': to['textColor']=='accent','mat-warn-color': to['textColor']=='warn'}">
            {{ to['label']}}</h3>
        }

        @case ("h4") {
        <h4 class="mb-0"
            [ngClass]="{'mat-primary-color': to['textColor']=='primary','mat-accent-color': to['textColor']=='accent','mat-warn-color': to['textColor']=='warn'}">
            {{ to['label']}}</h4>
        }

        @case ("h5") {
        <h5 class="mb-0"
            [ngClass]="{'mat-primary-color': to['textColor']=='primary','mat-accent-color': to['textColor']=='accent','mat-warn-color': to['textColor']=='warn'}">
            {{ to['label']}}</h5>
        }

        @case ("h6") {
        <h6 class="mb-0"
            [ngClass]="{'mat-primary-color': to['textColor']=='primary','mat-accent-color': to['textColor']=='accent','mat-warn-color': to['textColor']=='warn'}">
            {{ to['label']}}</h6>
        }

        @case ("p") {
        <p class="mb-0"
            [ngClass]="{'mat-primary-color': to['textColor']=='primary','mat-accent-color': to['textColor']=='accent','mat-warn-color': to['textColor']=='warn'}">
            {{ to['label']}}</p>
        }

        @default {
        <span class="text-base font-bold mx-auto">{{ to['label']}}</span>
        }
        }

    </div>
    @if(to['subtitle']){
    <span *ngIf="to['subtitle']">{{to['subtitle']}}</span>
    }
</div>