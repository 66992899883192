<div class="flex justify-between items-center gap-5 dialog-title">

  <h4 class="mb-0">
    {{
    data.config && data.config.header.label
    ? data.config.header.label
    : "Modifier une offre"
    }}
  </h4>

  <button mat-icon-button [mat-dialog-close]="true">
    <mat-icon class="dark:text-white">close</mat-icon>
  </button>
</div>

<mat-dialog-content class="mat-typography flex flex-col justify-start items-stretch gap-5">

  <form [formGroup]="dialogForm" (ngSubmit)="submitDialogForm()">
    <formly-form [model]="dialogModel" [fields]="fieldsForm" [options]="options" [form]="dialogForm"
      [ngClass]="cssClasses"></formly-form>
  </form>

  <div class="flex justify-end items-center gap-5 dialog-actions" *ngIf="data.config && data.config.actions">
    <button mat-stroked-button mat-dialog-close [mat-dialog-close]="true">
      {{ data.config.actions.cancel ? data.config.actions.cancel : "Annuler" }}
    </button>
    <button mat-flat-button color="primary" (click)="submitDialogForm()" [disabled]="!dialogForm.valid">
      {{ data.config.actions.submit ? data.config.actions.submit : "Valider" }}
    </button>
  </div>

</mat-dialog-content>