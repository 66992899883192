<ng-container *ngIf="data.config">
  <div class="dialog-title flex flex-row justify-between items-center gap-5" *ngIf="data.config.header">
    <div class="flex flex-row justify-start items-center gap-2">
      <!-- 
      <svg-icon
        *ngIf="data.config.header.icon"
        [src]="'assets/icon/' + data.config.header.icon + '.svg'"
        fxLayout="row"
        fxLayoutAlign="center center"
        class="mat-primary-color"
        [svgStyle]="{ 'width.px': 32, 'height.px': 32 }"
      ></svg-icon>
     -->
      <h3 class="dialog-name" *ngIf="data.config.header.label">
        {{ data.config.header.label }}
      </h3>
    </div>
    <button mat-icon-button [mat-dialog-close]="true">
      <mat-icon class="text-white">close</mat-icon>
    </button>
  </div>

  <mat-dialog-content class="mat-typography flex flex-col justify-center items-center gap-8"
    *ngIf="data.config.content">
    <div class="flex flex-col justify-center items-center gap-2">



      <svg width="445" height="256" viewBox="0 0 445 256" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="90" y="31" width="251" height="182" rx="5" fill="#ececec" />
        <g filter="url(#filter0_d_2926_8025)">
          <rect x="134" y="132" width="251" height="60" rx="5" fill="white" />
          <rect x="139" y="137" width="50" height="50" rx="5" fill="#a50113" />
          <rect x="146.143" y="144.143" width="35.7143" height="35.7143" rx="5" fill="#a50113" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M167.686 150.752L178.503 169.525C178.741 170.085 178.845 170.541 178.875 171.014C178.934 172.12 178.547 173.195 177.788 174.022C177.03 174.847 176.003 175.333 174.887 175.392H153.105C152.644 175.364 152.183 175.259 151.751 175.097C149.594 174.227 148.552 171.78 149.43 169.658L160.321 150.739C160.693 150.074 161.259 149.501 161.958 149.132C163.981 148.01 166.555 148.747 167.686 150.752ZM165.291 163.124C165.291 163.832 164.71 164.423 163.996 164.423C163.282 164.423 162.687 163.832 162.687 163.124V158.952C162.687 158.243 163.282 157.67 163.996 157.67C164.71 157.67 165.291 158.243 165.291 158.952V163.124ZM163.996 169.466C163.282 169.466 162.687 168.875 162.687 168.168C162.687 167.459 163.282 166.869 163.996 166.869C164.71 166.869 165.291 167.446 165.291 168.152C165.291 168.875 164.71 169.466 163.996 169.466Z"
            fill="#FAFAFA" />
          <path d="M204 152H254" stroke="#a50113" stroke-opacity="0.1" stroke-width="8" stroke-linecap="round" />
          <path d="M204 172H304" stroke="#a50113" stroke-opacity="0.1" stroke-width="8" stroke-linecap="round" />
        </g>
        <g filter="url(#filter1_d_2926_8025)">
          <rect x="60" y="56" width="251" height="60" rx="5" fill="white" />
          <rect x="65" y="61" width="50" height="50" rx="5" fill="#a50113" />
          <rect x="72.1426" y="68.1426" width="35.7143" height="35.7143" rx="5" fill="#a50113" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M93.686 74.7524L104.503 93.5249C104.741 94.0853 104.845 94.5409 104.875 95.0142C104.934 96.1201 104.547 97.1951 103.788 98.0223C103.03 98.8466 102.003 99.3332 100.887 99.3922H79.1051C78.6439 99.3641 78.1827 99.2595 77.7512 99.0973C75.5938 98.2273 74.5523 95.7795 75.4301 93.6576L86.3212 74.7391C86.6931 74.0741 87.2585 73.5005 87.9578 73.1319C89.9813 72.0097 92.5552 72.747 93.686 74.7524ZM91.2906 87.1239C91.2906 87.8317 90.7103 88.423 89.9961 88.423C89.282 88.423 88.6868 87.8317 88.6868 87.1239V82.9524C88.6868 82.2431 89.282 81.6695 89.9961 81.6695C90.7103 81.6695 91.2906 82.2431 91.2906 82.9524V87.1239ZM89.9961 93.4659C89.282 93.4659 88.6868 92.8746 88.6868 92.1683C88.6868 91.4591 89.282 90.8693 89.9961 90.8693C90.7103 90.8693 91.2906 91.4458 91.2906 92.1521C91.2906 92.8746 90.7103 93.4659 89.9961 93.4659Z"
            fill="#FAFAFA" />
          <path d="M130 76H180" stroke="#a50113" stroke-opacity="0.1" stroke-width="8" stroke-linecap="round" />
          <path d="M130 96H230" stroke="#a50113" stroke-opacity="0.1" stroke-width="8" stroke-linecap="round" />
        </g>
        <defs>
          <filter id="filter0_d_2926_8025" x="74" y="76" width="371" height="180" filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="30" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.0156863 0 0 0 0 0.0235294 0 0 0 0 0.0588235 0 0 0 0.08 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2926_8025" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2926_8025" result="shape" />
          </filter>
          <filter id="filter1_d_2926_8025" x="0" y="0" width="371" height="180" filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="30" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.0156863 0 0 0 0 0.0235294 0 0 0 0 0.0588235 0 0 0 0.08 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2926_8025" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2926_8025" result="shape" />
          </filter>
        </defs>
      </svg>


      <h3 class="mat-warn-color dialog-title text-center mb-0" *ngIf="data.config.content.title">
        {{ data.config.content.title }}
      </h3>

      <p class="dialog-text" *ngIf="data.config.content.text">
        {{ data.config.content.text }}
      </p>

    </div>

    <div class="dialog-actions flex flex-row justify-center items-center gap-2">
      <button mat-stroked-button mat-dialog-close>
        {{
        data.config.actions.cancel ? data.config.actions.cancel : "Annuler"
        }}
      </button>
      <button mat-flat-button color="warn" (click)="submitDialogForm()">
        {{
        data.config.actions.submit ? data.config.actions.submit : "Supprimer"
        }}
      </button>
    </div>
  </mat-dialog-content>
</ng-container>