import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from '@env';
import { Observable, catchError, throwError } from 'rxjs';

const BASE_URL = env.apiURL;

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  private options = { headers: new HttpHeaders().set('Content-Type', 'application/json'), withCredentials: true };

  constructor(private httpClient: HttpClient) { }

  public get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.httpClient.get(BASE_URL + "/" + path, { params, withCredentials: true }).pipe(catchError(this.formatErrors));
  }


  public formatErrors(error: any): Observable<any> {
    return throwError(() => error.error);
  }
}
