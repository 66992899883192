import { Component, Input } from '@angular/core';

export interface GameCardConfig {
  id: string,
  showGameChapterTitle: {
    enable: boolean,
    title: string,
    order: number,

  },
  showGameChapterSkills: {
    enable: boolean,
    title: string,

  },
  showGameChapterDescription: {
    enable: boolean,
    title: string,

  },
  showGameChapterIcon: {
    enable: boolean,
    path: string,
  },
  showGameButton?: GameCardInterraction,
  showGameContentButton?: GameCardInterraction,

  isLocked: boolean,
  isCompleted?: boolean,

  backgroundColor: string,
  fontColor: string,

  backgroundImage: string,

}


export interface GameCardInterraction {
  enable: boolean;
  buttonIcon?: string,
  buttonLabel?: string,

  fontColor?: string,
  color?: string,

  options?: {
    isLocked: boolean,
  }

}

@Component({
  selector: 'app-game-card',
  templateUrl: './game-card.component.html',
  styleUrl: './game-card.component.scss'
})


export class GameCardComponent {

  @Input() gameCardConfig!: GameCardConfig;

  constructor() {
  }


  ngOnInit(): void {

  }

}
