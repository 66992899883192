<mat-form-field [style.color]="color" [cpPosition]="'top'" [cpPositionOffset]="'50%'" [cpPositionRelativeToArrow]="true"
  [(colorPicker)]="color" (colorPickerChange)="onColorChange($event)" appearance="outline"
  class="relative w-full custom-input-file" [floatLabel]="'always'">
  <mat-label>{{to['label']??'Pièce jointe'}}</mat-label>


  <div class="flex flex-row items-center gap-2.5">
    <div [ngStyle]="{'background-color': field.formControl?.value ? field.formControl?.value : color}"
      class="flex h-6 w-6 my-2.5 items-center rounded overflow-hidden">
    </div>

    <input matInput [placeholder]="field.formControl?.value ? field.formControl?.value : color" type="text" readonly
      [(ngModel)]="valueToDisplayInInput" class="opacity-1 py-1">
  </div>


  <mat-icon matIconSuffix svgIcon="painting-light"></mat-icon>

</mat-form-field>