<mat-form-field style="width: 100%">
  <mat-label>{{ to["label"] }}</mat-label>

  <mat-icon matPrefix svgIcon="custom-table/search-document-light"></mat-icon>

  <input matInput #chipInput [matAutocomplete]="auto" [formControl]="getFormControl()" [formlyAttributes]="field"
    [matChipInputFor]="chipGrid" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    (matChipInputTokenEnd)="add($event)" [placeholder]="
      to.placeholder
        ? to.required
          ? to.placeholder + ' *'
          : to.placeholder
        : ''
    " [required]="to.required ?? false" />

  <mat-autocomplete #auto="matAutocomplete" [displayWith]="customDisplayFn(filter | async)"
    (optionSelected)="selected($event)">
    <mat-option *ngFor="let value of filter | async" [value]="to['valueProp'] ? value[to['valueProp']] : value"
      [disabled]="optionAlreadySelected(value)">
      @if(typeLabelProp=='function'){
      {{ to["labelProp"](value) }}
      }@else{
      {{ to["labelProp"] ? value[to["labelProp"]] : value }}
      }
    </mat-option>
  </mat-autocomplete>

  <mat-error>
    <formly-validation-message [field]="field"></formly-validation-message>
  </mat-error>

  <mat-hint *ngIf="to.description">
    {{ to.description }}
  </mat-hint>
</mat-form-field>

<mat-chip-grid #chipGrid aria-label="Chip selection" style="margin-top: 5px">
  @for (chip of chipListValues; track chip) {
  <mat-chip-row (removed)="remove(chip)" class="mat-primary-background-light-pastel">
    <span class="mat-accent-color">
      {{ chip.label }}
    </span>

    <button matChipRemove [attr.aria-label]="'remove ' + chip.label">
      <mat-icon color="primary">cancel</mat-icon>
    </button>
  </mat-chip-row>
  }
</mat-chip-grid>