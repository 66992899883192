import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, ViewChild, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as i3 from '@ngx-formly/core';
import { FormlyModule } from '@ngx-formly/core';
import * as i2 from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import * as i1 from '@angular/material/slide-toggle';
import { MatSlideToggle, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FieldType, FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';
class FormlyFieldToggle extends FieldType {
  constructor() {
    super(...arguments);
    this.defaultOptions = {
      props: {
        hideFieldUnderline: true,
        floatLabel: 'always',
        hideLabel: true
      }
    };
  }
  onContainerClick(event) {
    this.slideToggle.focus();
    super.onContainerClick(event);
  }
}
FormlyFieldToggle.ɵfac = /* @__PURE__ */(() => {
  let ɵFormlyFieldToggle_BaseFactory;
  return function FormlyFieldToggle_Factory(t) {
    return (ɵFormlyFieldToggle_BaseFactory || (ɵFormlyFieldToggle_BaseFactory = i0.ɵɵgetInheritedFactory(FormlyFieldToggle)))(t || FormlyFieldToggle);
  };
})();
FormlyFieldToggle.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FormlyFieldToggle,
  selectors: [["formly-field-mat-toggle"]],
  viewQuery: function FormlyFieldToggle_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(MatSlideToggle, 7);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.slideToggle = _t.first);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 9,
  consts: [[3, "id", "name", "formControl", "formlyAttributes", "color", "tabIndex", "required", "labelPosition"]],
  template: function FormlyFieldToggle_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "mat-slide-toggle", 0);
      i0.ɵɵtext(1);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("id", ctx.id)("name", ctx.field.name)("formControl", ctx.formControl)("formlyAttributes", ctx.field)("color", ctx.props.color)("tabIndex", ctx.props.tabindex)("required", ctx.required)("labelPosition", ctx.props.labelPosition);
      i0.ɵɵadvance();
      i0.ɵɵtextInterpolate1(" ", ctx.props.label, " ");
    }
  },
  dependencies: [i1.MatSlideToggle, i1.MatSlideToggleRequiredValidator, i2.NgControlStatus, i2.FormControlDirective, i3.ɵFormlyAttributes, i2.RequiredValidator],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyFieldToggle, [{
    type: Component,
    args: [{
      selector: 'formly-field-mat-toggle',
      template: `
    <mat-slide-toggle
      [id]="id"
      [name]="field.name"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [color]="props.color"
      [tabIndex]="props.tabindex"
      [required]="required"
      [labelPosition]="props.labelPosition"
    >
      {{ props.label }}
    </mat-slide-toggle>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    slideToggle: [{
      type: ViewChild,
      args: [MatSlideToggle, {
        static: true
      }]
    }]
  });
})();
class FormlyMatToggleModule {}
FormlyMatToggleModule.ɵfac = function FormlyMatToggleModule_Factory(t) {
  return new (t || FormlyMatToggleModule)();
};
FormlyMatToggleModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyMatToggleModule
});
FormlyMatToggleModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, ReactiveFormsModule, MatSlideToggleModule, FormlyMatFormFieldModule, FormlyModule.forChild({
    types: [{
      name: 'toggle',
      component: FormlyFieldToggle,
      wrappers: ['form-field']
    }]
  })]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyMatToggleModule, [{
    type: NgModule,
    args: [{
      declarations: [FormlyFieldToggle],
      imports: [CommonModule, ReactiveFormsModule, MatSlideToggleModule, FormlyMatFormFieldModule, FormlyModule.forChild({
        types: [{
          name: 'toggle',
          component: FormlyFieldToggle,
          wrappers: ['form-field']
        }]
      })]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyFieldToggle, FormlyMatToggleModule };
