import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ConfigModel } from './decorators/config.model';
import { TableColumnConfig } from './decorators/table-column.model';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ApiService } from './service/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';

import { TableDeleteDialogComponent } from './dialog/delete-dialog/delete-dialog.component';
import { catchError, map, merge, startWith, switchMap } from 'rxjs';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatMenuTrigger } from '@angular/material/menu';
import { TranslateService } from '@core/services/translate.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipListbox } from '@angular/material/chips';
import { IconResolver, MatIconRegistry } from '@angular/material/icon';

@Component({
  selector: 'layxo-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CustomTableComponent {

  // https://muhimasri.com/blogs/add-and-remove-table-rows-using-angular-material/#remove-a-row

  // Custom Variable
  fileChanged = false;
  isLoadingResults = true;
  dataFirstChange = true;

  // MAT TABLE VARIABLES
  dataSource: MatTableDataSource<any>;
  columns: Array<any> = []
  displayedColumns = this.columns.map((c: any) => c.columnDef);
  expandedElement: any | null;

  // SELECT ROW VARIABLES
  selection = new SelectionModel<any>(true, []);
  selectedRow = new Set();

  // VIEWCHILD VARIABLES
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatMenuTrigger) filterMenu!: MatMenuTrigger;

  // MAT PAGINATOR
  paginate: any = {
    links: {},
    meta: {}
  }
  resultsLength = 0;

  // Responsive
  isMobile = false;



  /*  -------------------------------- 1. INPUTS / OUTPUTS --------------------------------  */
  @Input() data!: any;
  @Input() config!: ConfigModel;
  @Output() actionModalEvent = new EventEmitter<{ type: string, data: Array<any>, config?: any }>();
  /*  -------------------------------- / 1. INPUTS / OUTPUTS --------------------------------  */

  /*  -------------------------------- 2. ⌛️ STATE & EVENT --------------------------------  */
  constructor(private apiService: ApiService, private sanitizer: DomSanitizer, public dialog: MatDialog, private translateService: TranslateService, iconRegistry: MatIconRegistry,) {

    this.dataSource = new MatTableDataSource<any>();
    // Check if user is on a mobile device
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      // true for mobile device
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

  }


  ngOnChanges(changes: SimpleChanges): void {

    if (changes['data']) {

      // 1. Search if only data or paginate request
      if (this.data.meta) {
        this.setPaginatorLengthFromPaginateRequest(this.data.meta.totalItems ?? 0);
        this.data = this.data.data
      }

      // 2. Load Data & Set Paginator
      // For Paginate Request : Allow to show only number item = pageSize in config var
      if (this.config.paginator) {
        this.setPaginator(); // Update Paginator with user config
        if (this.config.paginator?.pageSize) {
          this.dataSource.data = this.data.slice(0, this.config.paginator?.pageSize)
        }
      } else {
        // Else Classic Display : With full data
        this.dataSource.data = this.data;
      }

      this.dataFirstChange = changes['data'].firstChange;
      if (changes['data'].currentValue.length == 0) {
        this.isLoadingResults = false;
      }

      // 3. Create Config for columns
      // If Config
      if (this.config && this.config.columns) {
        this.customBuildConfigColumns(this.config ? this.config.columns : this.data ? this.data[0] : [])
      } else {
        // Default Build Config
        this.defaultBuildConfigColumns(this.data ? this.data[0] : [])
      }

    }

  }

  ngAfterViewInit() {


    setTimeout(() => {
      // If the user changes the sort order, reset back to the first page.
      this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

      // ----------------------- FOR DEV ----------------------- //
      // this.openFilterMenu()
      // ----------------------- / FOR DEV ----------------------- //

      merge(this.sort.sortChange, this.paginator.page)
        .pipe(
          startWith({}),
          switchMap(() => {
            this.isLoadingResults = true;
            if (this.config.api && (this.data == undefined || !this.dataFirstChange)) {
              return this.apiService.getAPIGlobalPaginated(this.config.api ?? "", this.paginator.pageIndex, this.paginator.pageSize, this.searchFormValue, this.sort.active ? (this.sort.active + ':' + this.sort.direction.toUpperCase()) : '', this.filterRequestString);
            } else {
              this.isLoadingResults = false;
              return [];
            }
          }),

          map(data => {

            // Flip flag to show that loading has finished.
            this.isLoadingResults = false;
            //this.isRateLimitReached = data === null;
            if (data === null) {
              return [];
            }

            // If translation is enabled for a column, update data
            if (data.data.length > 0) {
              let fieldToTranslate = this.columns.filter(col => col.translate);
              fieldToTranslate.forEach(field => {
                data.data = this.translateService.translateField(data['data'] ?? data, field.translate);
              });
            }
            this.resultsLength = data.meta.totalItems ?? 0;
            // Only refresh the result length if there is new data. In case of rate
            // limit errors, we do not want to reset the paginator to zero, as that
            // would prevent users from re-triggering requests.
            return data.data;
          }),
        )
        .subscribe(data => {
          this.dataSource = new MatTableDataSource(data)

          //this.dataSource.data = data
        }
        );
    });

    // FOR DEV 
    // this.openFilterMenu()
  }

  getResultsLength() {
    return this.resultsLength;
  }
  /*  -------------------------------- / 2. ⌛️ STATE & EVENT --------------------------------  */

  /*  -------------------------------- 👑 CORE --------------------------------  */

  defaultBuildConfigColumns(header: Object) {
    if (this.data && this.data.length > 0) {
      const configHeader = Object.entries(header).map((coupleKeyValueArray: any) => ({ key: coupleKeyValueArray[0], label: this.textWithoutSpecialChars(coupleKeyValueArray[0]), value: coupleKeyValueArray[1] }));
      this.createColumnConfig(configHeader);
    }
  }

  customBuildConfigColumns(header: Array<any>) {
    if (this.data && this.data.length > 0) {
      const configHeader = header.map((item: any) => item = { ...item, value: this.data[0][item.key] });
      this.createColumnConfig(configHeader)
    }
  }

  createColumnConfig(inputDataHeader: Array<any>) {
    this.columns = [] // Reset Existing Columns to avoid duplication in the configuration on reload data

    // Create Column Config
    inputDataHeader.forEach((column) => {
      let columnConfig: TableColumnConfig = {
        columnDef: column.key ? column.key : '',
        header: column.label ? column.label : '',
        iconHeader: column.icon ? column.icon : undefined,
        type: column.type ? column.type : this.determineObjectType(column.value),
        show: column.show !== undefined ? column.show : true,
        cell: column.cell ? column.cell : (element: any) => `${this.resolvePath(element, (column.pattern ? column['pattern'] : column['key']), '')}`,
        class: column.class ? column.class : undefined,
        translate: column.translate ? column.translate : undefined,
      }
      this.columns.push(columnConfig)
    });

    // Add Options Actions
    if (this.config) {

      // Button Edit & Delete
      if (this.config && (this.config.editOptions?.active || this.config.deleteOptions?.active || (this.config.customActions && this.config.customActions.length > 0))) {
        let col = {
          columnDef: 'action',
          header: '',
          type: 'action',
          show: true,
          customActions: (this.config.customActions && this.config.customActions.length > 0) ? true : false, // Custom Action Button
          cell: (element: any) => ``,
        }

        this.columns.push(col);
      }

      // Button Select ( Checkbox )
      if (this.config.selectOptions?.active) {
        let col = {
          columnDef: 'select',
          header: '',
          type: 'selectRow',
          show: true,
          cell: (element: any) => ``,
        }
        this.columns.unshift(col);
      }
    }

    // Update displayedColumns with new config
    this.displayedColumns = this.columns.filter((c: any) => c.show == true).map((c: any) => c.columnDef);
    this.isLoadingResults = false;
  }


  /*  -------------------- 3. 🔍 FILTER --------------------  */
  /**
   * Filter data with input
   * @param event 
   */
  searchFormValue = "";
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchFormValue = filterValue;
    this.apiService.getAPIGlobalPaginated(this.config.api ?? "", this.paginator.pageIndex, this.paginator.pageSize, filterValue, undefined, this.filterRequestString).subscribe(data => {
      this.resultsLength = data.meta.totalItems;

      // If translation is enabled for a column, update data
      if (data.data.length > 0) {
        let fieldToTranslate = this.columns.filter(col => col.translate);
        fieldToTranslate.forEach(field => {
          data.data = this.translateService.translateField(data['data'] ?? data, field.translate);
        });
      }

      this.dataSource.data = data.data;
    });
  }
  resetFilter() {
    this.searchFormValue = "";
    this.apiService.getAPIGlobalPaginated(this.config.api ?? "", this.paginator.pageIndex, this.paginator.pageSize, this.searchFormValue).subscribe(data => {
      this.resultsLength = data.meta.totalItems;

      // If translation is enabled for a column, update data
      if (data.data.length > 0) {
        let fieldToTranslate = this.columns.filter(col => col.translate);
        fieldToTranslate.forEach(field => {
          data.data = this.translateService.translateField(data['data'] ?? data, field.translate);
        });
      }

      this.dataSource.data = data.data;
    });
  }

  /*  --------- 3.1 FILTER MENU ---------  */

  filtersList: any = []
  filtersForm: FormGroup = new FormGroup({})
  filterRequestString = "";

  // 3.1 FILTER MENU - Global Methods
  openFilterMenu() {
    this.filterMenu.openMenu();
    this.config.filterBtnOptions?.filters.forEach(filter => {
      this.setUpFormManagementForFilterType(filter); // Set Up Form Management
    });
  }

  closeFilterMenu() {
    this.filterMenu.closeMenu()
  }


  addFilter(filter: any) {
    const indexFilter = this.filtersList.findIndex((x: any) => x.id === filter.id);

    // If filter not existing
    if (indexFilter == -1) {
      this.filtersList.push(filter);
      this.updateFilteredData();
    } else { // Filter already exist
      // Remove Filte r: If new value is empty remove filter from filtersList
      if (filter.value.length == 0) {
        this.filtersList.splice(indexFilter, 1);
      } else {
        // Update : add new filter value to filtersList 
        this.filtersList[indexFilter].value = filter.value;
        this.filtersList[indexFilter].label = filter.label;
      }
      this.updateFilteredData();
    }
  }

  removeFilter(filter: any) {
    // Case Checkbox - Clear Checkbox List
    if (filter.selectionModel) {
      filter.selectionModel.clear();
    }

    // Remove Value from Filter Form
    let fieldLinkToFormControl = this.getFilterFormControl(filter.id)
    if (fieldLinkToFormControl) {
      fieldLinkToFormControl.reset();
    }
    // Remove from Filter List and reload data
    const indexFilter = this.filtersList.findIndex((x: any) => x.id === filter.id)
    this.filtersList.splice(indexFilter, 1);
    this.updateFilteredData();
  }

  /**
   * Loading Data for filter
   * @param filter 
   */
  getFilterData(filter: any) {
    // Cancel Load if already data
    if (!filter.contentLoad) {
      if (filter.fieldData.type == "local") {
        filter.contentLoad = true;
      } else if (filter.fieldData.type == "api") {
        filter.contentLoad = true;
        this.apiService.getAPIGlobal(filter.fieldData.url).subscribe(data => {
          data = data.data ?? data;
          // If exist pattern -> Transform data with pattern Ex: [ {id:0,name:'a'}] -> [ 'a' ]
          if (filter.fieldLabel && filter.fieldValue) {
            data = data.map((x: any) => x = { value: this.resolvePath(x, filter.fieldValue, ''), label: this.resolvePath(x, filter.fieldLabel, ''), icon: filter.fieldData.options && filter.fieldData.options.icon ? this.resolvePath(x, filter.fieldData.options.icon, '') : undefined });
          }
          filter.fieldData.data = filter.fieldData.data.length > 0 ? filter.fieldData.data.push(data) : data;

        });
      }
    }
  }

  /**
   * Update Data for filter
   */
  updateFilteredData() {
    let listPatternNewData: Array<any> = [];// Store all field to transform ( name['fr']....)
    this.filterRequestString = "";
    let indexFilter = 0;

    // Case Exist Filters
    this.filtersList.forEach((filter: any) => {
      this.filterRequestString += indexFilter === 0 ? filter.parentSource + "=" : "&filter." + filter.parentSource + "=$or:";
      this.filterRequestString += Array.isArray(filter.value) ? filter.value.join("&filter." + filter.parentSource + "=$or:") : filter.value;
      if (filter.fieldLabel) listPatternNewData.push(filter.fieldLabel) // Push Field Pattern to transform
      indexFilter++;
    });

    // Case Reset All Filters - And need to reset value with good field
    if (this.filtersList.length == 0) {
      this.config.filterBtnOptions?.filters.forEach(filter => {
        listPatternNewData.push(filter.fieldLabel)
      });
    }

    this.apiService.getApiGlobalWithFilters(this.config.api ?? "", this.filterRequestString, this.searchFormValue).subscribe(data => {
      var newData = data.data ?? data
      // For each pattern, transform data 
      listPatternNewData.forEach((pattern: string) => {
        if (pattern) {
          newData.forEach((el: any) => {
            this.setPath(el, pattern.split('.')[0], ' ', this.resolvePath(el, pattern, ''))
          });
        }
      })
      this.dataSource.data = newData;
      this.setPaginatorLengthFromPaginateRequest(data.meta.totalItems ?? 0);
    });

  }

  // 3.1 FILTER MENU - Form Management
  setUpFormManagementForFilterType(filter: any) {

    switch (filter.fieldType) {
      case 'autocomplete':
        this.filtersForm.addControl(filter.name, new FormControl(''));

        // Subscribes to formControl to listen for any change in input value
        this.filtersForm.get(filter.name)?.valueChanges.pipe(
          startWith('')).subscribe(value => {

            // Trigger only if user select a value in autocomplete list
            if (value.value && value.label) {
              this.addFilter({
                id: filter.name,
                value: value.value,
                label: [value.label],
                fieldType: filter.fieldType,
                fieldValue: filter.fieldValue,
                fieldLabel: filter.fieldLabel ?? "",
                parentSource: filter.parentSource ?? "",
              });
            }
            else {
              // Else user is searching -> call API with the new value
              this.apiService.getAPIGlobalPaginated(filter.fieldData.url, 0, 10, value).subscribe(data => {
                data = data.data ?? data;
                // If exist pattern -> Transform data with pattern Ex: [ {id:0,name:'a'}] -> [ 'a' ]
                if (filter.fieldLabel && filter.fieldValue) {
                  data = data.map((x: any) => x = { value: this.resolvePath(x, filter.fieldValue, ''), label: this.resolvePath(x, filter.fieldLabel, '') });
                  filter.fieldData.data = data;
                }
              });
            }
          });
        break;

      case 'chip':
        this.filtersForm.addControl(filter.name, new FormControl([]));
        break;

      case 'slider':
        let sliderFormGroup = new FormGroup({
          start: new FormControl(),
          end: new FormControl()
        });
        this.filtersForm.addControl(filter.name, sliderFormGroup);

        break;

      case 'date':
        let dateFormGroup = new FormGroup({
          start: new FormControl<Date | null>(null),
          end: new FormControl<Date | null>(null)
        });
        this.filtersForm.addControl(filter.name, dateFormGroup);
        break;

      case 'toggle':
        this.filtersForm.addControl(filter.name, new FormControl(false));
        break;

      default:
        break;
    }

  }

  getFilterFormControl(name: any) {
    return this.filtersForm.get(name) as FormControl;
  }

  getFilterFormGroup(name: any) {
    return this.filtersForm.get(name) as FormGroup;
  }

  // 3.1 FILTER MENU - Field Type

  // 3.1.1 AutoComplete
  displayFnAutoCompleteFilter(value: any) {
    return value.label ? value.label : value;
  }

  // 3.1.2 Checkbox
  // Form management for a checkbox filter
  onSelection(e: any, v: any, filter: any) {
    const filterCheckbox = {
      id: filter.name,
      value: v.selected.map((o: any) => o.value.value),
      label: v.selected.map((o: any) => o.value.label),
      fieldType: filter.fieldType,
      fieldValue: filter.fieldValue,
      fieldLabel: filter.fieldLabel ?? "",
      parentSource: filter.parentSource ?? "",
      selectionModel: v,
    };
    this.addFilter(filterCheckbox);
  }

  // 3.1.3 Chip List
  onSelectionChip(e: any, filter: any) {
    const filterChipList = {
      id: filter.name,
      value: e.value.map((chip: any) => chip.value),
      label: e.value.map((chip: any) => chip.label),
      fieldType: filter.fieldType,
      fieldValue: filter.fieldValue,
      fieldLabel: filter.fieldLabel ?? "",
      parentSource: filter.parentSource ?? "",
    };
    this.addFilter(filterChipList);
  }

  // 3.1.4 Slider
  sliderChange(type: string, event: any, filter: any) {
    if (this.filtersForm.get(filter.name)) {
      this.filtersForm.get(filter.name)?.get(type)?.setValue(event);
      if (this.filtersForm.get(filter.name)?.get('start')?.value && this.filtersForm.get(filter.name)?.get('end')?.value) {
        const filterSliderType = {
          id: filter.name,
          value: "$btw:" + this.filtersForm.get(filter.name)?.get('start')?.value + "," + this.filtersForm.get(filter.name)?.get('end')?.value,
          label: [" de " + this.filtersForm.get(filter.name)?.get('start')?.value + " à " + this.filtersForm.get(filter.name)?.get('end')?.value],
          fieldType: filter.fieldType,
          fieldValue: filter.fieldValue,
          fieldLabel: filter.fieldLabel ?? "",
          parentSource: filter.parentSource ?? "",
        };
        this.addFilter(filterSliderType);
      }
    }
  }

  // 3.1.5 Date
  datePickerChange(type: string, event: any, filter: any) {
    if (this.filtersForm.get(filter.name)) {
      this.filtersForm.get(filter.name)?.get(type)?.setValue(event.value);
      if (this.filtersForm.get(filter.name)?.get('start')?.value && this.filtersForm.get(filter.name)?.get('end')?.value) {
        const filterDateType = {
          id: filter.name,
          value: "$btw:" + this.filtersForm.get(filter.name)?.get('start')?.value.toISOString() + "," + this.filtersForm.get(filter.name)?.get('end')?.value.toISOString(),
          label: [" du " + this.filtersForm.get(filter.name)?.get('start')?.value.toLocaleDateString('fr-FR') + " au " + this.filtersForm.get(filter.name)?.get('end')?.value.toLocaleDateString('fr-FR')],
          fieldType: filter.fieldType,
          fieldValue: filter.fieldValue,
          fieldLabel: filter.fieldLabel ?? "",
          parentSource: filter.parentSource ?? "",
        };
        this.addFilter(filterDateType);
      }
    }
  }

  // 3.1.6 Toggle
  toggleChange(event: any, filter: any) {
    const filterToggle = {
      id: filter.name,
      value: [event.checked],
      label: [this.filtersForm.get(filter.name)?.value ? "Oui" : "Non"],
      fieldType: filter.fieldType,
      fieldValue: filter.fieldValue,
      fieldLabel: filter.fieldLabel ?? "",
      parentSource: filter.parentSource ?? "",
    };
    this.addFilter(filterToggle);
  }

  /*  --------- / 3.1 FILTER MENU ---------  */



  // 4. ADD / UPDATE / DELETE / EXPAND - ROW
  addRow() {
    if (this.config.addOptions?.active && this.config.addOptions?.type == "row") {
      var newRow: any = { ...this.dataSource.data.length > 0 ? this.dataSource.data[0] : [] };
      // Empty Value 
      Object.keys(newRow).forEach((props: any) => {
        newRow[props] = "";
      });
      newRow.isEdit = true; // Set Edit to true
      this.dataSource.data = [newRow, ...this.dataSource.data]; // Update Mat Table Data
    } else if (this.config.addOptions?.active) {
      this.actionModalEvent.emit({ type: "add", data: [] });
    }
  }

  // Update OR Create Row
  updateRow(row: any) {
    const savedRow = { ...row };

    row.isEdit = !row.isEdit;
    if (this.config.api) {
      // CREATE new Row because row.id empty string -> row created in createNewRow()
      if (row.id == "") {
        delete row['id'];

        this.apiService.createAPIGlobal(this.config.api, row).subscribe({
          next: (updatedRow) => {

            // Update Images
            const fieldTypeFile = this.columns.filter(field => field.type == 'file');
            fieldTypeFile.forEach(field => {
              // If existing new Data to update
              if (savedRow[`new_${field.columnDef}`]) {
                this.updateRowImage(updatedRow, field.columnDef, savedRow[`new_${field.columnDef}`]);
              }
            });

          },
          error: (e) => console.log(e),
          complete: () => this.apiService.openSnackBar("Votre élément a été crée!", 'Fermer')
        });
      } else { // Row already existing so update it
        this.apiService.updateAPIGlobal(this.config.api, row.id, row).subscribe({
          next: (updatedRow) => {

            // Update Images
            const fieldTypeFile = this.columns.filter(field => field.type == 'file');
            fieldTypeFile.forEach(field => {
              // If existing new Data to update
              if (savedRow[`new_${field.columnDef}`]) {
                this.updateRowImage(updatedRow, field.columnDef, savedRow[`new_${field.columnDef}`]);
              }
            });

          },
          error: (e) => console.log(e),
          complete: () => this.apiService.openSnackBar("Votre modification a été sauvegardée !", 'Fermer')
        });
      }
    } else {
      this.apiService.openSnackBar("[ ERROR ] Incorrect API parameter configuration !", 'Close')
    }
  }

  editRow(row: any) {
    if (this.config.editOptions?.type == "row") {
      row.isEdit = !row.isEdit
    } else {
      this.actionModalEvent.emit({ type: "edit", data: [row] })
    }
  }


  // Expand Row
  clickOnMenuButton = false;
  expandRow(row: any) {

    // Only Expand if User not click on menu btn
    if (!this.clickOnMenuButton) {
      this.expandedElement = this.expandedElement === row ? null : row;
    } else {
      this.clickOnMenuButton = false;
    }

    if (this.config.expandOptions?.api) {
      // If Specific Filters
      if (this.config.expandOptions.api.filter) {
        this.config.expandOptions.isLoading = true;
        this.apiService.getApiGlobalWithSingleFilter(this.config.expandOptions.api.path, this.config.expandOptions.api.filter.field + "=" + this.resolvePath(row, this.config.expandOptions.api.filter.key, '')).subscribe(data => {

          if (this.config.expandOptions && this.config.expandOptions.data) {
            this.config.expandOptions.data = data.data
            this.config.expandOptions.isLoading = false;
          }

        });
      } else {
        // Classic Call API

      }
    }
    // console.log('expand', row)
    // this.actionModalEvent.emit({ type: "expand", data: [row], config: { id: row.id } })
  }

  getActionFromCustomTableChild(event: any) {
    this.actionModalEvent.emit(event);
  }

  customActionRow(row: any, itemConfig: any) {
    this.clickOnMenuButton = true;
    this.actionModalEvent.emit({ type: "custom", data: [row], config: itemConfig });
  }

  /**
   * Update Image of Row
   * @param row Object
   * @param columnDef Key of object
   * @param files FileList
   */
  updateRowImage(row: any, columnDef: string, files: any) {

    const formData = new FormData();
    files.forEach((img: any) => {
      formData.append("file", img, img.name);
    });

    if (this.config.api) {
      this.apiService.updateAvatar(this.config.api, row.id, formData).subscribe({
        next: (v) => {
          row = v;

          // Case new Row Added
          if (this.dataSource.data[0].id == undefined) {
            this.dataSource.data[0] = v;
          }

          // Case Existing Row
          const indexItemDataSource = this.dataSource.data.findIndex(item => item.id == row.id);
          if (indexItemDataSource !== -1) {
            this.dataSource.data[indexItemDataSource][columnDef] = v[columnDef]; // Update New Image Url in DataSource
          }

        },
        error: (e) => console.log(e),
      });
    }

  }

  /**
   * On row click redirect to edit
   * @param row 
   * @param type 
   */
  redirectToRow(row: any, type: any) {
    if (type != "action" && !this.config.btnOptions) {
      // OnClick redirect to details page
      if (this.config.editOptions && this.config.editOptions.active) {
        this.editRow(row);
      }
    }
  }

  /**
   * Remove Row from DataSource
   * @param id 
   */
  removeRow(id: any) {
    this.clickOnMenuButton = true;
    if (this.config.api) {

      // Modal Version
      if (this.config.deleteOptions?.type == "modal") {
        // Launch Modal
        const dialogRef = this.dialog.open(TableDeleteDialogComponent, {
          width: '50%',
          data: {
            config: {
              header: {
                icon: 'delete-bulk',
                label: "Supprimer l'élément",
              },
              content: {
                img: "delete-dialog.svg",
                title: "Êtes-vous sûr de vouloir supprimer cet élément ?",
                text: "Attention ! Cette action est irréversible"
              },
              actions: {
                cancel: "Annuler",
                submit: "Supprimer"
              }
            }
          },
        });

        // After Modal Close
        dialogRef.afterClosed().subscribe(result => {
          if (result && this.config.api) {
            this.apiService.deleteAPIGlobal(this.config.api, id).subscribe({
              next: (v) => {
                this.dataSource.data = this.dataSource.data.filter((u: any) => u.id !== id);
              },
              error: (e) => console.log(e),
              complete: () => this.apiService.openSnackBar("Votre élément a été supprimé !", 'Fermer')
            });
          }
        });
      }
      // Row Version
      else if (this.config.deleteOptions?.type == "row") {
        this.apiService.deleteAPIGlobal(this.config.api, id).subscribe({
          next: (v) => {
            this.dataSource.data = this.dataSource.data.filter((u: any) => u.id !== id);
          },
          error: (e) => console.log(e),
          complete: () => this.apiService.openSnackBar("Votre élément a été supprimé !", 'Fermer')
        });
      }
    } else {
      this.apiService.openSnackBar("[ ERROR ] Incorrect API parameter configuration !", 'Close')
    }
  }

  cancelEditRow(row: any) {
    // If Cancel created row
    if (row.id == '') {
      this.dataSource.data = this.dataSource.data.filter((u: any) => u.id !== row.id);
    } else { // Else cancel existing row
      row.isEdit = false;
    }
  }

  // 5. SELECT
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  /** Select Specific Row */
  toggleRow(row: any) {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.isSelected(row) ? this.selection.deselect(row) : this.selection.select(row);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  /** Delete All elements selected  */
  deleteSelectedElements() {
    if (this.config.selectOptions && this.config.selectOptions?.specialConfig) {

      // Set up custom body to send to back { bodyField: [ item[fieldSource], item[fieldSource] ... ] }
      let bodyRequest: any = {};
      const bodyField = this.config.selectOptions.specialConfig.body;
      bodyRequest[this.config.selectOptions.specialConfig.body] = [];

      // Launch Modal
      const dialogRef = this.dialog.open(TableDeleteDialogComponent, {
        width: '50%',
        data: {
          config: {
            header: {
              icon: 'delete-bulk',
              label: "Supprimer l'élément",
            },
            content: {
              img: "delete-dialog.svg",
              title: "Êtes-vous sûr de vouloir supprimer ces éléments ?",
              text: "Attention ! Cette action est irréversible"
            },
            actions: {
              cancel: "Annuler",
              submit: "Supprimer"
            }
          }
        },
      });

      // After Modal Close
      dialogRef.afterClosed().subscribe(result => {
        if (result && this.config.api) {
          if (bodyRequest[bodyField]) {

            this.selection.selected.forEach(item => {
              bodyRequest[bodyField].push(this.resolvePath(item, this.config.selectOptions?.specialConfig?.fieldSource, '')); // Push element item[fieldSource] in array
            });

            // TO DO : $ wait back-end fix error request for add error gestion
            this.apiService.deleteMultipleAPIGlobal(this.config.api ?? "", bodyRequest).subscribe(
              {
                next: (response: any) => { },
                error: (e) => this.apiService.openSnackBar("ERROR : " + this.apiService.displayErrorMessage(e), 'Fermer', 5),
                complete: () => this.apiService.openSnackBar("Les éléments sélectionnés ont été supprimés !", 'Fermer', 5),
              }
            );

          }
        }
      });

    }
  }

  // 6. PAGINATOR
  // Update paginator's pageSizeOptions from the config
  setPaginator() {
    if (this.config && this.config.paginator && this.paginator) {
      if (this.config.paginator.disabled) this.paginator.disabled = this.config.paginator.disabled;
      if (this.config.paginator.hidePageSize) this.paginator.hidePageSize = this.config.paginator.hidePageSize;
      if (this.config.paginator.pageIndex) this.paginator.pageIndex = this.config.paginator.pageIndex;
      if (this.config.paginator.pageSizeOptions) this.paginator.pageSizeOptions = this.config.paginator.pageSizeOptions;

      if (this.config.paginator.length) {
        if (this.resultsLength == 0) {
          this.paginator.length = this.config.paginator.length;
          this.resultsLength = this.config.paginator.length;
        }
      } else {
        if (this.resultsLength == 0)
          this.resultsLength = this.data.length
      }
      if (this.config.paginator.pageSize) this.paginator.pageSize = this.config.paginator.pageSize;
      if (this.config.paginator.showFirstLastButtons) this.paginator.showFirstLastButtons = this.config.paginator.showFirstLastButtons;
    }

  }

  setPaginatorLengthFromPaginateRequest(length: number) {
    if (this.paginator) {
      this.paginator.length = length;
    }

    this.resultsLength = length;
  }

  getPaginatorTotalLength() {
    if (this.paginate && this.paginate.meta) {
      return this.paginate.meta.totalItems
    }
    return 10;
  }

  /*  --------------------------------/ 👑 CORE --------------------------------  */

  /*  --------------------------------  🛠 TOOLS --------------------------------  */

  resolvePath = (object: object, path: any, defaultValue: any) => path.split('.').reduce((o: any, p: any) => o ? o[p] : defaultValue, object)

  setPath = (object: object, path: any, defaultValue: any, newValue?: any) =>
    path.split('.').reduce((o: any, p: any, index: number, array: string[]) => {
      if (index === array.length - 1) {
        // Si c'est la dernière propriété dans le chemin, mettez à jour la valeur si newValue est fourni
        if (newValue !== undefined) {
          o[p] = newValue;
        }
        return o[p] !== undefined ? o[p] : defaultValue;
      } else {
        return o ? o[p] : defaultValue;
      }
    }, object);

  determineObjectType(obj: any): string {

    if (typeof obj === 'string') {
      if (/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+)?Z?/.test(obj)) {
        return 'date';
      }
      else if (/^\d{2}:\d{2}$/.test(obj)) { // REVOIR LE REGEX 
        return 'time';
      } else if (/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/.test(obj)) {
        return 'img';
      }
      else {
        return 'string';
      }
    }
    else if (obj === 'boolean-chip') {
      return 'boolean-chip';
    }
    else if (typeof obj === 'boolean') {
      return 'boolean';
    } else if (typeof obj === 'number') {
      return 'number';
    } else if (obj instanceof Date) {
      return 'date';
    } else if (typeof obj === 'object' && Array.isArray(obj)) {
      return 'array';
    } else if (typeof obj === 'object') {
      if (obj instanceof File || obj instanceof Blob) {
        return 'file';
      } else {
        return 'object';
      }
    } else {
      return 'unknown';
    }
  }

  onFileChange(event: any, row: any, column: any) {
    const files: any = (event.target as HTMLInputElement).files;
    var newArrFiles = [];
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(files[i]));
        files[i]['url'] = url
        newArrFiles.push(files[i]);
      }
    }
    row[`new_${column.columnDef}`] = newArrFiles; // Store new value in variable for update in updateRowImage ()
  }

  getFileSrc(item: any) {
    if (typeof item == 'string') {
      return item;
    } else {
      return ""
      // return item[0]['src'];
    }
  }

  getSafeUrl(url: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  transformFileToFormData(file: any) {
    const formData = new FormData();
    // if (file.includes('images'))
    return formData;
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  containsSpecialChars = (str: string) => {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  }

  textWithoutSpecialChars(string: string) {
    return string.replace(/[`!@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?~]/, " ");
  }

  getCustomButtonColor(config: any) {
    return 'warn'
  }

  // Add class dynamically
  getClassDynamically(col: any, row?: any) {

    // If type String -> Return current Class
    if (typeof col['class'] == "string") {
      if (col['class']) {
        return col['class'];
      }
    }
    // If type Fucntion -> Return current function result
    else if (typeof col['class'] == "function") {
      return col.class(row)
    }
    return ""
  }
  /*  -------------------------------- / 🛠 TOOLS --------------------------------  */
}
