import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-fyyyre-sorting',
  templateUrl: './fyyyre-sorting.component.html',
  styleUrl: './fyyyre-sorting.component.scss'
})
export class FyyyreSortingComponent extends FieldType<FieldTypeConfig> {
  optionList: any = [];
  disableSorting = false;

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.optionList = this.props.options
    //this.field.formControl.setValue(this.optionList)
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.optionList, event.previousIndex, event.currentIndex);
    this.field.formControl.setValue(this.optionList);
  }

  formIsSubmit() {
    return this.options.formState['submit'];
  }

  showAnswers(index: any, option: any) {
    if (this.formIsSubmit()) {
      this.disableSorting = true; // Disable Form Control
      if (this.props['expectedAnswers'][index].value == this.field.formControl.value[index].value) {
        return ["good-answer"]
      } else {
        let goodAnswerLabel = this.optionList.find((el: any) => el.value == this.props['expectedAnswers'][index].value);
        option.answer = goodAnswerLabel.label
        return ["bad-answer"]
      }
    }
    return []
  }
}
