import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'app-repeat',
  templateUrl: './repeat.component.html',
  styleUrl: './repeat.component.scss',
})
export class RepeatComponent extends FieldArrayType {
  globalLength: any = 0;

  ngOnInit(): void {
    this.globalLength = this.field.fieldGroup?.length;
    // Add Item by default
    if (this.field.fieldGroup?.length == 0) {
      this.add();
      this.globalLength = this.field.fieldGroup?.length;
    }
  }

  getIndexToRemove(index: any) {
    return index;
  }

  // Move up an answer in FormArray
  moveFieldUp(index: any) {
    if (this.field.fieldGroup) {
      let tmp = this.field.fieldGroup[index - 1];
      this.field.fieldGroup[index - 1] = this.field.fieldGroup[index];
      this.field.fieldGroup[index] = tmp;
      let tmpModel = this.model[index - 1];
      this.model[index - 1] = this.model[index];
      this.model[index] = tmpModel;
    }
  }

  // Move down an answer in FormArray
  moveFieldDown(index: any) {
    if (this.field.fieldGroup) {
      let tmp = this.field.fieldGroup[index + 1];
      this.field.fieldGroup[index + 1] = this.field.fieldGroup[index];
      this.field.fieldGroup[index] = tmp;
      let tmpModel = this.model[index + 1];
      this.model[index + 1] = this.model[index];
      this.model[index] = tmpModel;
    }
  }

  /* 
  // OLD CODE : With Reverse elment Array 

    getIndexToRemove(index: any) {
    this.globalLength = this.field.fieldGroup?.length
    return (this.globalLength - 1) - index
  }
  
    // Move up an answer in FormArray
    moveFieldUp(index: any) {
      if (this.field.fieldGroup) {
        const fieldGroup = this.field.fieldGroup.reverse();
        let tmp = fieldGroup[index - 1];
        fieldGroup[index - 1] = fieldGroup[index];
        fieldGroup[index] = tmp;
        this.field.fieldGroup = fieldGroup.reverse();
      }
    }
  
    // Move down an answer in FormArray
    moveFieldDown(index: any) {
      if (this.field.fieldGroup) {
        const fieldGroup = this.field.fieldGroup.reverse();
        let tmp = fieldGroup[index + 1];
        fieldGroup[index + 1] = fieldGroup[index];
        fieldGroup[index] = tmp;
        this.field.fieldGroup = fieldGroup.reverse();
      }
    }
   */
}
