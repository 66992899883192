import { Component } from '@angular/core';
import {FieldType} from "@ngx-formly/core";
import {DomSanitizer} from "@angular/platform-browser";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-input-color',
  templateUrl: './input-color.component.html',
  styleUrl: './input-color.component.scss'
})
export class InputColorComponent extends FieldType {
  valueToDisplayInInput: string = ''
  color: string = '#FFFFFF';

  constructor(private sanitizer: DomSanitizer) {
    super();
  }


  getFormControl(): FormControl {
    return this.field.formControl as FormControl;
  }

  ngOnInit() {
    if (this.field.formControl?.value) {
      this.color = this.field.formControl?.value;
    }
  }

  onColorChange(event: any) {
    this.color = event;
    this.valueToDisplayInInput = this.color;
    this.field.formControl?.setValue(this.color);
  }


}
