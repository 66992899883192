<div class="flex flex-col gap-1 w-full">
  <h5>{{ to["label"] }}</h5>

  @if(to["explanation"] && formIsSubmit() && to["showExplanation"]){
  <mat-card class="mat-elevation-z0 py-1 px-2 my-3 glass-card">
    <mat-card-content class="flex flex-col gap-2">
      <div class="flex gap-3 items-center">
        <mat-icon svgIcon="question-mark-circle-light" color="accent"></mat-icon>
        <h5 class="mb-0">Explication des résultats :</h5>
      </div>
      <mat-divider></mat-divider>

      <p class="text-gray-500 dark:text-white font-light">
        {{ to["explanation"] }}
      </p>
    </mat-card-content>
  </mat-card>
  }

  <div class="flex flex-col md:flex-row md:gap-10 w-full">

    <div class="mat-elevation-z0 basis-1/2 py-5 example-list flex flex-col gap-3">
      @for (option of fromOptionsList; track option;let i = $index) {
      <mat-card class="glass-card item p-0"
        [ngClass]="{'good-answer': option.answerState=='good'  ,'bad-answer': option.answerState=='bad','disable-answer': option.answerState=='disable'}"
        [ngStyle]=" dynamicCssStyle(i, 'from' )" (click)="updateSelectedIndex(option,i)">
        <mat-card-content>
          <div class="flex gap-1">
            <span class="user-answer"> {{ option.label | titlecase }}</span>
            {{ option.answer ?? "" | titlecase }}
          </div>
        </mat-card-content>
      </mat-card>
      }
    </div>

    <div class="mat-elevation-z0 basis-1/2 py-5 example-list flex flex-col gap-3">
      @for (option of toOptionsList; track option;let i = $index) {
      <mat-card class="glass-card item p-0 cursor-pointer"
        [ngClass]="{'good-answer': option.answerState=='good'  ,'bad-answer': option.answerState=='bad','disable-answer': option.answerState=='disable'}"
        (click)="verifAnswer(option,i)">
        <mat-card-content>
          <div class="flex gap-1">
            <span class="user-answer"> {{ option.label | titlecase }}</span>
            {{ option.answer ?? "" | titlecase }}
          </div>
        </mat-card-content>
      </mat-card>
      }
    </div>
  </div>

</div>