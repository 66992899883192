<mat-form-field style="width: 100%">

  <mat-label>{{ to["label"] }}</mat-label>
  <mat-icon matPrefix svgIcon="custom-table/search-document-light"></mat-icon>

  <input matInput [matAutocomplete]="auto" [formControl]="getFormControl()" [formlyAttributes]="field" [placeholder]="
      to.placeholder
        ? to.required
          ? to.placeholder + ' *'
          : to.placeholder
        : ''
    " [required]="to.required ?? false" />

  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn(filter | async)">
    <mat-option *ngFor="let value of filter | async" [value]="to['valueProp'] ? value[to['valueProp']] : value">
      @if(typeLabelProp=='function'){
      {{ to["labelProp"](value) }}
      }@else{
      {{ to["labelProp"] ? value[to["labelProp"]] : value }}
      }
    </mat-option>
  </mat-autocomplete>

  <mat-error>
    <formly-validation-message [field]="field"></formly-validation-message>
  </mat-error>

  <mat-hint *ngIf="to.description">
    {{ to.description }}
  </mat-hint>
</mat-form-field>