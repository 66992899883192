<mat-form-field class="w-full">
  <mat-label>{{ to.label ?? "" }}</mat-label>
  <mat-chip-grid
    #chipGrid
    aria-label="Enter keywords"
    [formControl]="getFormControl()"
  >
    <mat-chip-row
      *ngFor="let keyword of keywords"
      (removed)="removeKeyword(keyword)"
    >
      {{ keyword }}
      <button matChipRemove aria-label="'remove ' + keyword">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>

    <input
      [matChipInputFor]="chipGrid"
      (matChipInputTokenEnd)="add($event)"
      [placeholder]="
        to.placeholder
          ? to.required
            ? to.placeholder + ' *'
            : to.placeholder
          : ''
      "
      [required]="to.required ?? false"
    />
  </mat-chip-grid>
</mat-form-field>
