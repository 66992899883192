import { Component, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ThemeService } from '@app/core/services/theme.service';
import {Chart, ChartConfiguration, ChartData} from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import {GameService} from "@data/services/game.service";
import {ActivatedRoute} from "@angular/router";
import {Game} from "@data/models/game.model";
import {InstanceService} from "@data/services/instance.service";
import {GameInstance} from "@data/models/game-instance.model";
import {EditDialogComponent} from "@shared/components/edit-dialog/edit-dialog.component";
import {User} from "@data/models/user.model";
import {SnackbarService} from "@shared/services/snackbar.service";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-game-and-instance-stats',
  templateUrl: './game-and-instance-stats.component.html',
  styleUrl: './game-and-instance-stats.component.scss',
})
export class GameAndInstanceStatsComponent {
  // Variables
  yearLabels: any = {
    '01': 'Janvier',
    '02': 'Février',
    '03': 'Mars',
    '04': 'Avril',
    '05': 'Mai',
    '06': 'Juin',
    '07': 'Juillet',
    '08': 'Août',
    '09': 'Septembre',
    '10': 'Octobre',
    '11': 'Novembre',
    '12': 'Décembre',
  };

  filterLabels: any = {
    yesterday: 'Hier',
    today: "Aujourd'hui",
    month: 'Mois',
    week: 'Semaine',
    year: 'Année',
    custom: 'Personnalisé',
  };

  xpRank = [
    {
      first_name: 'John',
      last_name: 'Doe',
      xp: 100,
    },
    {
      first_name: 'Jane',
      last_name: 'Smith',
      xp: 100,
    },
    {
      first_name: 'John',
      last_name: 'Smith',
      xp: 100,
    },
    {
      first_name: 'Jane',
      last_name: 'Doe',
      xp: 100,
    },
  ];

  headerLabel = '';

  dateRange = new FormGroup({
    start: new FormControl(null), // Vous pouvez initialiser avec une date par défaut si nécessaire
    end: new FormControl(null), // Vous pouvez initialiser avec une date par défaut si nécessaire
  });

  // CHARTS 📊
  @ViewChildren(BaseChartDirective) charts?: QueryList<BaseChartDirective>;

  // Chart - Line
  public lineChartData: ChartConfiguration['data'] = {
    labels: [],
    datasets: [
      {
        label: 'Nombre de niveaux réalisés',
        data: [],
        fill: true,
        backgroundColor: 'rgba(30,255,255,0.2)',
        borderColor: '#3dfff9',
        pointBackgroundColor: '#7bfff4',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(93,130,190,0.8)',
        yAxisID: 'y',
      },
      {
        label: 'Temps moyen passé par niveau',
        data: [],
        backgroundColor: 'rgba(0,188,144,0.2)',
        borderColor: '#00c39a',
        pointBackgroundColor: '#4dd9be',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        fill: 'origin',
        yAxisID: 'y1',
      },
    ],
  };
  public lineChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0.5,
      },
    },
    scales: {
      y: {
        max: undefined,
        position: 'left',
        title: {
          display: true,
          text: 'Nombre de niveaux',
        },
        grid: {
          display: false,
        },
        ticks: {
          color: this.themeService.mode ? 'white' : 'black',
          stepSize: 0,
        },
      },
      y1: {
        max: undefined,
        // min: 0,
        position: 'right',
        title: {
          display: true,
          text: 'Temps en minutes',
        },
        grid: {
          display: false,
        },
        ticks: {
          color: this.themeService.mode ? 'white' : 'black',
          stepSize: 0,
        },
      },
      x: {
        position: 'bottom',
        ticks: {
          color: this.themeService.mode ? 'white' : 'black',
        },
        grid: {
          display: false,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        labels: {
          color: this.themeService.mode ? 'white' : 'black',
        },
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            context.formattedValue += context.datasetIndex == 1 ? ' min' : '  ';
          },
        },
      },
    },
  };



  // Chart - Pie
  public pieChartData: ChartData<'pie', number[], string | string[]> = {
    labels: ['Modules commencés', 'Modules terminés', 'Modules non commencés'],
    datasets: [
      {
        data: [800, 300, 100],
        backgroundColor: ['#6949FF', '#3dfff9', 'orange'],
        hoverBackgroundColor: ['#6949FF', '#3dfff9', 'orange'],
        hoverOffset: 30,
      },
    ],
  };
  public pieChartOptions: ChartConfiguration['options'] = {
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      datalabels: {
        formatter: (value, ctx) => {
          let sum: any = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map((data) => {
            sum += data;
          });
          let percentage = ((value * 100) / sum).toFixed(2) + '%';
          return percentage;
        },
        color: '#fff',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            context.formattedValue += ' parties';
          },
        },
      },
    },
    layout: {
      padding: {
        top: 35,
        bottom: 35,
      },
    },
  };

  countByEndedAt: { [key: string]: { totalDuration:number, averageDuration: number, count: number} } = {};
  showInstancesStats = false;
  showInstanceBar = false;
  gameId: string = '';
  instanceId: string = '';
  gameInstances: GameInstance[] = [];
  currentInterval: string[] = [];


  constructor(
    public themeService: ThemeService,
    public gameService: GameService,
    public route: ActivatedRoute,
    public instanceService: InstanceService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    // Subscribe to route parameters to get gameId and instanceId
    this.route.params.subscribe((params) => {
      if (params['gameId']) {
        this.gameId = params['gameId'];
        if (params['instanceId']) {
          this.instanceId = params['instanceId'];

          this.showInstanceBar = false
          this.showInstancesStats = true;

          this.getLastLevelUnlocked(this.gameId, this.instanceId);
          this.getInstanceRanking(this.gameId, this.instanceId);

        } else {
          this.showInstanceBar = true;
        }
      }

      this.setChartsColors();
      this.updateFiltersData('month'); // Set by default Month

    });

  }

  /**
   * Remove Instance
   * @param instance
   */

  removeInstance(instance: GameInstance) {
    this.gameInstances = this.gameInstances.filter((i) => i.id !== instance.id);
    this.getGamePlayedLevelByDate(this.gameId, this.currentInterval[0], this.currentInterval[1]);
    if (this.gameInstances.length == 1) {
      this.showInstancesStats = true;
      this.getInstanceStats(this.gameInstances[0]);
    } else {
      this.showInstancesStats = false;
    }
  }

  getInstanceStats(instance: GameInstance) {
    if (this.gameInstances.length != 1) {
      this.showInstancesStats = false;
    } else {
      this.showInstancesStats = true;
      this.getLastLevelUnlocked(this.gameId, instance.id);
      this.getInstanceRanking(this.gameId, instance.id);
    }
  }


  openDialog(): void {
    const dialogRef = this.dialog.open(EditDialogComponent, {
      width: '700px',
      data: {
        field: [
          {
            key: 'instances',
            type: 'autocomplete-chip',
            className: 'md:flex-grow',
            defaultValue: [],
            props: {
              label: 'Rechercher des sessions',
              placeholder: 'Rechercher par nom',
              required: false,
              multiple: false,
              labelProp: (instance: GameInstance) =>
                instance.name,
              filter: (search: any) =>
                this.gameService.searchGameInstances(this.gameId, search),
            },
          },
        ],
        model: {},
        config: {
          header: {
            icon: '',
            label: 'Ajouter une session',
          },
          actions: {
            cancel: 'Annuler',
            submit: 'Valider',
          },
        },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if(result) {

        let instances = result.instances;
        for (let instance of instances) {
          if (!this.gameInstances.find(i => i.id === instance.id)) {
            this.gameInstances.push(instance);
          }
        }

        if (this.gameInstances.length == 1) {
          this.showInstancesStats = true;
          this.getInstanceStats(this.gameInstances[0]);
        } else {
          this.showInstancesStats = false;
        }

        this.getGamePlayedLevelByDate(this.gameId, this.currentInterval[0], this.currentInterval[1]);


      }

    });
  }


  /**
   * Get Game Played Level By Date
   * @param gameId
   * @param startDate
   * @param endDate
   * @param instances
   */

  getGamePlayedLevelByDate(gameId: string, startDate: string, endDate: string,) {
    let instances: string[] = [];
    if(!this.instanceId) {
      instances = this.gameInstances.map(i => i.id);
    } else {
      instances.push(this.instanceId);
    }

    this.gameService.getGameLevelPlayedByDate(gameId, startDate, endDate, instances).subscribe((response) => {

      // Reset countByEndedAt
      this.countByEndedAt = {};

      // Loop through response to get data
      response.forEach((data: any) => {
        // Format date
        const date = new Date(data.ended_at);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const dateFormated = `${day}/${month}`;


        let duration: number = 0;
        if (data.duration.milliseconds) {
          duration = data.duration.milliseconds / 60000;
        }

        // Check if date already exists in countByEndedAt
        if (this.countByEndedAt[dateFormated]) {
          this.countByEndedAt[dateFormated].count++;
          this.countByEndedAt[dateFormated].totalDuration += duration;
        } else {
          this.countByEndedAt[dateFormated] = {totalDuration: duration, averageDuration: duration, count: 1};
        }
      });

      // Calculate average duration
      Object.keys(this.countByEndedAt).forEach(date => {
        this.countByEndedAt[date].averageDuration = this.countByEndedAt[date].totalDuration / this.countByEndedAt[date].count;
        this.countByEndedAt[date].averageDuration = parseFloat((this.countByEndedAt[date].totalDuration / this.countByEndedAt[date].count).toFixed(2));
      });

      // Update Line Chart Data
      this.lineChartData.labels = Object.keys(this.countByEndedAt);
      this.lineChartData.datasets[0].data = Object.keys(this.countByEndedAt).map(date => this.countByEndedAt[date].count);
      this.lineChartData.datasets[1].data = Object.keys(this.countByEndedAt).map(date => this.countByEndedAt[date].averageDuration);

      // Update Charts
      this.charts?.forEach((child) => {
        child.chart?.update();
      });
    });
  }


  /**
   * Get Last Level Unlocked
   * @param gameId
   * @param instanceId
   */

  getLastLevelUnlocked(gameId: string, instanceId: string) {
    this.gameService.getGameLastLevelUnlocked(gameId, instanceId).subscribe((response) => {

      // Update Pie Chart Data
      this.pieChartData.datasets[0].data = [];
      this.pieChartData.datasets[0].data.push(response.playersDoingLastUnlockedUnit);
      this.pieChartData.datasets[0].data.push(response.playersHavingFinishedLastUnlockedUnit);
      this.pieChartData.datasets[0].data.push(response.playersNotAtLastUnlockedUnit);

      // Update Charts
      this.charts?.forEach((child) => {
        child.chart?.update();
      });
    });
  }

  /**
   * Get Instance Ranking
   * @param gameId
   * @param instanceId
   */

  getInstanceRanking(gameId: string, instanceId: string) {
    this.instanceService.getRanking(gameId, instanceId).subscribe((response) => {
      this.xpRank = [];
      response.forEach((user: any) => {
        this.xpRank.push({
          first_name: user.participant.first_name,
          last_name: user.participant.last_name,
          xp: user.xp
        });
      });
    });
  }




  /*  -------------------------------- 🔍 FILTERS --------------------------------  */

  /**
   * Update Filters Data
   * @param type
   * @param start_date
   * @param end_date
   */
  updateFiltersData(type: string, start_date?: string, end_date?: string) {
    const date_today = new Date();
    this.currentInterval = [];
    switch (type) {
      case 'week':
        const currentDayOfWeek = new Date().getDay();

        let first_day_of_the_week = new Date();
        first_day_of_the_week.setDate(
          new Date().getDate() - (currentDayOfWeek - 1)
        );

        let last_day_of_the_week = new Date(first_day_of_the_week);
        last_day_of_the_week.setDate(first_day_of_the_week.getDate() + 6);

        const firstDayOfTheWeek = new Date(first_day_of_the_week);
        const lastDayOfTheWeek = new Date(last_day_of_the_week);

        const formattedFirstDayOfTheWeek = firstDayOfTheWeek.toISOString().split('T')[0];
        const formattedLastDayOfTheWeek = lastDayOfTheWeek.toISOString().split('T')[0];

        this.currentInterval = [formattedFirstDayOfTheWeek, formattedLastDayOfTheWeek];

        this.getGamePlayedLevelByDate(this.gameId, formattedFirstDayOfTheWeek, formattedLastDayOfTheWeek);

        break;

      case 'month':
        let first_day_of_the_month = new Date(
          date_today.getFullYear(),
          date_today.getMonth(),
          1
        );
        let last_day_of_the_month = new Date(
          new Date(
            date_today.getFullYear(),
            date_today.getMonth() + 1,
            0
          ).setHours(23, 59, 59, 999)
        );

        const firstDayOfTheMonth = new Date(first_day_of_the_month);
        const lastDayOfTheMonth = new Date(last_day_of_the_month);

        const formattedFirstDayOfTheMonth = firstDayOfTheMonth.toISOString().split('T')[0];
        const formattedLastDayOfTheMonth = lastDayOfTheMonth.toISOString().split('T')[0];

        this.currentInterval = [formattedFirstDayOfTheMonth, formattedLastDayOfTheMonth];

        this.getGamePlayedLevelByDate(this.gameId, formattedFirstDayOfTheMonth, formattedLastDayOfTheMonth);


        break;

      case 'year':
        let first_day_of_the_year = new Date(date_today.getFullYear(), 0, 1);
        let last_day_of_the_year = new Date(date_today.getFullYear(), 11, 31);

        const firstDayOfTheYear = new Date(first_day_of_the_year);
        const lastDayOfTheYear = new Date(last_day_of_the_year);

        const formattedFirstDayOfTheYear = firstDayOfTheYear.toISOString().split('T')[0];
        const formattedLastDayOfTheYear = lastDayOfTheYear.toISOString().split('T')[0];

        this.currentInterval = [formattedFirstDayOfTheYear, formattedLastDayOfTheYear];


        this.getGamePlayedLevelByDate(this.gameId, formattedFirstDayOfTheYear, formattedLastDayOfTheYear);

        break;

      case 'custom':
        if (start_date && end_date) {
          const startDate = new Date(start_date);
          const endDate = new Date(end_date);

          const formattedStartDate = startDate.toISOString().split('T')[0];
          const formattedEndDate = endDate.toISOString().split('T')[0];

          this.currentInterval = [formattedStartDate, formattedEndDate];

          this.getGamePlayedLevelByDate(this.gameId, formattedStartDate, formattedEndDate);

        }
        break;

      default:
        break;
    }
  }

  // generateChartData (type: string, start_date?: Date, end_date?: Date) {
  //   const startDate = start_date ?? new Date('1970-01-01');
  //   const endDate = end_date ?? new Date();
  //   let sumByDay: any = {};
  //   let countByDay: any = {};
  //   let filtered_data = globalFakeData;
  //   this.headerLabel =
  //     'Statistiques du ' +
  //     startDate.toLocaleDateString('fr-FR') +
  //     ' au ' +
  //     endDate.toLocaleDateString('fr-FR'); // Update Header Label
  //
  //   // 1. FILTER Data  with Start & End Date
  //   if (type !== 'all') {
  //     filtered_data = globalFakeData.filter(
  //       (item: any) =>
  //         new Date(item.date).getTime() >= startDate.getTime() &&
  //         new Date(item.date).getTime() <= endDate.getTime()
  //     );
  //   }
  //
  //   // 2. CREATE Object { date:'day', d1:'data 1', d2:'data 2' }
  //   filtered_data.forEach((item: any) => {
  //     const day = this.getSplit(type, new Date(item.date));
  //     if (sumByDay[day] == undefined) {
  //       sumByDay[day] = {};
  //       countByDay[day] = {};
  //
  //       if (sumByDay[day].d1 == undefined) {
  //         sumByDay[day].d1 = 0;
  //         countByDay[day].d1 = 0;
  //       }
  //
  //       if (sumByDay[day].d2 == undefined) {
  //         sumByDay[day].d2 = 0;
  //         countByDay[day].d2 = 0;
  //       }
  //     }
  //
  //     sumByDay[day].d1 += item.data; // Data 1
  //     countByDay[day].d1 += 1;
  //
  //     sumByDay[day].d2 += item.data2; // Data 2
  //     countByDay[day].d2 += 1;
  //   });
  //
  //   // 3. Calcul Average Value for Period
  //   const averageByDay: any = [];
  //   for (const day in sumByDay) {
  //     averageByDay.push({
  //       date: day,
  //       d1: (sumByDay[day].d1 / countByDay[day].d1).toFixed(2),
  //       d2: (sumByDay[day].d2 / countByDay[day].d2).toFixed(2),
  //     });
  //   }
  //
  //   // Trier les résultats par ordre croissant de date
  //   averageByDay.sort(
  //     (a: any, b: any) =>
  //       new Date(a.date).valueOf() - new Date(b.date).valueOf()
  //   );
  //
  //   // 4. Update Charts Values
  //
  //   this.lineChartData.labels = [];
  //   this.lineChartData.datasets[0].data = [];
  //   this.lineChartData.datasets[1].data = [];
  //   averageByDay.forEach((item: any) => {
  //     if (this.lineChartData.labels) {
  //       if (type == 'year') {
  //         this.lineChartData.labels.push(this.yearLabels[item.date]);
  //       } else {
  //         this.lineChartData.labels.push(item.date);
  //       }
  //     }
  //     this.lineChartData.datasets[0].data.push(item.d1);
  //     this.lineChartData.datasets[1].data.push(item.d2);
  //   });
  //
  //   this.pieChartData.datasets[0].data = [];
  //   this.pieChartData.datasets[0].data.push(Math.round(Math.random() * 100));
  //   this.pieChartData.datasets[0].data.push(Math.round(Math.random() * 100));
  //   this.pieChartData.datasets[0].data.push(Math.round(Math.random() * 100));
  //
  //   // Update Charts
  //   this.charts?.forEach((child) => {
  //     child.chart?.update();
  //   });
  //
  //   // Fake Update Ranking
  //   this.updateXpRank();
  // }




  /*  -------------------------------- / 🔍 FILTERS --------------------------------  */

  /*  -------------------------------- 🛠️ Tools --------------------------------  */
  /**
   * On Close Custom Date Window, update dateRange FormGroup
   */
  pickerClosed() {
    if (
      this.dateRange.get('start')?.value &&
      this.dateRange.get('end')?.value
    ) {
      this.updateFiltersData(
        'custom',
        this.dateRange.get('start')?.value ?? '',
        this.dateRange.get('end')?.value ?? ''
      );
    }
  }

  /**
   * Test if inputString is a Date
   * @param inputString
   * @returns
   */
  isDateFormatValid(inputString: string) {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(inputString);
  }

  /**
   * Return timestamp in a specific format
   * @param type ( custom / today / month / year)
   * @param date
   * @returns
   */
  getSplit(type: string, date: any) {
    switch (type) {
      case 'today':
        return date.toISOString().split('T')[1].split(':')[0];
        break;

      case 'all':
      case 'week':
      case 'month':
      case 'custom':
        return date.toISOString().split('T')[0];
        break;

      case 'year':
        return date.toISOString().split('T')[0].split('-')[1];
        break;

      default:
        return date.toISOString().split('T')[1].split(':')[0];
        break;
    }
  }

  // Fake Update
  // updateXpRank() {
  //   this.xpRank.forEach((user) => {
  //     user.xp = Math.round(Math.random() * 1000);
  //   });
  //   this.xpRank.sort((a: any, b: any) => b.xp - a.xp);
  // }


  setChartsColors() {
    if (this.themeService.themeIsLoaded()) {

      // Load Scss Variables
      const primaryColor = this.themeService.getPrimaryColor();
      const accentColor = this.themeService.getAccentColor();
      const warnColor = this.themeService.getWarnColor();

      // LineCharts
      this.lineChartData.datasets.forEach((chart: any, index: any) => {
        if (index == 0) {
          chart.backgroundColor = `rgba(${parseInt(primaryColor.substring(1, 3), 16)},${parseInt(primaryColor.substring(3, 5), 16)},${parseInt(primaryColor.substring(5, 7), 16)},0.4)`;
          chart.borderColor = primaryColor;
          chart.pointBackgroundColor = primaryColor;
        } else {
          chart.backgroundColor = `rgba(${parseInt(accentColor.substring(1, 3), 16)},${parseInt(accentColor.substring(3, 5), 16)},${parseInt(accentColor.substring(5, 7), 16)},0.4)`;
          chart.borderColor = accentColor;
          chart.pointBackgroundColor = accentColor;
        }
      });

      // PieCharts
      if (this.pieChartData.datasets[0].backgroundColor) {
        this.pieChartData.datasets[0].backgroundColor = [primaryColor, accentColor, warnColor];
        this.pieChartData.datasets[0].hoverBackgroundColor = [primaryColor, accentColor, warnColor];
      };


    }
  }

  /*  -------------------------------- / 🛠️ Tools --------------------------------  */
}

const globalFakeData = [
  { data: 8, data2: 56, date: '2023-11-11T06:25:43Z' },
  { data: 4, data2: 55, date: '2023-10-17T07:52:44Z' },
  { data: 22, data2: 7, date: '2023-07-20T00:07:30Z' },
  { data: 23, data2: 73, date: '2023-08-04T00:18:20Z' },
  { data: 2, data2: 5, date: '2023-05-01T15:41:38Z' },
  { data: 47, data2: 36, date: '2023-07-20T09:55:53Z' },
  { data: 88, data2: 52, date: '2023-09-17T03:38:33Z' },
  { data: 13, data2: 55, date: '2024-03-06T17:23:53Z' },
  { data: 97, data2: 8, date: '2023-06-16T23:41:57Z' },
  { data: 60, data2: 78, date: '2024-06-24T13:08:46Z' },
  { data: 91, data2: 73, date: '2024-06-25T06:48:57Z' },
  { data: 5, data2: 54, date: '2023-08-08T08:13:18Z' },
  { data: 67, data2: 17, date: '2024-05-31T11:15:00Z' },
  { data: 44, data2: 94, date: '2024-02-26T01:26:13Z' },
  { data: 28, data2: 22, date: '2023-06-17T18:58:04Z' },
  { data: 73, data2: 62, date: '2024-02-04T01:02:20Z' },
  { data: 83, data2: 42, date: '2024-03-04T22:28:23Z' },
  { data: 85, data2: 51, date: '2023-07-14T10:24:42Z' },
  { data: 23, data2: 45, date: '2023-11-16T02:16:07Z' },
  { data: 61, data2: 39, date: '2024-03-21T18:14:37Z' },
  { data: 12, data2: 95, date: '2023-10-06T13:44:13Z' },
  { data: 38, data2: 24, date: '2023-09-13T14:51:21Z' },
  { data: 38, data2: 85, date: '2024-02-09T23:57:02Z' },
  { data: 55, data2: 15, date: '2024-01-03T12:25:33Z' },
  { data: 38, data2: 96, date: '2024-07-30T19:03:14Z' },
  { data: 30, data2: 96, date: '2023-07-31T13:50:08Z' },
  { data: 97, data2: 17, date: '2023-12-11T09:34:07Z' },
  { data: 19, data2: 37, date: '2023-05-04T12:46:58Z' },
  { data: 89, data2: 48, date: '2024-04-22T16:04:34Z' },
  { data: 78, data2: 23, date: '2024-01-19T12:52:55Z' },
  { data: 30, data2: 74, date: '2023-07-02T20:43:32Z' },
  { data: 69, data2: 27, date: '2023-07-24T15:31:01Z' },
  { data: 3, data2: 22, date: '2023-12-02T07:30:51Z' },
  { data: 62, data2: 27, date: '2023-06-03T04:06:54Z' },
  { data: 10, data2: 64, date: '2024-01-18T10:59:27Z' },
  { data: 21, data2: 24, date: '2024-03-01T13:16:45Z' },
  { data: 16, data2: 26, date: '2023-08-31T13:12:29Z' },
  { data: 37, data2: 5, date: '2023-07-15T14:16:12Z' },
  { data: 85, data2: 70, date: '2024-07-14T22:51:35Z' },
  { data: 86, data2: 45, date: '2023-06-10T10:15:30Z' },
  { data: 26, data2: 82, date: '2023-08-19T18:24:47Z' },
  { data: 84, data2: 19, date: '2024-05-29T21:04:15Z' },
  { data: 4, data2: 64, date: '2024-03-01T01:54:29Z' },
  { data: 36, data2: 13, date: '2023-05-29T16:56:45Z' },
  { data: 50, data2: 26, date: '2023-12-30T19:41:47Z' },
  { data: 17, data2: 58, date: '2023-09-02T06:35:42Z' },
  { data: 42, data2: 63, date: '2023-06-07T14:37:48Z' },
  { data: 14, data2: 7, date: '2024-04-27T20:24:35Z' },
  { data: 42, data2: 7, date: '2024-05-02T16:15:21Z' },
  { data: 53, data2: 57, date: '2024-08-03T20:24:58Z' },
  { data: 16, data2: 81, date: '2023-08-15T15:44:37Z' },
  { data: 59, data2: 38, date: '2024-05-25T02:48:46Z' },
  { data: 62, data2: 88, date: '2024-02-15T13:44:55Z' },
  { data: 14, data2: 66, date: '2023-06-14T05:57:05Z' },
  { data: 1, data2: 32, date: '2023-07-30T01:05:42Z' },
  { data: 24, data2: 35, date: '2023-05-07T23:30:50Z' },
  { data: 41, data2: 22, date: '2023-08-23T16:11:18Z' },
  { data: 66, data2: 79, date: '2024-01-04T12:14:54Z' },
  { data: 92, data2: 40, date: '2024-04-04T23:11:16Z' },
  { data: 95, data2: 27, date: '2023-06-09T11:03:35Z' },
  { data: 21, data2: 12, date: '2024-07-18T05:23:28Z' },
  { data: 68, data2: 9, date: '2024-04-28T15:44:51Z' },
  { data: 91, data2: 36, date: '2024-01-15T03:06:59Z' },
  { data: 16, data2: 20, date: '2023-11-04T10:09:58Z' },
  { data: 64, data2: 43, date: '2023-05-23T18:48:23Z' },
  { data: 37, data2: 63, date: '2023-07-12T16:31:42Z' },
  { data: 24, data2: 30, date: '2024-05-23T07:48:46Z' },
  { data: 49, data2: 63, date: '2023-11-24T01:57:25Z' },
  { data: 2, data2: 65, date: '2023-06-09T18:40:43Z' },
  { data: 26, data2: 58, date: '2023-09-10T13:55:33Z' },
  { data: 53, data2: 60, date: '2024-06-05T03:19:35Z' },
  { data: 66, data2: 14, date: '2024-02-25T12:27:13Z' },
  { data: 8, data2: 22, date: '2024-07-09T03:14:34Z' },
  { data: 56, data2: 42, date: '2024-06-26T07:49:11Z' },
  { data: 82, data2: 97, date: '2024-06-01T23:21:50Z' },
  { data: 33, data2: 1, date: '2024-06-15T19:22:05Z' },
  { data: 73, data2: 57, date: '2024-03-13T22:00:37Z' },
  { data: 78, data2: 44, date: '2024-01-10T17:05:46Z' },
  { data: 35, data2: 79, date: '2024-07-02T19:38:42Z' },
  { data: 86, data2: 78, date: '2024-07-19T22:23:53Z' },
  { data: 53, data2: 1, date: '2024-07-21T12:58:19Z' },
  { data: 52, data2: 42, date: '2023-12-26T16:42:11Z' },
  { data: 48, data2: 15, date: '2023-09-06T10:54:46Z' },
  { data: 16, data2: 81, date: '2023-11-27T21:44:34Z' },
  { data: 48, data2: 4, date: '2024-07-22T05:07:24Z' },
  { data: 83, data2: 49, date: '2023-12-10T20:36:14Z' },
  { data: 49, data2: 39, date: '2023-09-17T17:26:33Z' },
  { data: 100, data2: 47, date: '2023-07-12T08:01:25Z' },
  { data: 21, data2: 13, date: '2023-10-23T02:54:43Z' },
  { data: 40, data2: 24, date: '2024-04-21T10:36:31Z' },
  { data: 28, data2: 42, date: '2024-05-01T09:09:39Z' },
  { data: 28, data2: 32, date: '2023-10-14T17:51:06Z' },
  { data: 44, data2: 100, date: '2023-08-08T19:03:18Z' },
  { data: 59, data2: 82, date: '2023-10-09T18:51:22Z' },
  { data: 23, data2: 29, date: '2023-12-21T00:11:31Z' },
  { data: 58, data2: 9, date: '2023-06-15T21:43:28Z' },
  { data: 47, data2: 94, date: '2024-01-03T08:12:16Z' },
  { data: 11, data2: 43, date: '2023-06-25T22:48:20Z' },
  { data: 23, data2: 87, date: '2023-08-10T05:33:29Z' },
  { data: 77, data2: 38, date: '2024-03-16T10:38:37Z' },
  { data: 47, data2: 10, date: '2023-05-16T16:14:42Z' },
  { data: 31, data2: 8, date: '2023-10-03T02:39:43Z' },
  { data: 76, data2: 100, date: '2024-07-28T09:27:28Z' },
  { data: 62, data2: 76, date: '2023-06-14T22:44:35Z' },
  { data: 49, data2: 62, date: '2023-08-16T17:41:37Z' },
  { data: 92, data2: 88, date: '2024-03-16T02:02:10Z' },
  { data: 50, data2: 95, date: '2023-07-27T03:27:32Z' },
  { data: 69, data2: 22, date: '2023-12-27T10:55:29Z' },
  { data: 76, data2: 60, date: '2024-05-03T06:07:02Z' },
  { data: 24, data2: 31, date: '2024-05-04T15:35:36Z' },
  { data: 6, data2: 64, date: '2024-05-25T21:40:31Z' },
  { data: 20, data2: 96, date: '2024-07-18T01:36:19Z' },
  { data: 12, data2: 59, date: '2024-02-07T15:25:36Z' },
  { data: 96, data2: 46, date: '2024-05-04T12:42:21Z' },
  { data: 84, data2: 71, date: '2023-10-11T00:53:11Z' },
  { data: 89, data2: 36, date: '2024-06-27T22:13:03Z' },
  { data: 93, data2: 45, date: '2023-05-03T07:45:02Z' },
  { data: 3, data2: 63, date: '2023-11-24T06:23:32Z' },
  { data: 55, data2: 47, date: '2024-04-04T17:04:07Z' },
  { data: 50, data2: 45, date: '2023-08-23T19:41:24Z' },
  { data: 49, data2: 28, date: '2024-01-05T14:21:53Z' },
  { data: 12, data2: 97, date: '2024-03-17T05:42:00Z' },
  { data: 36, data2: 78, date: '2023-08-26T07:42:12Z' },
  { data: 77, data2: 59, date: '2024-06-02T23:39:08Z' },
  { data: 17, data2: 38, date: '2023-06-21T11:20:53Z' },
  { data: 11, data2: 67, date: '2024-05-12T04:09:44Z' },
  { data: 55, data2: 26, date: '2023-06-24T12:28:06Z' },
  { data: 22, data2: 40, date: '2023-07-14T14:17:01Z' },
  { data: 95, data2: 61, date: '2023-09-27T06:17:58Z' },
  { data: 32, data2: 80, date: '2023-06-27T02:16:25Z' },
  { data: 47, data2: 32, date: '2024-04-13T11:23:49Z' },
  { data: 92, data2: 5, date: '2024-01-04T03:16:06Z' },
  { data: 87, data2: 83, date: '2023-11-08T22:59:15Z' },
  { data: 83, data2: 7, date: '2024-04-22T00:14:00Z' },
  { data: 4, data2: 20, date: '2024-02-27T03:40:08Z' },
  { data: 18, data2: 48, date: '2023-06-22T19:39:52Z' },
  { data: 45, data2: 13, date: '2024-03-25T23:59:16Z' },
  { data: 7, data2: 20, date: '2024-07-18T04:55:36Z' },
  { data: 21, data2: 39, date: '2023-11-04T16:31:32Z' },
  { data: 29, data2: 91, date: '2024-04-27T00:22:38Z' },
  { data: 69, data2: 42, date: '2024-05-22T23:03:59Z' },
  { data: 77, data2: 83, date: '2024-02-27T07:14:32Z' },
  { data: 29, data2: 94, date: '2023-10-11T00:55:22Z' },
  { data: 100, data2: 46, date: '2023-08-09T07:12:16Z' },
  { data: 62, data2: 58, date: '2023-07-08T05:58:03Z' },
  { data: 97, data2: 4, date: '2023-10-01T12:39:22Z' },
  { data: 33, data2: 47, date: '2024-01-11T22:55:15Z' },
  { data: 70, data2: 83, date: '2023-11-08T16:49:53Z' },
  { data: 6, data2: 93, date: '2024-02-01T05:21:05Z' },
  { data: 69, data2: 21, date: '2023-08-02T09:52:06Z' },
  { data: 8, data2: 67, date: '2023-08-31T16:18:44Z' },
  { data: 53, data2: 77, date: '2023-10-13T04:17:34Z' },
  { data: 29, data2: 56, date: '2024-05-16T07:35:23Z' },
  { data: 24, data2: 70, date: '2024-07-16T06:44:10Z' },
  { data: 32, data2: 30, date: '2023-12-03T09:05:47Z' },
  { data: 58, data2: 62, date: '2024-01-13T07:45:30Z' },
  { data: 52, data2: 62, date: '2023-07-19T03:31:54Z' },
  { data: 4, data2: 53, date: '2023-11-14T12:23:22Z' },
  { data: 44, data2: 27, date: '2023-11-14T01:13:58Z' },
  { data: 30, data2: 84, date: '2024-02-03T10:51:58Z' },
  { data: 50, data2: 13, date: '2024-07-13T14:32:54Z' },
  { data: 27, data2: 84, date: '2023-08-13T15:29:52Z' },
  { data: 60, data2: 77, date: '2024-03-13T07:13:48Z' },
  { data: 15, data2: 87, date: '2023-08-17T11:19:59Z' },
  { data: 88, data2: 79, date: '2024-02-20T04:10:13Z' },
  { data: 14, data2: 76, date: '2023-11-26T16:49:16Z' },
  { data: 21, data2: 52, date: '2023-06-21T12:33:06Z' },
  { data: 43, data2: 79, date: '2023-10-19T00:25:17Z' },
  { data: 86, data2: 67, date: '2024-04-18T16:02:14Z' },
  { data: 28, data2: 25, date: '2023-07-30T02:06:24Z' },
  { data: 75, data2: 55, date: '2023-05-02T07:37:00Z' },
  { data: 86, data2: 56, date: '2024-05-18T14:09:29Z' },
  { data: 93, data2: 44, date: '2023-09-09T15:07:57Z' },
  { data: 86, data2: 47, date: '2024-07-04T05:47:28Z' },
  { data: 39, data2: 99, date: '2024-05-08T03:04:05Z' },
  { data: 94, data2: 94, date: '2024-01-28T16:03:20Z' },
  { data: 21, data2: 66, date: '2023-08-08T10:33:34Z' },
  { data: 31, data2: 21, date: '2024-03-16T10:30:38Z' },
  { data: 57, data2: 90, date: '2024-07-17T15:44:34Z' },
  { data: 100, data2: 97, date: '2023-11-25T08:09:29Z' },
  { data: 72, data2: 46, date: '2024-06-15T03:11:58Z' },
  { data: 6, data2: 89, date: '2023-05-08T17:21:32Z' },
  { data: 4, data2: 52, date: '2024-06-16T13:58:49Z' },
  { data: 39, data2: 4, date: '2024-04-19T16:49:32Z' },
  { data: 93, data2: 47, date: '2023-06-04T18:53:44Z' },
  { data: 56, data2: 50, date: '2023-07-09T09:48:17Z' },
  { data: 77, data2: 30, date: '2024-07-03T02:30:15Z' },
  { data: 72, data2: 27, date: '2024-02-26T12:43:39Z' },
  { data: 88, data2: 68, date: '2024-02-17T12:54:21Z' },
  { data: 16, data2: 43, date: '2024-03-02T01:11:34Z' },
  { data: 5, data2: 75, date: '2024-03-02T22:21:05Z' },
  { data: 8, data2: 67, date: '2024-05-17T08:00:04Z' },
  { data: 82, data2: 13, date: '2023-06-28T11:54:46Z' },
  { data: 71, data2: 6, date: '2023-12-07T08:09:42Z' },
  { data: 6, data2: 50, date: '2023-11-15T09:59:50Z' },
  { data: 33, data2: 79, date: '2023-11-25T07:12:41Z' },
  { data: 39, data2: 19, date: '2023-05-03T09:35:01Z' },
  { data: 86, data2: 45, date: '2023-11-17T14:21:37Z' },
  { data: 32, data2: 59, date: '2024-03-03T08:22:14Z' },
  { data: 28, data2: 50, date: '2024-03-20T07:51:01Z' },
  { data: 71, data2: 58, date: '2023-11-16T19:02:36Z' },
  { data: 20, data2: 89, date: '2023-07-14T03:49:34Z' },
  { data: 69, data2: 37, date: '2024-02-02T19:04:16Z' },
  { data: 11, data2: 42, date: '2023-10-27T11:51:27Z' },
  { data: 62, data2: 57, date: '2023-09-24T13:22:07Z' },
  { data: 21, data2: 83, date: '2024-03-12T17:08:29Z' },
  { data: 95, data2: 58, date: '2024-05-03T00:31:55Z' },
  { data: 40, data2: 58, date: '2024-03-09T02:09:21Z' },
  { data: 97, data2: 36, date: '2023-05-03T07:46:17Z' },
  { data: 100, data2: 78, date: '2023-11-24T02:59:11Z' },
  { data: 68, data2: 1, date: '2024-07-15T12:38:06Z' },
  { data: 29, data2: 78, date: '2023-11-22T23:56:44Z' },
  { data: 62, data2: 14, date: '2024-05-26T19:04:50Z' },
  { data: 19, data2: 73, date: '2024-05-04T13:12:50Z' },
  { data: 43, data2: 71, date: '2024-04-01T17:17:26Z' },
  { data: 14, data2: 24, date: '2023-06-12T05:03:46Z' },
  { data: 65, data2: 69, date: '2024-03-29T00:00:55Z' },
  { data: 21, data2: 60, date: '2024-01-07T14:17:11Z' },
  { data: 79, data2: 47, date: '2023-12-18T10:43:58Z' },
  { data: 88, data2: 59, date: '2023-05-23T16:04:30Z' },
  { data: 93, data2: 94, date: '2024-07-29T21:19:50Z' },
  { data: 65, data2: 47, date: '2023-12-05T01:51:42Z' },
  { data: 98, data2: 72, date: '2023-07-13T02:35:52Z' },
  { data: 6, data2: 56, date: '2023-12-23T16:47:12Z' },
  { data: 83, data2: 25, date: '2024-01-09T09:02:01Z' },
  { data: 47, data2: 47, date: '2024-05-30T14:35:43Z' },
  { data: 34, data2: 52, date: '2024-01-23T04:48:00Z' },
  { data: 86, data2: 74, date: '2024-06-06T08:20:50Z' },
  { data: 84, data2: 58, date: '2024-06-12T14:35:51Z' },
  { data: 72, data2: 73, date: '2023-05-26T00:44:01Z' },
  { data: 59, data2: 62, date: '2023-11-11T23:05:38Z' },
  { data: 17, data2: 40, date: '2023-05-10T22:09:38Z' },
  { data: 94, data2: 49, date: '2024-01-08T12:35:07Z' },
  { data: 27, data2: 80, date: '2024-06-05T18:52:28Z' },
  { data: 17, data2: 62, date: '2024-07-21T06:22:54Z' },
  { data: 60, data2: 91, date: '2024-01-07T14:13:08Z' },
  { data: 4, data2: 55, date: '2024-03-18T03:21:18Z' },
  { data: 36, data2: 76, date: '2023-05-20T12:42:43Z' },
  { data: 41, data2: 7, date: '2023-10-16T22:47:37Z' },
  { data: 55, data2: 58, date: '2024-07-31T07:04:52Z' },
  { data: 8, data2: 51, date: '2023-08-02T16:11:01Z' },
  { data: 66, data2: 17, date: '2023-07-29T12:38:32Z' },
  { data: 23, data2: 15, date: '2024-01-11T20:37:30Z' },
  { data: 47, data2: 14, date: '2023-07-16T17:47:08Z' },
  { data: 72, data2: 91, date: '2023-05-11T14:52:10Z' },
  { data: 61, data2: 53, date: '2024-06-28T20:52:22Z' },
  { data: 64, data2: 49, date: '2023-08-04T15:40:21Z' },
  { data: 52, data2: 79, date: '2023-12-30T10:06:30Z' },
  { data: 58, data2: 5, date: '2023-11-19T10:52:19Z' },
  { data: 2, data2: 15, date: '2023-05-01T09:04:08Z' },
  { data: 72, data2: 39, date: '2024-01-07T01:07:17Z' },
  { data: 77, data2: 32, date: '2023-11-05T07:35:19Z' },
  { data: 79, data2: 85, date: '2023-05-24T03:09:22Z' },
  { data: 2, data2: 57, date: '2023-05-03T04:09:03Z' },
  { data: 24, data2: 60, date: '2024-04-01T18:17:39Z' },
  { data: 69, data2: 55, date: '2024-04-02T07:49:36Z' },
  { data: 85, data2: 83, date: '2023-08-29T03:28:31Z' },
  { data: 22, data2: 64, date: '2024-05-08T14:38:58Z' },
  { data: 88, data2: 7, date: '2023-07-07T08:33:39Z' },
  { data: 80, data2: 40, date: '2024-05-24T02:33:26Z' },
  { data: 19, data2: 99, date: '2024-03-10T07:54:45Z' },
  { data: 68, data2: 45, date: '2023-05-29T04:36:23Z' },
  { data: 19, data2: 50, date: '2024-01-30T04:40:18Z' },
  { data: 15, data2: 26, date: '2023-10-24T23:16:34Z' },
  { data: 12, data2: 8, date: '2023-11-02T21:14:48Z' },
  { data: 61, data2: 38, date: '2023-06-11T07:36:20Z' },
  { data: 100, data2: 63, date: '2024-03-29T13:54:15Z' },
  { data: 58, data2: 41, date: '2024-03-07T10:57:06Z' },
  { data: 69, data2: 28, date: '2024-02-04T04:57:39Z' },
  { data: 67, data2: 17, date: '2023-07-07T14:06:25Z' },
  { data: 31, data2: 51, date: '2023-09-02T21:59:09Z' },
  { data: 3, data2: 48, date: '2024-03-27T12:08:06Z' },
  { data: 51, data2: 35, date: '2023-12-27T14:18:43Z' },
  { data: 69, data2: 74, date: '2023-10-14T19:07:07Z' },
  { data: 8, data2: 18, date: '2024-03-21T13:39:54Z' },
  { data: 23, data2: 44, date: '2023-08-08T10:40:37Z' },
  { data: 68, data2: 14, date: '2024-03-31T22:41:45Z' },
  { data: 87, data2: 41, date: '2023-08-03T07:22:38Z' },
  { data: 99, data2: 59, date: '2024-04-10T07:30:39Z' },
  { data: 2, data2: 33, date: '2024-07-06T07:27:36Z' },
  { data: 47, data2: 99, date: '2023-12-05T22:45:22Z' },
  { data: 76, data2: 70, date: '2023-08-30T03:13:30Z' },
  { data: 99, data2: 97, date: '2023-07-02T12:51:03Z' },
  { data: 8, data2: 72, date: '2023-06-25T00:52:50Z' },
  { data: 56, data2: 51, date: '2024-04-03T07:40:31Z' },
  { data: 4, data2: 48, date: '2024-02-20T23:15:01Z' },
  { data: 96, data2: 56, date: '2023-07-04T00:26:40Z' },
  { data: 31, data2: 85, date: '2023-05-30T21:40:59Z' },
  { data: 1, data2: 32, date: '2024-02-10T22:12:47Z' },
  { data: 100, data2: 29, date: '2023-06-04T18:20:26Z' },
  { data: 8, data2: 34, date: '2023-07-01T07:42:48Z' },
  { data: 14, data2: 22, date: '2024-03-23T16:54:56Z' },
  { data: 65, data2: 21, date: '2024-01-30T10:49:17Z' },
  { data: 74, data2: 1, date: '2024-01-08T03:49:56Z' },
  { data: 36, data2: 93, date: '2023-12-20T23:41:09Z' },
  { data: 69, data2: 71, date: '2023-06-23T17:32:59Z' },
  { data: 91, data2: 73, date: '2024-03-09T10:03:10Z' },
  { data: 82, data2: 66, date: '2024-02-19T23:12:28Z' },
  { data: 19, data2: 93, date: '2023-09-28T15:12:50Z' },
  { data: 72, data2: 16, date: '2023-10-22T14:47:56Z' },
  { data: 38, data2: 74, date: '2024-01-20T01:36:32Z' },
  { data: 61, data2: 82, date: '2023-07-20T07:20:39Z' },
  { data: 72, data2: 31, date: '2024-05-15T03:06:29Z' },
  { data: 42, data2: 47, date: '2024-02-16T16:06:02Z' },
  { data: 55, data2: 92, date: '2023-10-21T16:10:11Z' },
  { data: 35, data2: 32, date: '2024-03-07T16:50:51Z' },
  { data: 47, data2: 99, date: '2024-05-08T20:33:49Z' },
  { data: 40, data2: 75, date: '2023-10-29T21:51:54Z' },
  { data: 38, data2: 26, date: '2023-10-15T14:44:08Z' },
  { data: 97, data2: 41, date: '2023-11-15T07:39:50Z' },
  { data: 79, data2: 89, date: '2023-06-22T16:37:45Z' },
  { data: 30, data2: 28, date: '2023-08-12T23:56:23Z' },
  { data: 35, data2: 99, date: '2023-11-27T00:12:43Z' },
  { data: 48, data2: 57, date: '2024-01-10T18:06:15Z' },
  { data: 44, data2: 66, date: '2023-09-17T02:25:24Z' },
  { data: 52, data2: 13, date: '2023-12-13T14:29:10Z' },
  { data: 72, data2: 85, date: '2023-10-30T03:02:34Z' },
  { data: 37, data2: 86, date: '2024-02-29T11:55:07Z' },
  { data: 23, data2: 81, date: '2023-08-02T08:49:09Z' },
  { data: 41, data2: 94, date: '2024-03-10T09:36:06Z' },
  { data: 31, data2: 15, date: '2024-07-13T04:41:19Z' },
  { data: 1, data2: 16, date: '2024-02-13T08:42:55Z' },
  { data: 37, data2: 3, date: '2024-05-18T12:50:38Z' },
  { data: 78, data2: 78, date: '2023-09-02T00:01:01Z' },
  { data: 90, data2: 22, date: '2024-04-14T16:39:45Z' },
  { data: 90, data2: 79, date: '2023-10-04T06:24:32Z' },
  { data: 97, data2: 86, date: '2023-05-10T11:39:41Z' },
  { data: 69, data2: 72, date: '2024-02-15T06:39:11Z' },
  { data: 19, data2: 75, date: '2024-04-19T01:10:07Z' },
  { data: 22, data2: 72, date: '2023-06-04T05:35:24Z' },
  { data: 50, data2: 48, date: '2023-11-27T06:25:46Z' },
  { data: 53, data2: 30, date: '2023-05-12T13:35:47Z' },
  { data: 48, data2: 54, date: '2023-06-14T21:30:27Z' },
  { data: 23, data2: 53, date: '2024-07-17T18:04:10Z' },
  { data: 68, data2: 13, date: '2023-06-20T00:14:22Z' },
  { data: 82, data2: 30, date: '2024-04-04T19:39:12Z' },
  { data: 72, data2: 71, date: '2024-01-04T19:42:53Z' },
  { data: 48, data2: 70, date: '2023-05-22T01:27:01Z' },
  { data: 25, data2: 46, date: '2024-03-06T09:29:25Z' },
  { data: 47, data2: 52, date: '2023-07-27T01:51:45Z' },
  { data: 62, data2: 74, date: '2024-06-15T09:35:56Z' },
  { data: 38, data2: 31, date: '2023-11-05T21:24:04Z' },
  { data: 82, data2: 12, date: '2023-06-29T17:30:17Z' },
  { data: 33, data2: 53, date: '2023-11-05T16:02:09Z' },
  { data: 11, data2: 91, date: '2024-07-09T23:06:55Z' },
  { data: 46, data2: 22, date: '2024-01-11T20:38:58Z' },
  { data: 56, data2: 21, date: '2024-01-26T02:53:19Z' },
  { data: 86, data2: 12, date: '2023-08-29T13:48:55Z' },
  { data: 46, data2: 72, date: '2024-06-03T03:11:42Z' },
  { data: 94, data2: 84, date: '2023-06-05T18:30:54Z' },
  { data: 18, data2: 28, date: '2024-05-02T14:47:47Z' },
  { data: 27, data2: 100, date: '2024-04-26T20:00:13Z' },
  { data: 14, data2: 100, date: '2023-08-05T22:05:05Z' },
  { data: 71, data2: 89, date: '2023-11-24T07:04:06Z' },
  { data: 71, data2: 85, date: '2023-11-03T14:13:37Z' },
  { data: 86, data2: 8, date: '2023-11-14T11:18:05Z' },
  { data: 88, data2: 62, date: '2024-03-30T19:06:55Z' },
  { data: 32, data2: 75, date: '2023-07-19T14:45:41Z' },
  { data: 90, data2: 69, date: '2023-10-28T07:27:43Z' },
  { data: 11, data2: 97, date: '2024-03-20T08:44:08Z' },
  { data: 80, data2: 79, date: '2024-01-31T22:14:10Z' },
  { data: 9, data2: 7, date: '2023-12-19T12:29:49Z' },
  { data: 59, data2: 63, date: '2024-03-05T21:32:26Z' },
  { data: 98, data2: 48, date: '2023-06-01T23:21:39Z' },
  { data: 59, data2: 64, date: '2023-08-02T19:19:57Z' },
  { data: 77, data2: 39, date: '2023-05-09T23:42:26Z' },
  { data: 45, data2: 94, date: '2024-06-30T17:35:04Z' },
  { data: 39, data2: 79, date: '2023-08-21T00:30:55Z' },
  { data: 90, data2: 59, date: '2024-02-28T19:41:55Z' },
  { data: 10, data2: 37, date: '2024-04-09T21:18:27Z' },
  { data: 95, data2: 16, date: '2023-09-23T03:02:44Z' },
  { data: 34, data2: 58, date: '2023-05-03T22:32:39Z' },
  { data: 14, data2: 45, date: '2024-03-30T08:40:33Z' },
  { data: 49, data2: 69, date: '2024-04-01T04:37:37Z' },
  { data: 44, data2: 82, date: '2023-08-07T23:53:12Z' },
  { data: 74, data2: 25, date: '2024-04-18T15:00:39Z' },
  { data: 20, data2: 44, date: '2023-07-03T13:46:13Z' },
  { data: 65, data2: 55, date: '2024-06-29T15:35:06Z' },
  { data: 20, data2: 19, date: '2023-08-14T12:12:20Z' },
  { data: 63, data2: 89, date: '2023-10-25T23:21:13Z' },
  { data: 94, data2: 39, date: '2023-09-22T03:51:24Z' },
  { data: 56, data2: 10, date: '2023-08-25T17:57:59Z' },
  { data: 74, data2: 100, date: '2024-05-10T17:49:24Z' },
  { data: 77, data2: 34, date: '2024-07-14T19:15:44Z' },
  { data: 14, data2: 9, date: '2024-01-17T17:55:13Z' },
  { data: 86, data2: 60, date: '2023-12-29T11:53:47Z' },
  { data: 16, data2: 70, date: '2024-02-10T23:45:02Z' },
  { data: 52, data2: 45, date: '2024-03-22T06:01:23Z' },
  { data: 35, data2: 14, date: '2023-06-26T01:31:35Z' },
  { data: 84, data2: 20, date: '2023-08-29T01:55:20Z' },
  { data: 47, data2: 20, date: '2024-03-29T02:47:42Z' },
  { data: 37, data2: 55, date: '2023-09-04T16:03:56Z' },
  { data: 70, data2: 49, date: '2024-02-15T04:21:03Z' },
  { data: 7, data2: 93, date: '2023-10-14T19:27:05Z' },
  { data: 7, data2: 44, date: '2023-10-24T12:26:38Z' },
  { data: 95, data2: 78, date: '2023-09-30T14:37:16Z' },
  { data: 69, data2: 3, date: '2024-03-28T00:06:52Z' },
  { data: 88, data2: 80, date: '2024-01-31T14:17:40Z' },
  { data: 12, data2: 92, date: '2023-11-20T12:06:48Z' },
  { data: 78, data2: 51, date: '2024-06-26T05:46:04Z' },
  { data: 73, data2: 68, date: '2023-12-14T21:55:03Z' },
  { data: 98, data2: 80, date: '2024-07-28T14:01:31Z' },
  { data: 87, data2: 77, date: '2023-06-26T11:48:25Z' },
  { data: 58, data2: 96, date: '2023-09-13T23:20:48Z' },
  { data: 71, data2: 1, date: '2024-06-14T10:37:18Z' },
  { data: 86, data2: 43, date: '2023-10-17T11:13:11Z' },
  { data: 32, data2: 71, date: '2023-08-27T14:24:13Z' },
  { data: 99, data2: 26, date: '2024-04-21T18:14:24Z' },
  { data: 4, data2: 34, date: '2023-12-01T00:44:52Z' },
  { data: 75, data2: 76, date: '2023-12-16T10:42:27Z' },
  { data: 43, data2: 92, date: '2023-07-03T07:22:28Z' },
  { data: 76, data2: 41, date: '2024-01-23T11:08:00Z' },
  { data: 37, data2: 64, date: '2024-02-17T23:13:21Z' },
  { data: 37, data2: 37, date: '2023-09-29T01:40:02Z' },
  { data: 12, data2: 33, date: '2023-09-25T10:35:52Z' },
  { data: 86, data2: 16, date: '2024-06-14T03:59:56Z' },
  { data: 23, data2: 9, date: '2024-02-02T23:35:35Z' },
  { data: 31, data2: 66, date: '2024-07-20T00:29:13Z' },
  { data: 80, data2: 98, date: '2023-08-16T22:51:18Z' },
  { data: 47, data2: 70, date: '2023-11-05T00:56:57Z' },
  { data: 92, data2: 52, date: '2023-10-21T07:16:02Z' },
  { data: 60, data2: 45, date: '2024-07-08T09:48:38Z' },
  { data: 89, data2: 88, date: '2023-12-09T16:38:06Z' },
  { data: 76, data2: 69, date: '2024-05-28T11:26:59Z' },
  { data: 45, data2: 100, date: '2023-07-22T06:39:15Z' },
  { data: 60, data2: 97, date: '2024-03-01T23:56:31Z' },
  { data: 64, data2: 6, date: '2024-05-15T18:12:10Z' },
  { data: 91, data2: 41, date: '2024-07-30T09:22:11Z' },
  { data: 83, data2: 28, date: '2023-11-12T04:20:52Z' },
  { data: 89, data2: 10, date: '2024-02-01T17:45:33Z' },
  { data: 72, data2: 64, date: '2024-03-11T09:37:23Z' },
  { data: 34, data2: 24, date: '2024-02-17T06:08:32Z' },
  { data: 16, data2: 68, date: '2023-08-26T13:11:47Z' },
  { data: 66, data2: 91, date: '2024-06-29T13:52:41Z' },
  { data: 64, data2: 38, date: '2023-10-14T22:26:13Z' },
  { data: 54, data2: 20, date: '2023-12-22T20:54:48Z' },
  { data: 14, data2: 47, date: '2024-01-28T23:35:14Z' },
  { data: 36, data2: 58, date: '2024-07-23T11:41:52Z' },
  { data: 71, data2: 29, date: '2024-03-03T01:04:56Z' },
  { data: 76, data2: 82, date: '2024-01-26T22:04:03Z' },
  { data: 97, data2: 64, date: '2023-06-14T16:41:28Z' },
  { data: 65, data2: 4, date: '2024-04-09T08:09:27Z' },
  { data: 76, data2: 66, date: '2023-10-31T12:59:39Z' },
  { data: 30, data2: 68, date: '2024-02-03T00:58:47Z' },
  { data: 91, data2: 31, date: '2024-04-04T13:40:51Z' },
  { data: 60, data2: 28, date: '2024-07-21T13:00:49Z' },
  { data: 28, data2: 72, date: '2024-05-12T02:05:26Z' },
  { data: 52, data2: 72, date: '2024-07-22T08:22:37Z' },
  { data: 76, data2: 61, date: '2023-10-22T12:10:55Z' },
  { data: 73, data2: 67, date: '2024-06-22T16:26:32Z' },
  { data: 14, data2: 26, date: '2024-04-20T09:57:10Z' },
  { data: 19, data2: 84, date: '2023-05-19T16:12:36Z' },
  { data: 84, data2: 32, date: '2024-02-20T00:14:05Z' },
  { data: 19, data2: 19, date: '2023-05-29T16:52:44Z' },
  { data: 79, data2: 7, date: '2023-11-01T09:36:49Z' },
  { data: 65, data2: 23, date: '2024-04-01T22:29:11Z' },
  { data: 94, data2: 97, date: '2023-12-13T16:11:33Z' },
  { data: 95, data2: 27, date: '2024-01-10T00:03:45Z' },
  { data: 16, data2: 7, date: '2024-04-22T05:07:39Z' },
  { data: 74, data2: 9, date: '2023-07-07T07:06:28Z' },
  { data: 18, data2: 21, date: '2023-07-11T23:04:23Z' },
  { data: 79, data2: 59, date: '2023-05-29T11:30:46Z' },
  { data: 21, data2: 30, date: '2024-02-26T09:01:56Z' },
  { data: 78, data2: 99, date: '2023-11-24T12:31:26Z' },
  { data: 100, data2: 46, date: '2024-04-02T07:58:39Z' },
  { data: 76, data2: 3, date: '2024-03-08T16:10:51Z' },
  { data: 79, data2: 71, date: '2023-05-26T05:29:34Z' },
  { data: 93, data2: 21, date: '2023-12-21T21:37:13Z' },
  { data: 69, data2: 68, date: '2023-11-21T07:44:01Z' },
  { data: 51, data2: 17, date: '2023-10-22T13:43:08Z' },
  { data: 31, data2: 81, date: '2023-10-09T13:30:22Z' },
  { data: 13, data2: 49, date: '2023-05-01T02:26:42Z' },
  { data: 54, data2: 35, date: '2023-07-19T13:20:23Z' },
  { data: 41, data2: 65, date: '2024-02-11T09:46:14Z' },
  { data: 96, data2: 89, date: '2024-03-20T19:18:07Z' },
  { data: 3, data2: 75, date: '2024-07-22T08:38:18Z' },
  { data: 12, data2: 85, date: '2023-12-12T09:17:36Z' },
  { data: 4, data2: 48, date: '2023-12-17T04:42:58Z' },
  { data: 93, data2: 20, date: '2023-08-14T13:56:49Z' },
  { data: 59, data2: 33, date: '2023-09-23T04:59:03Z' },
  { data: 5, data2: 51, date: '2023-10-12T21:33:31Z' },
  { data: 91, data2: 98, date: '2023-05-24T20:05:12Z' },
  { data: 57, data2: 61, date: '2023-08-16T18:34:47Z' },
  { data: 17, data2: 63, date: '2023-09-10T10:57:10Z' },
  { data: 61, data2: 40, date: '2023-10-08T09:03:32Z' },
  { data: 2, data2: 3, date: '2023-06-29T10:57:56Z' },
  { data: 62, data2: 88, date: '2023-07-25T15:15:16Z' },
  { data: 35, data2: 14, date: '2023-08-25T10:38:04Z' },
  { data: 3, data2: 86, date: '2024-02-07T13:12:29Z' },
  { data: 77, data2: 20, date: '2023-09-24T23:57:47Z' },
  { data: 20, data2: 61, date: '2024-03-09T16:54:26Z' },
  { data: 53, data2: 73, date: '2024-01-06T23:48:33Z' },
  { data: 49, data2: 65, date: '2024-05-11T17:21:07Z' },
  { data: 35, data2: 100, date: '2024-07-09T23:01:14Z' },
  { data: 28, data2: 56, date: '2023-10-16T16:02:50Z' },
  { data: 38, data2: 81, date: '2023-12-15T10:35:36Z' },
  { data: 49, data2: 43, date: '2023-07-23T00:30:30Z' },
  { data: 94, data2: 62, date: '2024-04-23T03:56:23Z' },
  { data: 96, data2: 46, date: '2023-11-03T08:46:39Z' },
  { data: 39, data2: 31, date: '2024-07-07T19:28:05Z' },
  { data: 72, data2: 13, date: '2023-05-05T20:34:05Z' },
  { data: 51, data2: 9, date: '2023-06-10T14:20:16Z' },
  { data: 16, data2: 82, date: '2023-09-27T13:58:55Z' },
  { data: 55, data2: 28, date: '2023-07-02T17:05:22Z' },
  { data: 88, data2: 64, date: '2024-07-30T22:27:09Z' },
  { data: 26, data2: 37, date: '2024-05-25T03:22:26Z' },
  { data: 1, data2: 10, date: '2023-11-06T13:03:02Z' },
  { data: 86, data2: 32, date: '2024-07-30T18:27:42Z' },
  { data: 23, data2: 26, date: '2023-12-03T17:07:59Z' },
  { data: 23, data2: 6, date: '2023-09-09T18:17:40Z' },
  { data: 54, data2: 68, date: '2024-02-11T23:51:21Z' },
  { data: 33, data2: 51, date: '2023-07-28T14:07:18Z' },
  { data: 55, data2: 54, date: '2024-01-29T08:38:55Z' },
  { data: 83, data2: 1, date: '2024-06-09T00:40:57Z' },
  { data: 23, data2: 30, date: '2023-09-14T05:09:55Z' },
  { data: 81, data2: 95, date: '2024-03-11T20:19:05Z' },
  { data: 75, data2: 61, date: '2024-05-28T21:44:47Z' },
  { data: 59, data2: 75, date: '2023-11-30T16:07:49Z' },
  { data: 96, data2: 31, date: '2024-07-06T00:49:06Z' },
  { data: 24, data2: 88, date: '2024-06-16T13:59:05Z' },
  { data: 26, data2: 76, date: '2023-09-05T08:40:04Z' },
  { data: 98, data2: 51, date: '2023-07-10T03:24:11Z' },
  { data: 25, data2: 87, date: '2024-03-05T07:23:22Z' },
  { data: 13, data2: 14, date: '2024-07-20T07:47:45Z' },
  { data: 4, data2: 2, date: '2024-02-01T15:15:43Z' },
  { data: 82, data2: 90, date: '2023-11-21T19:06:42Z' },
  { data: 89, data2: 7, date: '2024-06-21T16:53:55Z' },
  { data: 24, data2: 19, date: '2024-02-08T22:51:44Z' },
  { data: 44, data2: 14, date: '2023-12-12T22:00:11Z' },
  { data: 68, data2: 83, date: '2023-05-05T10:33:03Z' },
  { data: 3, data2: 96, date: '2024-02-18T23:57:43Z' },
  { data: 11, data2: 63, date: '2024-01-09T21:58:53Z' },
  { data: 71, data2: 78, date: '2023-06-17T14:49:46Z' },
  { data: 33, data2: 78, date: '2023-05-13T15:32:36Z' },
  { data: 19, data2: 69, date: '2023-07-12T14:18:10Z' },
  { data: 83, data2: 2, date: '2023-11-08T10:54:33Z' },
  { data: 60, data2: 76, date: '2024-03-28T12:17:41Z' },
  { data: 74, data2: 38, date: '2023-07-08T06:03:53Z' },
  { data: 21, data2: 68, date: '2024-06-15T05:59:20Z' },
  { data: 16, data2: 75, date: '2023-08-16T04:33:39Z' },
  { data: 11, data2: 66, date: '2023-09-24T18:08:55Z' },
  { data: 90, data2: 35, date: '2024-05-28T11:24:24Z' },
  { data: 98, data2: 78, date: '2024-06-21T03:43:52Z' },
  { data: 64, data2: 20, date: '2024-04-18T05:05:49Z' },
  { data: 34, data2: 83, date: '2024-06-06T02:27:17Z' },
  { data: 58, data2: 87, date: '2024-05-06T01:57:36Z' },
  { data: 58, data2: 76, date: '2023-08-12T02:56:06Z' },
  { data: 91, data2: 62, date: '2024-05-21T09:11:13Z' },
  { data: 71, data2: 47, date: '2023-07-01T20:16:04Z' },
  { data: 64, data2: 87, date: '2023-07-08T23:33:41Z' },
  { data: 47, data2: 88, date: '2023-08-25T17:29:13Z' },
  { data: 92, data2: 42, date: '2024-02-03T07:06:29Z' },
  { data: 75, data2: 57, date: '2023-05-15T22:48:54Z' },
  { data: 25, data2: 70, date: '2023-10-10T11:33:31Z' },
  { data: 89, data2: 60, date: '2023-11-16T02:30:09Z' },
  { data: 82, data2: 48, date: '2024-05-11T15:01:05Z' },
  { data: 14, data2: 70, date: '2024-02-25T00:44:52Z' },
  { data: 98, data2: 80, date: '2023-12-12T00:20:14Z' },
  { data: 93, data2: 89, date: '2024-01-11T19:10:01Z' },
  { data: 21, data2: 30, date: '2023-07-23T11:26:51Z' },
  { data: 31, data2: 61, date: '2024-04-12T09:04:29Z' },
  { data: 50, data2: 6, date: '2024-02-03T19:08:11Z' },
  { data: 42, data2: 92, date: '2024-06-17T22:25:12Z' },
  { data: 38, data2: 3, date: '2024-04-20T09:19:12Z' },
  { data: 16, data2: 3, date: '2023-09-18T17:57:50Z' },
  { data: 29, data2: 82, date: '2023-12-25T22:18:25Z' },
  { data: 41, data2: 42, date: '2024-03-14T18:21:13Z' },
  { data: 26, data2: 52, date: '2024-06-06T20:32:11Z' },
  { data: 49, data2: 75, date: '2024-06-25T09:12:59Z' },
  { data: 84, data2: 13, date: '2024-05-15T06:11:55Z' },
  { data: 74, data2: 3, date: '2023-05-12T14:16:58Z' },
  { data: 69, data2: 2, date: '2024-01-14T08:24:59Z' },
  { data: 28, data2: 73, date: '2023-05-09T05:07:55Z' },
  { data: 7, data2: 9, date: '2024-03-25T04:18:47Z' },
  { data: 40, data2: 22, date: '2023-09-22T15:59:21Z' },
  { data: 47, data2: 52, date: '2024-06-04T05:13:53Z' },
  { data: 76, data2: 27, date: '2024-06-29T20:31:52Z' },
  { data: 65, data2: 52, date: '2024-04-10T02:14:39Z' },
  { data: 16, data2: 8, date: '2024-03-27T02:06:23Z' },
  { data: 89, data2: 64, date: '2024-02-23T14:32:45Z' },
  { data: 78, data2: 47, date: '2024-05-07T01:15:32Z' },
  { data: 2, data2: 75, date: '2024-02-29T22:56:43Z' },
  { data: 84, data2: 65, date: '2024-05-11T08:15:49Z' },
  { data: 84, data2: 13, date: '2024-03-10T09:29:37Z' },
  { data: 70, data2: 63, date: '2023-11-15T20:27:23Z' },
  { data: 8, data2: 57, date: '2023-09-24T01:00:08Z' },
  { data: 29, data2: 42, date: '2023-12-21T18:46:12Z' },
  { data: 44, data2: 27, date: '2024-06-15T06:21:18Z' },
  { data: 99, data2: 9, date: '2023-10-08T04:28:16Z' },
  { data: 52, data2: 49, date: '2024-01-23T03:36:37Z' },
  { data: 42, data2: 62, date: '2023-06-19T22:40:30Z' },
  { data: 66, data2: 3, date: '2024-01-24T18:54:11Z' },
  { data: 80, data2: 82, date: '2024-03-20T17:20:04Z' },
  { data: 92, data2: 23, date: '2023-10-31T08:21:37Z' },
  { data: 2, data2: 22, date: '2023-12-19T16:53:54Z' },
  { data: 9, data2: 31, date: '2024-04-07T05:25:36Z' },
  { data: 38, data2: 15, date: '2024-08-02T20:50:36Z' },
  { data: 70, data2: 79, date: '2024-06-04T11:47:57Z' },
  { data: 26, data2: 73, date: '2024-06-02T03:41:58Z' },
  { data: 61, data2: 11, date: '2023-09-20T21:31:18Z' },
  { data: 52, data2: 46, date: '2023-12-11T05:10:13Z' },
  { data: 36, data2: 82, date: '2023-08-21T07:33:44Z' },
  { data: 52, data2: 57, date: '2024-05-20T08:31:01Z' },
  { data: 40, data2: 35, date: '2024-01-31T16:58:26Z' },
  { data: 80, data2: 69, date: '2023-08-19T08:37:41Z' },
  { data: 73, data2: 7, date: '2023-09-25T18:54:09Z' },
  { data: 6, data2: 38, date: '2023-11-02T05:35:33Z' },
  { data: 55, data2: 25, date: '2023-12-23T02:39:57Z' },
  { data: 59, data2: 32, date: '2023-11-02T14:51:01Z' },
  { data: 36, data2: 28, date: '2023-06-13T08:50:01Z' },
  { data: 87, data2: 46, date: '2023-06-16T11:19:42Z' },
  { data: 17, data2: 61, date: '2024-06-08T03:03:12Z' },
  { data: 29, data2: 15, date: '2024-03-12T10:04:48Z' },
  { data: 63, data2: 51, date: '2023-11-23T04:48:31Z' },
  { data: 31, data2: 48, date: '2024-05-01T07:12:02Z' },
  { data: 70, data2: 77, date: '2024-05-30T11:31:31Z' },
  { data: 95, data2: 23, date: '2024-02-11T14:07:09Z' },
  { data: 8, data2: 41, date: '2024-05-07T06:23:48Z' },
  { data: 20, data2: 100, date: '2023-12-22T07:09:05Z' },
  { data: 65, data2: 77, date: '2023-12-20T06:40:47Z' },
  { data: 75, data2: 80, date: '2023-07-14T06:00:21Z' },
  { data: 19, data2: 94, date: '2023-10-16T11:14:35Z' },
  { data: 93, data2: 5, date: '2024-06-27T23:42:12Z' },
  { data: 46, data2: 54, date: '2023-09-23T10:25:46Z' },
  { data: 86, data2: 92, date: '2023-05-10T03:53:59Z' },
  { data: 12, data2: 11, date: '2024-01-15T04:04:42Z' },
  { data: 32, data2: 66, date: '2023-10-30T09:26:05Z' },
  { data: 6, data2: 95, date: '2024-01-04T07:39:25Z' },
  { data: 68, data2: 47, date: '2024-07-30T06:13:07Z' },
  { data: 30, data2: 39, date: '2024-07-27T10:52:56Z' },
  { data: 88, data2: 85, date: '2023-08-08T21:32:50Z' },
  { data: 60, data2: 46, date: '2024-04-28T13:09:08Z' },
  { data: 7, data2: 80, date: '2023-10-26T10:00:44Z' },
  { data: 21, data2: 69, date: '2024-01-06T18:35:06Z' },
  { data: 23, data2: 83, date: '2023-05-04T02:15:47Z' },
  { data: 13, data2: 92, date: '2023-10-26T14:22:10Z' },
  { data: 11, data2: 40, date: '2024-06-13T18:53:36Z' },
  { data: 62, data2: 45, date: '2024-06-07T05:36:51Z' },
  { data: 85, data2: 30, date: '2024-07-21T19:22:03Z' },
  { data: 57, data2: 40, date: '2024-02-21T18:48:13Z' },
  { data: 99, data2: 6, date: '2023-06-25T19:51:44Z' },
  { data: 72, data2: 43, date: '2024-05-27T18:44:45Z' },
  { data: 49, data2: 10, date: '2023-11-26T11:40:15Z' },
  { data: 49, data2: 32, date: '2024-07-13T22:42:58Z' },
  { data: 56, data2: 89, date: '2024-06-13T18:04:40Z' },
  { data: 70, data2: 46, date: '2024-04-06T05:26:33Z' },
  { data: 64, data2: 92, date: '2024-03-06T17:59:36Z' },
  { data: 64, data2: 87, date: '2023-06-02T21:42:20Z' },
  { data: 81, data2: 94, date: '2023-10-05T01:08:44Z' },
  { data: 46, data2: 43, date: '2023-05-18T10:30:46Z' },
  { data: 35, data2: 42, date: '2023-07-10T02:15:49Z' },
  { data: 95, data2: 32, date: '2024-04-24T13:10:45Z' },
  { data: 57, data2: 5, date: '2023-08-18T02:47:50Z' },
  { data: 90, data2: 11, date: '2023-11-14T22:55:25Z' },
  { data: 48, data2: 44, date: '2024-06-11T00:39:33Z' },
  { data: 97, data2: 45, date: '2023-12-13T15:32:09Z' },
  { data: 95, data2: 73, date: '2023-11-10T21:23:29Z' },
  { data: 37, data2: 49, date: '2024-01-24T08:47:53Z' },
  { data: 23, data2: 81, date: '2024-07-18T17:00:52Z' },
  { data: 32, data2: 39, date: '2023-05-09T12:30:26Z' },
  { data: 21, data2: 47, date: '2024-01-31T02:49:37Z' },
  { data: 85, data2: 65, date: '2023-07-05T08:24:39Z' },
  { data: 8, data2: 68, date: '2023-08-29T19:01:34Z' },
  { data: 74, data2: 72, date: '2024-05-23T22:09:37Z' },
  { data: 12, data2: 26, date: '2023-12-13T23:04:04Z' },
  { data: 8, data2: 9, date: '2023-05-28T09:17:44Z' },
  { data: 32, data2: 13, date: '2023-07-17T15:23:33Z' },
  { data: 66, data2: 93, date: '2023-08-16T09:10:31Z' },
  { data: 76, data2: 66, date: '2024-07-06T11:05:13Z' },
  { data: 94, data2: 14, date: '2023-09-20T19:41:04Z' },
  { data: 5, data2: 65, date: '2023-12-13T11:16:29Z' },
  { data: 51, data2: 88, date: '2023-09-11T05:21:50Z' },
  { data: 88, data2: 66, date: '2023-06-07T09:13:38Z' },
  { data: 27, data2: 68, date: '2023-07-12T17:12:24Z' },
  { data: 16, data2: 92, date: '2023-05-08T11:40:36Z' },
  { data: 39, data2: 86, date: '2023-06-12T13:59:31Z' },
  { data: 57, data2: 100, date: '2024-04-29T11:07:16Z' },
  { data: 33, data2: 68, date: '2024-05-10T22:07:05Z' },
  { data: 35, data2: 62, date: '2024-02-06T22:34:58Z' },
  { data: 87, data2: 6, date: '2023-05-11T04:44:30Z' },
  { data: 47, data2: 10, date: '2024-05-03T17:31:56Z' },
  { data: 18, data2: 8, date: '2023-12-04T13:44:54Z' },
  { data: 52, data2: 74, date: '2023-05-16T21:51:32Z' },
  { data: 48, data2: 86, date: '2024-04-30T14:45:51Z' },
  { data: 68, data2: 25, date: '2023-05-23T23:52:36Z' },
  { data: 3, data2: 93, date: '2023-06-29T23:09:57Z' },
  { data: 83, data2: 44, date: '2023-06-15T06:23:48Z' },
  { data: 86, data2: 48, date: '2024-07-18T04:01:55Z' },
  { data: 19, data2: 86, date: '2023-11-17T01:50:35Z' },
  { data: 1, data2: 40, date: '2023-07-05T19:23:02Z' },
  { data: 42, data2: 70, date: '2023-10-30T01:28:45Z' },
  { data: 7, data2: 51, date: '2023-07-03T12:04:43Z' },
  { data: 27, data2: 36, date: '2023-10-17T10:30:03Z' },
  { data: 34, data2: 24, date: '2023-06-24T07:11:24Z' },
  { data: 16, data2: 64, date: '2023-06-14T07:55:56Z' },
  { data: 52, data2: 48, date: '2024-04-13T15:09:56Z' },
  { data: 81, data2: 4, date: '2024-07-17T21:55:10Z' },
  { data: 24, data2: 33, date: '2023-08-15T02:02:05Z' },
  { data: 74, data2: 21, date: '2024-07-22T09:02:12Z' },
  { data: 10, data2: 34, date: '2024-07-04T14:32:20Z' },
  { data: 43, data2: 33, date: '2023-07-16T22:37:31Z' },
  { data: 4, data2: 69, date: '2023-11-18T09:28:50Z' },
  { data: 98, data2: 51, date: '2024-02-17T08:36:51Z' },
  { data: 41, data2: 25, date: '2023-06-10T11:02:21Z' },
  { data: 93, data2: 11, date: '2023-08-27T23:43:33Z' },
  { data: 24, data2: 75, date: '2023-05-18T00:43:08Z' },
  { data: 16, data2: 77, date: '2024-02-20T16:52:18Z' },
  { data: 31, data2: 64, date: '2023-07-29T23:48:55Z' },
  { data: 58, data2: 39, date: '2023-05-06T11:32:55Z' },
  { data: 45, data2: 70, date: '2023-05-24T01:07:54Z' },
  { data: 38, data2: 54, date: '2024-02-14T02:22:55Z' },
  { data: 64, data2: 60, date: '2024-04-11T22:39:41Z' },
  { data: 84, data2: 48, date: '2023-10-17T16:57:07Z' },
  { data: 11, data2: 10, date: '2023-08-30T03:20:27Z' },
  { data: 10, data2: 62, date: '2024-06-20T00:01:27Z' },
  { data: 44, data2: 32, date: '2024-06-29T04:19:29Z' },
  { data: 69, data2: 5, date: '2023-05-21T01:25:55Z' },
  { data: 72, data2: 9, date: '2023-06-19T18:58:35Z' },
  { data: 90, data2: 78, date: '2023-10-26T15:15:47Z' },
  { data: 80, data2: 58, date: '2024-03-15T01:55:12Z' },
  { data: 53, data2: 33, date: '2024-03-18T15:56:52Z' },
  { data: 77, data2: 20, date: '2023-05-10T00:01:19Z' },
  { data: 31, data2: 61, date: '2024-04-03T16:15:02Z' },
  { data: 45, data2: 56, date: '2024-05-26T17:35:37Z' },
  { data: 64, data2: 12, date: '2024-02-08T12:04:37Z' },
  { data: 46, data2: 6, date: '2023-07-09T21:09:26Z' },
  { data: 98, data2: 72, date: '2023-12-02T16:24:32Z' },
  { data: 36, data2: 96, date: '2024-01-13T10:43:50Z' },
  { data: 83, data2: 82, date: '2024-01-15T04:28:55Z' },
  { data: 22, data2: 59, date: '2023-11-03T23:04:00Z' },
  { data: 13, data2: 15, date: '2023-09-09T08:11:49Z' },
  { data: 59, data2: 97, date: '2023-08-25T10:11:03Z' },
  { data: 19, data2: 52, date: '2023-06-30T04:37:39Z' },
  { data: 93, data2: 39, date: '2023-07-17T15:11:19Z' },
  { data: 10, data2: 41, date: '2023-08-28T08:48:57Z' },
  { data: 82, data2: 81, date: '2023-11-23T09:55:59Z' },
  { data: 24, data2: 38, date: '2024-04-30T16:20:56Z' },
  { data: 86, data2: 28, date: '2024-06-21T20:01:29Z' },
  { data: 3, data2: 63, date: '2024-03-29T00:48:16Z' },
  { data: 2, data2: 93, date: '2023-07-30T02:48:24Z' },
  { data: 94, data2: 44, date: '2023-10-13T01:33:53Z' },
  { data: 38, data2: 96, date: '2023-08-29T18:41:19Z' },
  { data: 93, data2: 81, date: '2024-05-21T05:13:16Z' },
  { data: 20, data2: 78, date: '2023-10-23T08:43:19Z' },
  { data: 8, data2: 84, date: '2024-08-03T05:34:25Z' },
  { data: 3, data2: 24, date: '2023-06-13T07:12:46Z' },
  { data: 94, data2: 56, date: '2023-07-19T14:49:28Z' },
  { data: 96, data2: 61, date: '2024-03-17T23:17:00Z' },
  { data: 94, data2: 11, date: '2023-05-02T14:09:54Z' },
  { data: 27, data2: 86, date: '2023-07-10T05:22:48Z' },
  { data: 90, data2: 78, date: '2023-07-01T16:55:49Z' },
  { data: 34, data2: 97, date: '2023-11-02T02:18:50Z' },
  { data: 24, data2: 52, date: '2024-07-29T01:22:18Z' },
  { data: 62, data2: 2, date: '2023-08-12T09:33:06Z' },
  { data: 74, data2: 40, date: '2023-09-13T09:56:19Z' },
  { data: 34, data2: 62, date: '2024-04-29T09:38:47Z' },
  { data: 48, data2: 34, date: '2023-05-29T01:24:28Z' },
  { data: 8, data2: 5, date: '2023-12-28T21:50:31Z' },
  { data: 43, data2: 28, date: '2024-02-19T18:23:51Z' },
  { data: 41, data2: 37, date: '2024-01-12T09:44:43Z' },
  { data: 4, data2: 55, date: '2024-03-09T19:35:34Z' },
  { data: 85, data2: 17, date: '2024-04-26T16:10:37Z' },
  { data: 38, data2: 68, date: '2023-07-24T22:11:13Z' },
  { data: 82, data2: 100, date: '2023-12-13T20:41:30Z' },
  { data: 91, data2: 96, date: '2023-08-15T20:13:10Z' },
  { data: 55, data2: 73, date: '2024-05-07T15:18:15Z' },
  { data: 52, data2: 97, date: '2024-01-23T05:59:47Z' },
  { data: 42, data2: 33, date: '2024-07-21T08:52:26Z' },
  { data: 23, data2: 47, date: '2024-04-20T17:52:39Z' },
  { data: 48, data2: 18, date: '2023-08-17T11:25:36Z' },
  { data: 88, data2: 13, date: '2023-05-03T08:48:02Z' },
  { data: 60, data2: 46, date: '2023-05-03T22:37:00Z' },
  { data: 44, data2: 8, date: '2023-12-07T09:48:31Z' },
  { data: 20, data2: 71, date: '2023-08-13T15:25:34Z' },
  { data: 90, data2: 28, date: '2024-05-18T12:47:11Z' },
  { data: 81, data2: 88, date: '2024-04-23T04:51:25Z' },
  { data: 5, data2: 61, date: '2024-02-17T12:13:25Z' },
  { data: 30, data2: 19, date: '2023-05-02T03:52:40Z' },
  { data: 98, data2: 89, date: '2023-11-08T02:30:10Z' },
  { data: 41, data2: 16, date: '2023-12-29T21:07:05Z' },
  { data: 12, data2: 63, date: '2023-11-30T04:30:39Z' },
  { data: 41, data2: 33, date: '2023-11-21T13:46:30Z' },
  { data: 44, data2: 41, date: '2024-02-05T18:17:03Z' },
  { data: 29, data2: 94, date: '2023-11-04T12:17:21Z' },
  { data: 81, data2: 49, date: '2023-06-26T07:24:06Z' },
  { data: 59, data2: 56, date: '2024-06-14T20:25:51Z' },
  { data: 93, data2: 37, date: '2024-03-07T17:40:51Z' },
  { data: 3, data2: 66, date: '2024-03-16T22:46:50Z' },
  { data: 27, data2: 75, date: '2023-09-22T03:23:50Z' },
  { data: 26, data2: 65, date: '2024-05-11T22:29:28Z' },
  { data: 99, data2: 89, date: '2024-05-12T23:23:13Z' },
  { data: 5, data2: 32, date: '2024-01-19T23:55:47Z' },
  { data: 36, data2: 32, date: '2023-05-13T22:29:54Z' },
  { data: 35, data2: 18, date: '2023-07-24T06:59:10Z' },
  { data: 22, data2: 58, date: '2024-03-12T07:35:17Z' },
  { data: 5, data2: 26, date: '2023-09-21T15:13:23Z' },
  { data: 96, data2: 73, date: '2023-08-24T22:09:51Z' },
  { data: 40, data2: 85, date: '2024-07-07T13:13:51Z' },
  { data: 87, data2: 15, date: '2023-12-24T21:47:42Z' },
  { data: 23, data2: 38, date: '2024-06-18T16:23:59Z' },
  { data: 89, data2: 99, date: '2023-11-10T05:52:36Z' },
  { data: 17, data2: 44, date: '2024-05-27T21:33:15Z' },
  { data: 81, data2: 71, date: '2023-12-16T04:24:24Z' },
  { data: 9, data2: 90, date: '2023-09-04T19:34:57Z' },
  { data: 9, data2: 79, date: '2023-05-24T03:19:01Z' },
  { data: 33, data2: 24, date: '2023-10-03T04:09:10Z' },
  { data: 47, data2: 47, date: '2023-10-27T04:02:47Z' },
  { data: 98, data2: 80, date: '2023-12-13T17:45:28Z' },
  { data: 43, data2: 38, date: '2024-01-14T12:39:45Z' },
  { data: 13, data2: 62, date: '2023-12-03T08:16:26Z' },
  { data: 72, data2: 42, date: '2024-08-02T10:05:56Z' },
  { data: 40, data2: 21, date: '2023-10-19T08:09:12Z' },
  { data: 30, data2: 82, date: '2023-05-18T10:56:43Z' },
  { data: 91, data2: 64, date: '2024-02-12T00:31:20Z' },
  { data: 32, data2: 57, date: '2024-05-04T09:48:29Z' },
  { data: 98, data2: 50, date: '2023-12-23T02:24:13Z' },
  { data: 2, data2: 17, date: '2023-10-16T23:31:39Z' },
  { data: 86, data2: 18, date: '2024-06-20T07:12:06Z' },
  { data: 4, data2: 64, date: '2023-06-04T00:29:17Z' },
  { data: 84, data2: 38, date: '2023-10-17T01:56:04Z' },
  { data: 9, data2: 24, date: '2023-10-20T04:14:43Z' },
  { data: 33, data2: 62, date: '2023-06-25T23:05:24Z' },
  { data: 3, data2: 49, date: '2023-06-20T06:28:04Z' },
  { data: 33, data2: 16, date: '2023-09-18T01:23:43Z' },
  { data: 38, data2: 28, date: '2023-09-26T11:57:34Z' },
  { data: 18, data2: 54, date: '2023-08-12T22:40:39Z' },
  { data: 67, data2: 73, date: '2024-01-30T18:12:15Z' },
  { data: 45, data2: 22, date: '2023-05-06T02:04:10Z' },
  { data: 93, data2: 12, date: '2023-11-22T20:11:51Z' },
  { data: 59, data2: 38, date: '2023-08-22T11:59:29Z' },
  { data: 29, data2: 8, date: '2023-05-30T00:38:24Z' },
  { data: 51, data2: 69, date: '2023-07-23T07:32:43Z' },
  { data: 100, data2: 1, date: '2024-04-27T02:45:47Z' },
  { data: 14, data2: 44, date: '2024-03-24T19:20:49Z' },
  { data: 76, data2: 66, date: '2024-06-18T16:14:35Z' },
  { data: 59, data2: 81, date: '2024-02-13T21:37:08Z' },
  { data: 18, data2: 100, date: '2023-08-12T05:34:40Z' },
  { data: 91, data2: 42, date: '2023-08-20T03:00:06Z' },
  { data: 13, data2: 28, date: '2024-02-05T17:44:51Z' },
  { data: 64, data2: 25, date: '2023-10-18T13:50:54Z' },
  { data: 28, data2: 15, date: '2023-12-26T06:53:39Z' },
  { data: 40, data2: 73, date: '2023-10-20T07:13:09Z' },
  { data: 98, data2: 75, date: '2023-12-05T16:35:04Z' },
  { data: 60, data2: 29, date: '2024-01-16T14:56:06Z' },
  { data: 93, data2: 71, date: '2024-06-01T23:38:33Z' },
  { data: 48, data2: 44, date: '2023-05-05T19:42:53Z' },
  { data: 83, data2: 17, date: '2023-05-28T00:59:44Z' },
  { data: 25, data2: 64, date: '2023-12-31T19:27:12Z' },
  { data: 82, data2: 100, date: '2023-08-09T21:43:37Z' },
  { data: 100, data2: 15, date: '2023-08-22T02:42:21Z' },
  { data: 83, data2: 44, date: '2023-12-23T11:27:42Z' },
  { data: 25, data2: 64, date: '2023-07-07T13:13:02Z' },
  { data: 24, data2: 7, date: '2023-08-28T20:38:26Z' },
  { data: 74, data2: 11, date: '2023-05-13T13:24:56Z' },
  { data: 63, data2: 63, date: '2024-08-03T08:09:31Z' },
  { data: 92, data2: 31, date: '2023-12-01T06:59:38Z' },
  { data: 92, data2: 46, date: '2023-08-02T23:18:02Z' },
  { data: 54, data2: 13, date: '2024-05-14T19:54:54Z' },
  { data: 72, data2: 58, date: '2024-02-04T22:57:14Z' },
  { data: 35, data2: 26, date: '2023-09-03T05:42:43Z' },
  { data: 32, data2: 90, date: '2024-07-18T04:05:27Z' },
  { data: 10, data2: 52, date: '2023-12-02T15:34:12Z' },
  { data: 24, data2: 13, date: '2023-10-09T12:51:28Z' },
  { data: 72, data2: 25, date: '2024-05-22T14:19:55Z' },
  { data: 35, data2: 94, date: '2023-06-27T14:51:47Z' },
  { data: 87, data2: 40, date: '2024-08-04T02:53:15Z' },
  { data: 11, data2: 9, date: '2024-02-03T23:59:40Z' },
  { data: 21, data2: 47, date: '2024-07-30T11:59:46Z' },
  { data: 83, data2: 15, date: '2023-09-10T05:59:07Z' },
  { data: 24, data2: 100, date: '2023-05-29T22:13:20Z' },
  { data: 55, data2: 82, date: '2024-07-01T23:49:30Z' },
  { data: 38, data2: 57, date: '2024-04-18T11:06:09Z' },
  { data: 35, data2: 78, date: '2024-02-04T22:15:54Z' },
  { data: 58, data2: 76, date: '2023-07-30T05:24:09Z' },
  { data: 37, data2: 92, date: '2023-10-17T18:22:58Z' },
  { data: 40, data2: 63, date: '2024-06-24T03:27:29Z' },
  { data: 60, data2: 70, date: '2023-12-16T00:41:16Z' },
  { data: 28, data2: 56, date: '2024-03-21T09:15:38Z' },
  { data: 8, data2: 56, date: '2023-07-15T07:25:28Z' },
  { data: 26, data2: 25, date: '2023-08-23T09:23:28Z' },
  { data: 61, data2: 46, date: '2024-03-14T22:00:34Z' },
  { data: 90, data2: 47, date: '2023-10-15T12:25:58Z' },
  { data: 94, data2: 99, date: '2024-02-12T03:31:01Z' },
  { data: 64, data2: 93, date: '2023-09-17T07:34:32Z' },
  { data: 66, data2: 48, date: '2024-04-08T10:51:29Z' },
  { data: 2, data2: 6, date: '2023-07-10T12:36:53Z' },
  { data: 60, data2: 80, date: '2024-02-22T11:14:20Z' },
  { data: 9, data2: 72, date: '2024-05-18T17:51:18Z' },
  { data: 28, data2: 82, date: '2023-12-09T19:56:12Z' },
  { data: 17, data2: 86, date: '2024-01-12T08:09:51Z' },
  { data: 79, data2: 88, date: '2024-03-13T03:45:28Z' },
  { data: 83, data2: 93, date: '2023-10-21T22:02:21Z' },
  { data: 37, data2: 53, date: '2024-08-03T21:55:24Z' },
  { data: 42, data2: 71, date: '2023-12-29T14:36:47Z' },
  { data: 70, data2: 38, date: '2024-06-03T17:40:25Z' },
  { data: 83, data2: 7, date: '2023-11-04T03:46:05Z' },
  { data: 64, data2: 56, date: '2024-04-24T22:24:41Z' },
  { data: 15, data2: 7, date: '2023-05-06T23:37:13Z' },
  { data: 32, data2: 80, date: '2023-05-14T23:29:29Z' },
  { data: 8, data2: 68, date: '2023-06-24T03:54:05Z' },
  { data: 42, data2: 24, date: '2023-07-12T23:27:18Z' },
  { data: 34, data2: 75, date: '2024-06-30T03:19:58Z' },
  { data: 5, data2: 2, date: '2023-05-15T05:42:19Z' },
  { data: 32, data2: 18, date: '2024-02-01T18:30:04Z' },
  { data: 35, data2: 47, date: '2023-12-09T13:50:08Z' },
  { data: 19, data2: 68, date: '2023-09-11T04:23:57Z' },
  { data: 6, data2: 97, date: '2023-06-12T06:56:37Z' },
  { data: 50, data2: 81, date: '2023-08-24T00:06:13Z' },
  { data: 27, data2: 10, date: '2023-05-25T09:36:37Z' },
  { data: 54, data2: 99, date: '2024-01-08T01:28:27Z' },
  { data: 47, data2: 75, date: '2023-11-03T01:10:00Z' },
  { data: 96, data2: 17, date: '2024-01-17T18:40:29Z' },
  { data: 34, data2: 37, date: '2023-05-22T15:09:10Z' },
  { data: 100, data2: 38, date: '2023-07-16T09:49:55Z' },
  { data: 54, data2: 34, date: '2023-09-15T20:15:22Z' },
  { data: 86, data2: 70, date: '2024-06-01T06:17:06Z' },
  { data: 87, data2: 17, date: '2023-05-10T18:10:42Z' },
  { data: 25, data2: 42, date: '2024-05-09T19:15:33Z' },
  { data: 44, data2: 20, date: '2023-07-08T04:07:53Z' },
  { data: 82, data2: 52, date: '2024-03-14T11:42:46Z' },
  { data: 61, data2: 70, date: '2024-05-08T09:24:23Z' },
  { data: 33, data2: 39, date: '2023-09-05T16:43:17Z' },
  { data: 82, data2: 50, date: '2024-03-24T12:07:22Z' },
  { data: 66, data2: 33, date: '2023-12-01T23:38:22Z' },
  { data: 79, data2: 71, date: '2024-04-14T01:37:17Z' },
  { data: 92, data2: 86, date: '2023-09-21T18:13:11Z' },
  { data: 39, data2: 70, date: '2024-06-07T01:35:34Z' },
  { data: 21, data2: 100, date: '2024-03-26T14:04:28Z' },
  { data: 88, data2: 19, date: '2023-06-02T10:35:26Z' },
  { data: 96, data2: 95, date: '2024-02-04T10:18:22Z' },
  { data: 77, data2: 44, date: '2024-07-09T21:57:37Z' },
  { data: 91, data2: 10, date: '2023-09-12T03:43:31Z' },
  { data: 8, data2: 19, date: '2023-08-28T22:26:50Z' },
  { data: 79, data2: 85, date: '2023-05-02T11:15:51Z' },
  { data: 75, data2: 38, date: '2024-05-18T23:22:42Z' },
  { data: 58, data2: 46, date: '2023-10-16T21:45:33Z' },
  { data: 20, data2: 99, date: '2023-11-06T18:00:39Z' },
  { data: 40, data2: 6, date: '2024-06-25T04:18:04Z' },
  { data: 29, data2: 4, date: '2023-10-30T02:03:44Z' },
  { data: 80, data2: 83, date: '2024-02-07T03:56:38Z' },
  { data: 1, data2: 93, date: '2024-01-07T05:57:54Z' },
  { data: 90, data2: 54, date: '2024-03-19T03:14:46Z' },
  { data: 18, data2: 16, date: '2023-06-05T21:22:39Z' },
  { data: 41, data2: 56, date: '2023-05-06T02:03:29Z' },
  { data: 77, data2: 80, date: '2024-02-23T03:39:14Z' },
  { data: 3, data2: 42, date: '2024-02-20T01:28:15Z' },
  { data: 76, data2: 26, date: '2023-05-26T02:33:29Z' },
  { data: 17, data2: 60, date: '2024-04-25T07:48:18Z' },
  { data: 75, data2: 98, date: '2024-05-20T23:01:19Z' },
  { data: 48, data2: 5, date: '2023-06-21T22:43:34Z' },
  { data: 79, data2: 48, date: '2024-01-03T14:38:56Z' },
  { data: 76, data2: 58, date: '2023-12-04T15:34:02Z' },
  { data: 12, data2: 99, date: '2024-04-08T16:40:16Z' },
  { data: 69, data2: 36, date: '2024-07-04T15:40:15Z' },
  { data: 35, data2: 36, date: '2023-08-29T23:19:00Z' },
  { data: 56, data2: 38, date: '2024-07-16T07:11:15Z' },
  { data: 44, data2: 77, date: '2024-04-12T06:58:50Z' },
  { data: 25, data2: 56, date: '2023-09-18T04:17:56Z' },
  { data: 36, data2: 65, date: '2024-02-21T01:09:54Z' },
  { data: 91, data2: 80, date: '2024-02-18T16:05:04Z' },
  { data: 40, data2: 8, date: '2024-07-27T01:55:04Z' },
  { data: 88, data2: 79, date: '2023-12-07T16:30:22Z' },
  { data: 31, data2: 68, date: '2023-11-06T16:38:33Z' },
  { data: 80, data2: 21, date: '2023-05-03T01:32:58Z' },
  { data: 19, data2: 97, date: '2023-09-12T09:22:30Z' },
  { data: 70, data2: 34, date: '2024-06-02T00:06:26Z' },
  { data: 74, data2: 61, date: '2023-08-23T04:29:41Z' },
  { data: 29, data2: 52, date: '2024-06-06T19:30:07Z' },
  { data: 22, data2: 7, date: '2023-09-30T02:31:21Z' },
  { data: 94, data2: 5, date: '2024-07-10T03:28:34Z' },
  { data: 19, data2: 72, date: '2023-09-16T02:30:36Z' },
  { data: 9, data2: 58, date: '2024-04-19T23:59:58Z' },
  { data: 89, data2: 88, date: '2023-10-06T08:27:14Z' },
  { data: 60, data2: 85, date: '2023-12-22T09:26:40Z' },
  { data: 12, data2: 81, date: '2024-01-26T04:32:16Z' },
  { data: 93, data2: 68, date: '2023-06-09T06:45:54Z' },
  { data: 4, data2: 5, date: '2024-02-11T23:02:52Z' },
  { data: 81, data2: 24, date: '2024-07-12T08:42:44Z' },
  { data: 64, data2: 80, date: '2023-05-31T02:00:27Z' },
  { data: 42, data2: 70, date: '2024-05-25T03:44:16Z' },
  { data: 9, data2: 39, date: '2023-12-11T20:48:44Z' },
  { data: 98, data2: 51, date: '2024-07-23T13:34:31Z' },
  { data: 83, data2: 87, date: '2024-02-10T00:11:34Z' },
  { data: 33, data2: 69, date: '2024-03-08T12:22:34Z' },
  { data: 50, data2: 37, date: '2023-10-20T16:24:52Z' },
  { data: 86, data2: 20, date: '2023-12-09T00:39:51Z' },
  { data: 85, data2: 94, date: '2023-06-28T04:24:55Z' },
  { data: 69, data2: 79, date: '2024-06-30T06:37:43Z' },
  { data: 33, data2: 93, date: '2024-03-23T11:02:27Z' },
  { data: 77, data2: 59, date: '2023-06-11T04:36:49Z' },
  { data: 69, data2: 2, date: '2023-08-25T12:09:54Z' },
  { data: 31, data2: 49, date: '2023-10-07T02:45:03Z' },
  { data: 14, data2: 25, date: '2024-06-10T22:46:07Z' },
  { data: 34, data2: 69, date: '2023-08-28T12:35:21Z' },
  { data: 96, data2: 43, date: '2024-04-18T19:30:06Z' },
  { data: 81, data2: 79, date: '2024-07-09T10:25:49Z' },
  { data: 50, data2: 16, date: '2023-11-09T21:23:15Z' },
  { data: 87, data2: 92, date: '2024-06-09T04:27:57Z' },
  { data: 62, data2: 33, date: '2023-11-08T00:04:20Z' },
  { data: 31, data2: 67, date: '2024-02-09T22:29:43Z' },
];
