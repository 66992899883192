<mat-form-field appearance="outline" class="relative w-full custom-input-file" [floatLabel]="'always'">
    <mat-label>{{to['label']??'Pièce jointe'}}</mat-label>

    @if(imageSrc){
    <img matPrefix class="mx-3 rounded-md max-h-7 max-w-7" [src]="imageSrc" alt="ppreview image" width="28"
        height="28" />
    }
    @else{
    <mat-icon matPrefix svgIcon="image-plus-light"></mat-icon>
    }

    <input matInput [placeholder]="to['placeholder']??'Sélectionnez un fichier (.png, .jpg ... )'" type="text" readonly
        [(ngModel)]="valueToDisplayInInput" class="opacity-1 py-1">

    <div class="absolute left-0  bottom-0 top-0 h-full w-full  ">
        <input (change)="onFileSelected($event)" [accept]="to['accept']" type="file" [multiple]="to['multiple']??false"
            class="opacity-0 h-full w-full">
    </div>
    <mat-icon matSuffix (click)="clearInput()" matTooltip="Supprimer tous les fichiers" class="cursor-pointer"
        aria-label="Delete all files">close</mat-icon>
</mat-form-field>




@if(files.length>1){
<mat-chip-set aria-label="File selection">
    @for (file of files; track file) {
    <mat-chip>
        {{file.name | titlecase}}
        <button matChipRemove aria-label="'remove ' + file.name" (click)="removeFile(file)">
            <mat-icon>close</mat-icon>
        </button>
    </mat-chip>
    }
</mat-chip-set>
}