<mat-card class="info-card">

  <img *ngIf="infoCardConfig.showInfoCardHeader.enable" class="max-h-48" mat-card-image src="{{ infoCardConfig.showInfoCardHeader.showInfoCardImage.path }}" alt="Photo of a Shiba Inu">

  <mat-card-content>
    <div class="flex flex-col max-lg:gap-10 gap-2.5">
      <div *ngIf="infoCardConfig.showInfoCardHeader.enable" class="flex flex-col gap-2.5 info-card-content">


        <h5>{{ infoCardConfig.showInfoCardHeader.showInfoCardGameTitle.title }}</h5>
        <mat-card-subtitle>

          <div class="flex flex-row items-center justify-between">
            <div class="flex flex-row items-center gap-2.5">
              <mat-icon class="header-icon">{{ infoCardConfig.showInfoCardHeader.showInfoCardGameDuration.icon }}</mat-icon>
              <span class="header-span">
              Durée : {{ infoCardConfig.showInfoCardHeader.showInfoCardGameDuration.label }}
              </span>
            </div>

            <div class="flex flex-row items-center gap-2.5">
              <mat-icon class="header-icon">{{ infoCardConfig.showInfoCardHeader.showInfoCardGameDifficulty.icon }}</mat-icon>
              <span class="header-span">
              Difficulté : {{ infoCardConfig.showInfoCardHeader.showInfoCardGameDifficulty.label }}
              </span>
            </div>
          </div>

        </mat-card-subtitle>

        <mat-divider *ngIf="infoCardConfig.showInfoCardGameStory.enable || infoCardConfig.showInfoCardGameBadge.enable || infoCardConfig.showInfoCardGameRanking.enable"></mat-divider>
      </div>


      <div *ngIf="infoCardConfig.showInfoCardGameStory.enable" class="flex flex-col gap-2.5 info-card-content">
        <div class="flex flex-row items-center justify-between">
          <h6>{{ infoCardConfig.showInfoCardGameStory.title }}</h6>
          <a>{{ infoCardConfig.showInfoCardGameStory.button.label }}  </a>
        </div>

        <div >
          <p class="story-p"> {{ infoCardConfig.showInfoCardGameStory.story }}</p>
        </div>
        <mat-divider *ngIf="infoCardConfig.showInfoCardGameBadge.enable || infoCardConfig.showInfoCardGameRanking.enable"></mat-divider>
      </div>


      <div *ngIf="infoCardConfig.showInfoCardGameBadge.enable" class="flex flex-col gap-2.5 info-card-content">
        <div class="flex flex-row items-center justify-between lg:gap-2.5">
          <h6>{{ infoCardConfig.showInfoCardGameBadge.title }}</h6>
          <a class="max-lg:w-16 max-lg:text-ellipsis max-lg:overflow-hidden" href="{{ infoCardConfig.showInfoCardGameBadge.button.link }}">{{ infoCardConfig.showInfoCardGameBadge.button.label }}  </a>
        </div>

        <div class="grid grid-cols-2 auto-rows-auto 2xl:grid-cols-4 xl:grid-cols-3 gap-5" >

          <div class="flex items-center justify-center">
            <div style="background-color: rgba(59, 42, 127, 1);" class="w-24 h-24 flex items-center justify-center rounded-full">
              <mat-icon class="badge-icon" svgIcon="earth-bulk"></mat-icon>
            </div>
          </div>

          <div class="flex items-center justify-center">
            <div style="background-color: rgba(59, 42, 127, 1);" class="w-24 h-24 flex items-center justify-center rounded-full">
              <mat-icon class="badge-icon" svgIcon="rocket-bulk"></mat-icon>
            </div>
          </div>


          <div class="flex items-center justify-center">
            <div style="background-color: rgba(59, 42, 127, 1);" class="w-24 h-24 flex items-center justify-center rounded-full">
              <mat-icon class="badge-icon" svgIcon="earth-bulk"></mat-icon>
            </div>
          </div>

          <div class="flex items-center justify-center">
            <div style="background-color: rgba(59, 42, 127, 1);" class="w-24 h-24 flex items-center justify-center rounded-full">
              <mat-icon class="badge-icon" svgIcon="rocket-bulk"></mat-icon>
            </div>
          </div>


        </div>
        <mat-divider *ngIf="infoCardConfig.showInfoCardGameRanking.enable"></mat-divider>
      </div>


      <div *ngIf="infoCardConfig.showInfoCardGameRanking.enable" class="flex flex-col gap-2.5 info-card-content">
        <div class="flex flex-row items-center justify-between lg:gap-8">
          <h6>{{ infoCardConfig.showInfoCardGameRanking.title }}</h6>
          <a class="max-lg:w-16 max-lg:text-ellipsis max-lg:overflow-hidden" href="{{ infoCardConfig.showInfoCardGameRanking.button.link }}">{{ infoCardConfig.showInfoCardGameRanking.button.label }}  </a>
        </div>

        <div class="grid grid-cols-2 auto-rows-auto 2xl:grid-cols-4 xl:grid-cols-3 gap-5">

          <div class="flex max-lg:col-span-2 items-center justify-center">
            <div class="relative h-24 w-24">
              <img class="rounded-full w-full h-full object-cover" src="https://material.angular.io/assets/img/examples/shiba1.jpg" alt="image description">
              <mat-icon class="ranking-icon absolute bottom-0 right-0" svgIcon="king-bulk" style="color: gold;"></mat-icon>
            </div>
          </div>

          <div class="flex items-center justify-center">
            <div class="relative h-24 w-24">
              <img class="rounded-full w-full h-full object-cover" src="https://material.angular.io/assets/img/examples/shiba1.jpg" alt="image description">
              <mat-icon class="ranking-icon absolute bottom-0 right-0" svgIcon="king-bulk" style="color: silver;"></mat-icon>
            </div>
          </div>

          <div class="flex items-center justify-center">
            <div class="relative h-24 w-24">
              <img class="rounded-full w-full h-full object-cover" src="https://material.angular.io/assets/img/examples/shiba1.jpg" alt="image description">
              <mat-icon class="ranking-icon absolute bottom-0 right-0" svgIcon="king-bulk" style="color: rgba(131, 74, 7, 1);"></mat-icon>
            </div>
          </div>


        </div>
        <mat-divider></mat-divider>
      </div>

      <div *ngIf="infoCardConfig.showInfoCardGameProgression.enable" class="flex flex-col gap-2.5 info-card-content">
        <div class="flex flex-row items-center justify-between lg:gap-8">
          <h6>Ma progression</h6>
        </div>

        <div class="flex flex-col gap-1.5">
          <div class="flex flex-row justify-start items-center gap-2">
            <mat-icon class="progress-icon" svgIcon="{{ infoCardConfig.showInfoCardGameProgression.icon }}"></mat-icon>
            <mat-progress-bar color="primary" mode="determinate" value="{{ infoCardConfig.showInfoCardGameProgression.value }}"></mat-progress-bar>
          </div>

          <div>
            <div class="flex flex-row justify-end">
              <span class="whitespace-nowrap"> {{ infoCardConfig.showInfoCardGameProgression.value }} / {{ infoCardConfig.showInfoCardGameProgression.maxValue }} Missions</span>
            </div>
          </div>
        </div>

      </div>


    </div>
  </mat-card-content>
</mat-card>
