import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-fyyyre-select',
  templateUrl: './fyyyre-select.component.html',
  styleUrl: './fyyyre-select.component.scss',
})
export class FyyyreSelectComponent extends FieldType<FieldTypeConfig> {
  showAnswers(value: any, cssClass: any) {
    if (this.field.className == 'submitted') {
      this.field.formControl.disable(); // Disable Form Control

      // Add Css Class to Answers
      if (Array.isArray(this.props['expectedAnswers'])) {
        // Check is expectedAnswers is an Array
        if (
          this.props['expectedAnswers'].includes(value) ||
          this.field.formControl.value.includes(value)
        ) {
          if (this.props['expectedAnswers'].includes(value)) {
            return cssClass.concat('good-answer');
          } else {
            return cssClass.concat('bad-answer');
          }
        }
      }
    }
    return cssClass;
  }
}
