import { Component } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { FormControl } from "@angular/forms";
import { FieldType } from "@ngx-formly/core";

@Component({
  selector: 'app-input-img',
  templateUrl: './input-img.component.html',
  styleUrl: './input-img.component.scss'
})
export class InputImgComponent extends FieldType {

  imgFile: any[] = [];
  valueToDisplayInInput: string = ''
  imgToShow: any;
  imgSrc: any;

  imgData: any;

  constructor(private sanitizer: DomSanitizer) {
    super();
  }


  getFormControl(): FormControl {
    return this.field.formControl as FormControl;
  }

  ngOnInit() {
    if (this.field.formControl?.value) {
      this.imgToShow = true;
    }
  }


  onFileSelected(event: Event) {
    const files: any = (event.target as HTMLInputElement).files;
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(files[i]));
        files[i]['url'] = url
        this.imgFile.push(files[i]);
      }
    }

    this.field.formControl?.setValue(this.imgFile); // Mettez à jour le tableau d'images dans le FormControl
    // this.valueToDisplayInInput = this.field.formControl?.value.map((file: any) => file.name).join(','); // Update display input
    this.valueToDisplayInInput = this.imgFile.map((file: any) => file.name).join(','); // Update display input
    this.imgToShow = true;
    this.imgSrc = this.imgFile[0].url;

  }

  clearInput() {
    this.imgFile = [];
    this.valueToDisplayInInput = "";
    this.field.formControl?.reset();
    this.imgToShow = false;

  }

  removeFile(file: File) {
    const indexFile = this.imgFile.findIndex((item: File) => item == file);
    if (indexFile != -1) {
      this.imgFile.splice(indexFile, 1);
      this.field.formControl?.setValue(this.imgFile); // Mettez à jour le tableau d'images dans le FormControl
      this.valueToDisplayInInput = this.field.formControl?.value.map((file: any) => file.name).join(','); // Update display input

    }
  }

}
