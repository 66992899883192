import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { startWith } from 'rxjs';
import { ApiService } from './service/api.service';

/*  --------------------------------  FILTRES --------------------------------  */
export interface FilterBtn {
  active: boolean;
  label?: string;
  icon?: string;
  color?: string;
  styleType?: ValidStyleButtonType;
  filters: Array<filterField>;
}

export interface filterField {
  name: string;
  fieldLabel?: string;
  fieldValue: string;
  parentSource?: string;
  contentLoad: boolean;
  multiple?: boolean;
  fieldType: ValidFilterFieldType;
  fieldData: FilterFieldData;
}

export interface FilterFieldData {
  type: validFilterFieldDataType;
  url?: string;
  data: any;
  value: any;
  options?: FilterOptions;
}

// Filters Types
const validFilterFieldType = [
  'checkbox',
  'select',
  'chip',
  'autocomplete',
  'slider',
  'date',
  'toggle',
] as const;
type ValidFilterFieldType = (typeof validFilterFieldType)[number];

// Filters Types of data
const validFilterFieldDataType = ['local', 'api'] as const;
type validFilterFieldDataType = (typeof validFilterFieldDataType)[number];

// Filters Types of Options
export interface FilterOptions {
  icon?: string;
}
const valdiStyleButtonType = [
  'raised',
  'stroked',
  'flat',
  'menu',
  'basic',
] as const;
type ValidStyleButtonType = (typeof valdiStyleButtonType)[number];

/*  -------------------------------- / FILTRES --------------------------------  */

@Component({
  selector: 'app-standalone-filter-menu',
  templateUrl: './standalone-filter-menu.component.html',
  styleUrl: './standalone-filter-menu.component.scss',
})
export class StandaloneFilterMenuComponent {
  @Input() config!: FilterBtn;
  @ViewChild(MatMenuTrigger) filterMenu!: MatMenuTrigger;
  @Output() filterRequestUrl = new EventEmitter<string>();

  filtersList: any = [];
  filtersForm: FormGroup = new FormGroup({});
  filterRequestString = '';

  constructor(private apiService: ApiService) {}

  ngAfterViewInit(): void {
    // this.openFilterMenu();
  }

  /*  --------- 1. FILTER ACTIONS ---------  */
  // 3.1 FILTER MENU - Global Methods
  openFilterMenu() {
    this.filterMenu.openMenu();
  }
  closeFilterMenu() {
    //this.filterMenu.closeMenu()
  }

  addFilter(filter: any) {
    const indexFilter = this.filtersList.findIndex(
      (x: any) => x.id === filter.id
    );
    if (indexFilter == -1) {
      this.filtersList.push(filter);
      this.updateFilteredData();
    } else {
      this.filtersList[indexFilter].value = filter.value;
      this.filtersList[indexFilter].label = filter.label;
      this.updateFilteredData();
    }
  }

  removeFilter(filter: any) {
    // Case Checkbox - Clear Checkbox List
    if (filter.selectionModel) {
      filter.selectionModel.clear();
    }

    // Remove Value from Filter Form
    let fieldLinkToFormControl = this.getFilterFormControl(filter.id);
    if (fieldLinkToFormControl) {
      fieldLinkToFormControl.reset();
    }
    // Remove from Filter List and reload data
    const indexFilter = this.filtersList.findIndex(
      (x: any) => x.id === filter.id
    );
    this.filtersList.splice(indexFilter, 1);
    this.updateFilteredData();
  }
  /*  --------- / 1. FILTER ACTIONS ---------  */

  /*  --------- 2. FILTER DATA MANAGEMENT ---------  */

  getFilterDefaultData(filter: any) {
    // Cancel Load if already data
    if (!filter.contentLoad) {
      if (filter.fieldData.type == 'local') {
        filter.contentLoad = true;
      } else if (filter.fieldData.type == 'api') {
        filter.contentLoad = true;
        this.apiService.get(filter.fieldData.url).subscribe((data) => {
          data = data.data ?? data;
          // If exist pattern -> Transform data with pattern Ex: [ {id:0,name:'a'}] -> [ 'a' ]
          if (filter.fieldLabel && filter.fieldValue) {
            data = data.map(
              (x: any) =>
                (x = {
                  value: this.resolvePath(x, filter.fieldValue, ''),
                  label: this.resolvePath(x, filter.fieldLabel, ''),
                  icon:
                    filter.fieldData.options && filter.fieldData.options.icon
                      ? this.resolvePath(x, filter.fieldData.options.icon, '')
                      : undefined,
                })
            );
          }
          filter.fieldData.data =
            filter.fieldData.data.length > 0
              ? filter.fieldData.data.push(data)
              : data;
        });
      }
    }
  }

  /**
   * Update Data for filter
   */
  updateFilteredData() {
    let listPatternNewData: Array<any> = []; // Store all field to transform ( name['fr']....)
    this.filterRequestString = '';
    let indexFilter = 0;

    // Case Exist Filters
    this.filtersList.forEach((filter: any) => {
      this.filterRequestString +=
        indexFilter === 0
          ? filter.parentSource + '='
          : '&filter.' + filter.parentSource + '=$or:';
      this.filterRequestString += Array.isArray(filter.value)
        ? filter.value.join('&filter.' + filter.parentSource + '=$or:')
        : filter.value;
      if (filter.fieldLabel) listPatternNewData.push(filter.fieldLabel); // Push Field Pattern to transform
      indexFilter++;
    });

    // Case Reset All Filters - And need to reset value with good field
    if (this.filtersList.length == 0) {
      this.config?.filters.forEach((filter) => {
        listPatternNewData.push(filter.fieldLabel);
      });
    }

    /*
    this.apiService.getApiGlobalWithFilters(this.config.api ?? "", this.filterRequestString, this.searchFormValue).subscribe(data => {
      var newData = data.data ?? data
      // For each pattern, transform data
      listPatternNewData.forEach((pattern: string) => {
        if (pattern) {
          newData.forEach((el: any) => {
            this.setPath(el, pattern.split('.')[0], ' ', this.resolvePath(el, pattern, ''))
          });
        }
      })
      this.dataSource.data = newData;
      this.setPaginatorLengthFromPaginateRequest(data.meta.totalItems ?? 0);
    });*/
  }

  submitFilterUrl() {
    this.filterRequestUrl.emit(this.filterRequestString);
  }
  /*  --------- / 2. FILTER DATA MANAGEMENT ---------  */

  /*  --------- 3. FORM MANAGEMENT ---------  */
  getFilterFormControl(name: any) {
    return this.filtersForm.get(name) as FormControl;
  }

  getFilterFormGroup(name: any) {
    return this.filtersForm.get(name) as FormGroup;
  }

  setUpFormManagementForFilterType(filter: any) {
    switch (filter.fieldType) {
      case 'autocomplete':
        this.filtersForm.addControl(filter.name, new FormControl(''));

        // Subscribes to formControl to listen for any change in input value
        this.filtersForm
          .get(filter.name)
          ?.valueChanges.pipe(startWith(''))
          .subscribe((value) => {
            // Trigger only if user select a value in autocomplete list
            if (value.value && value.label) {
              this.addFilter({
                id: filter.name,
                value: value.value,
                label: [value.label],
                fieldType: filter.fieldType,
                fieldValue: filter.fieldValue,
                fieldLabel: filter.fieldLabel ?? '',
                parentSource: filter.parentSource ?? '',
              });
            } else {
              // Else user is searching -> call API with the new value
              // TO DO - REVOIR CE CODE
              /*  this.apiService.get(filter.fieldData.url, 0, 10, value).subscribe(data => {
                  data = data.data ?? data;
                  // If exist pattern -> Transform data with pattern Ex: [ {id:0,name:'a'}] -> [ 'a' ]
                  if (filter.fieldLabel && filter.fieldValue) {
                    data = data.map((x: any) => x = { value: this.resolvePath(x, filter.fieldValue, ''), label: this.resolvePath(x, filter.fieldLabel, '') });
                    filter.fieldData.data = data;
                  }
                });*/
            }
          });
        break;

      case 'chip':
        this.filtersForm.addControl(filter.name, new FormControl([]));
        break;

      case 'slider':
        let sliderFormGroup = new FormGroup({
          start: new FormControl(),
          end: new FormControl(),
        });
        this.filtersForm.addControl(filter.name, sliderFormGroup);

        break;

      case 'date':
        let dateFormGroup = new FormGroup({
          start: new FormControl<Date | null>(null),
          end: new FormControl<Date | null>(null),
        });
        this.filtersForm.addControl(filter.name, dateFormGroup);
        break;

      case 'toggle':
        this.filtersForm.addControl(filter.name, new FormControl(false));
        break;

      default:
        break;
    }
  }
  /*  --------- / 3. FORM MANAGEMENT ---------  */

  /*  --------- 4. FILTER TYPE  ---------  */
  // 3.1 FILTER MENU - Field Type

  // 4.1 AutoComplete
  displayFnAutoCompleteFilter(value: any) {
    return value.label ? value.label : value;
  }

  // 4.2 Checkbox
  // Form management for a checkbox filter
  onSelection(e: any, v: any, filter: any) {
    const filterCheckbox = {
      id: filter.name,
      value: v.selected.map((o: any) => o.value.value),
      label: v.selected.map((o: any) => o.value.label),
      fieldType: filter.fieldType,
      fieldValue: filter.fieldValue,
      fieldLabel: filter.fieldLabel ?? '',
      parentSource: filter.parentSource ?? '',
      selectionModel: v,
    };
    this.addFilter(filterCheckbox);
  }

  // 4.3 Chip List
  onSelectionChip(e: any, filter: any) {
    const filterChipList = {
      id: filter.name,
      value: e.value.map((chip: any) => chip.value),
      label: e.value.map((chip: any) => chip.label),
      fieldType: filter.fieldType,
      fieldValue: filter.fieldValue,
      fieldLabel: filter.fieldLabel ?? '',
      parentSource: filter.parentSource ?? '',
    };
    this.addFilter(filterChipList);
  }

  // 4.4 Slider
  sliderChange(type: string, event: any, filter: any) {
    if (this.filtersForm.get(filter.name)) {
      this.filtersForm.get(filter.name)?.get(type)?.setValue(event);
      if (
        this.filtersForm.get(filter.name)?.get('start')?.value &&
        this.filtersForm.get(filter.name)?.get('end')?.value
      ) {
        const filterSliderType = {
          id: filter.name,
          value:
            '$btw:' +
            this.filtersForm.get(filter.name)?.get('start')?.value +
            ',' +
            this.filtersForm.get(filter.name)?.get('end')?.value,
          label: [
            ' de ' +
              this.filtersForm.get(filter.name)?.get('start')?.value +
              ' à ' +
              this.filtersForm.get(filter.name)?.get('end')?.value,
          ],
          fieldType: filter.fieldType,
          fieldValue: filter.fieldValue,
          fieldLabel: filter.fieldLabel ?? '',
          parentSource: filter.parentSource ?? '',
        };
        this.addFilter(filterSliderType);
      }
    }
  }

  // 4.5 Date
  datePickerChange(type: string, event: any, filter: any) {
    if (this.filtersForm.get(filter.name)) {
      this.filtersForm.get(filter.name)?.get(type)?.setValue(event.value);
      if (
        this.filtersForm.get(filter.name)?.get('start')?.value &&
        this.filtersForm.get(filter.name)?.get('end')?.value
      ) {
        const filterDateType = {
          id: filter.name,
          value:
            '$btw:' +
            this.filtersForm
              .get(filter.name)
              ?.get('start')
              ?.value.toISOString() +
            ',' +
            this.filtersForm.get(filter.name)?.get('end')?.value.toISOString(),
          label: [
            ' du ' +
              this.filtersForm
                .get(filter.name)
                ?.get('start')
                ?.value.toLocaleDateString('fr-FR') +
              ' au ' +
              this.filtersForm
                .get(filter.name)
                ?.get('end')
                ?.value.toLocaleDateString('fr-FR'),
          ],
          fieldType: filter.fieldType,
          fieldValue: filter.fieldValue,
          fieldLabel: filter.fieldLabel ?? '',
          parentSource: filter.parentSource ?? '',
        };
        this.addFilter(filterDateType);
      }
    }
  }

  // 4.6 Toggle
  toggleChange(event: any, filter: any) {
    const filterToggle = {
      id: filter.name,
      value: [event.checked],
      label: [this.filtersForm.get(filter.name)?.value ? 'Oui' : 'Non'],
      fieldType: filter.fieldType,
      fieldValue: filter.fieldValue,
      fieldLabel: filter.fieldLabel ?? '',
      parentSource: filter.parentSource ?? '',
    };
    this.addFilter(filterToggle);
  }

  /*  --------- / 4. FILTER TYPE  ---------  */

  /*  ---------  🛠 UTILS ---------  */

  resolvePath = (object: object, path: any, defaultValue: any) =>
    path
      .split('.')
      .reduce((o: any, p: any) => (o ? o[p] : defaultValue), object);

  setPath = (object: object, path: any, defaultValue: any, newValue?: any) =>
    path.split('.').reduce((o: any, p: any, index: number, array: string[]) => {
      if (index === array.length - 1) {
        // Si c'est la dernière propriété dans le chemin, mettez à jour la valeur si newValue est fourni
        if (newValue !== undefined) {
          o[p] = newValue;
        }
        return o[p] !== undefined ? o[p] : defaultValue;
      } else {
        return o ? o[p] : defaultValue;
      }
    }, object);

  /*  ---------  🛠 UTILS ---------  */
}
