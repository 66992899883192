import { Injectable } from '@angular/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  constructor(private cookieService: SsrCookieService) { }


  translateField(data: any, path: any) {
    const lang = this.cookieService.get('current-language') || 'fr';
    if (Array.isArray(data)) {
      data.forEach(obj => {
        this.setPath(obj, path, ' ', this.resolvePath(obj, path + '.' + lang, ''))
      })
    } else {
      this.setPath(data, path, ' ', this.resolvePath(data, path + '.' + lang, ''))
    }
    return data;
  }

  /*  --------------------------------  🛠 TOOLS --------------------------------  */
  resolvePath = (object: object, path: any, defaultValue: any) => path.split('.').reduce((o: any, p: any) => o ? o[p] : defaultValue, object)

  setPath = (object: object, path: any, defaultValue: any, newValue?: any) =>
    path.split('.').reduce((o: any, p: any, index: number, array: string[]) => {
      if (index === array.length - 1) {
        // Si c'est la dernière propriété dans le chemin, mettez à jour la valeur si newValue est fourni
        if (newValue !== undefined) {
          o[p] = newValue;
        }
        return o[p] !== undefined ? o[p] : defaultValue;
      } else {
        return o ? o[p] : defaultValue;
      }
    }, object);


  /*  -------------------------------- / 🛠 TOOLS --------------------------------  */
}
