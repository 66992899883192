import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, HostBinding, Input } from '@angular/core';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { NavItem } from '@app/layouts/sidenav/sidenav.component';
import { NavService } from './nav.service';

@Component({
  selector: 'app-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrl: './menu-list-item.component.scss',
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ]),
  ],
})
export class MenuListItemComponent {
  // https://stackblitz.com/edit/dynamic-nested-sidenav-menu?file=app%2Fmenu-list-item%2Fmenu-list-item.component.ts,app%2Fapp.component.ts
  expanded: boolean = false;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item!: NavItem;
  @Input() depth!: number;

  constructor(public navService: NavService, public router: Router) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  ngOnInit() {
    // Auto Expand chill if necessary
    if (this.item.childrenExpanded) {
      this.expanded = true;
    }

    this.navService.currentUrl.subscribe((url: string) => {
      if (this.item.route && url) {
        this.expanded = url.indexOf(`/${this.item.route}`) === 0;
        this.ariaExpanded = this.expanded;
      }
    });
  }

  onItemSelected(item: NavItem) {
    if (!item.children || !item.children.length) {
      // Remove Navigation and do it directly in HTML

      if (item.fragment) {
        this.router.navigate([item.route], { fragment: item.fragment }); // Frament Navigation ( route#Id )
      } else {
        this.router.navigate([item.route]); // Classic Navigation
      }

      // this.navService.closeNav();
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }

  myMatchOptions: IsActiveMatchOptions = {
    queryParams: 'ignored',
    matrixParams: 'ignored',
    paths: 'exact',
    fragment: 'exact',
  };

  getClassCssItem(item: any) {
    let css: any = item.cssClass ?? [];
    // Check Active Link Class
    if (this.router.isActive(item.route, true)) {
      // css.push('active-list-item')
    }

    // Check Expanded Class
    if (this.expanded) {
      // css.push('expanded')
      css = ['expanded'];
    }

    return css;
  }
}
