import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChipInputEvent, MatChipEditedEvent } from '@angular/material/chips';
import { FieldType } from '@ngx-formly/core';

export interface Fruit {
  name: string;
}

@Component({
  selector: 'app-input-chip',
  templateUrl: './input-chip.component.html',
  styleUrl: './input-chip.component.scss',
})
export class InputChipComponent extends FieldType {
  announcer = inject(LiveAnnouncer);
  keywords: any = [];
  property: any = '';
  /*  -------------------------------- 1. STATE & EVENT --------------------------------  */

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    // Save Default Value - And disabled them in search
    if (this.field.model) {
      this.property = this.field.key;
      this.keywords = this.model[this.property] ?? [];
    }
  }
  /*  -------------------------------- / 1. STATE & EVENT -------------------------------- */

  /*  -------------------------------- 2. METHODS --------------------------------  */

  getFormControl(): FormControl {
    return this.field.formControl as FormControl;
  }

  /*  -------------------------------- / 2. METHODS --------------------------------  */

  /*  -------------------------------- 3. CHIP - METHODS --------------------------------  */
  removeKeyword(keyword: string) {
    const index = this.keywords.indexOf(keyword);
    if (index >= 0) {
      this.keywords.splice(index, 1);
      this.field.formControl?.setValue(this.keywords);
      this.announcer.announce(`removed ${keyword}`);
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our keyword
    if (value) {
      this.keywords.push(value);
      this.field.formControl?.setValue(this.keywords);
    }

    // Clear the input value
    event.chipInput!.clear();
  }
  /*  -------------------------------- / 3. CHIP - METHODS --------------------------------  */
}
